.travel-guide-item-faces {
  padding: 60px 0 60px;
  overflow: hidden;
  position: relative;
  background-color: #f2eee8;

  &__title {
    padding-bottom: 60px;
  }

  &__slider {
    .slick-list {
      overflow: unset;
    }

    .slick-track {
      margin: 0;
      display: flex;
      gap: 120px;

      .slick-slide {
        width: Min(900px, calc(100vw - 420px));
        flex-shrink: 0;
      }
    }
  }

  &__content {
    height: 740px;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  &__image {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    color: #2b4761;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-faces {
    padding: 30px 0 30px;

    &__title {
      padding-bottom: 25px;
    }

    &__slider {
      .slick-track {
        gap: 15px;

        .slick-slide {
          width: calc(50vw - 57.5px);
        }
      }
    }

    &__content {
      height: 440px;
      display: block !important;
      position: relative;
    }

    &__image {
      height: 100%;
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__description {
      padding: 0 16px;
      position: absolute;
      bottom: 16px;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-faces {
    &__slider {
      .slick-track {
        .slick-slide {
          width: calc(100vw - 60px);
        }
      }
    }
  }
}
