@import 'src/app/styles/vars';
.text {
  margin-bottom: 20px;
  @media (max-width: $md) {
    margin-bottom: 24px;
    padding: 0 16px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

}

.buttonsLight {
  display: flex;
  gap: 10px;
  @media (max-width: $sm) {
    width: 100%;
    border-top: 1px solid $c-gray-20;

  }
  @media (max-width: $md) {
    padding: 16px;
  }

}

.title {
  margin-bottom: 20px;
  @media (max-width: $md) {
    font-size: 20px;
    padding: 0 16px;
    margin-top: 8px;
  }
}

.thumbs {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 8px;
}

.thumb {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 50%;

  &.prev {
    background: $c-green;

  }
  &.active {
    background: $c-gray-40;
  }
  &.next {
    background: $c-gray-30;

  }
}

.popup {
  @media (max-width: $md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.back {
  padding: 12px;
  width: 44px;
  border: 1px solid $c-gray-30;
}

.btn {
  flex-grow: 1;
  @media (max-width: $md) {
    width: 100%;

  }
}
