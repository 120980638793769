.privileges-card {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  position: relative;

  &__label {
    @include cv1000;
    color: $dark-blue;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2.88px;
    font-stretch: 300%;
  }

  &__icon {
    color: #db2947;
  }
}

.book-card {
  margin-top: 16px;
  height: 228px;

  &__img {
    position: absolute;
    width: 550px;
    height: 100%;
    right: 0;
    top: 0;
    object-fit: cover;
  }
}

.privileges-card-sm {
  display: flex;
  padding: 16px 24px 16px 20px;
  align-items: center;
  gap: 20px;
  background: white;
  margin-bottom: 16px;

  &__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #db2947;
    flex-shrink: 0;
    clip-path: polygon(0 0, 100% 0, 97% 100%, 2px 100%);
  }
}

@media (max-width: 900px) {
  .privileges-card {
    &__icon {
      color: white;
      width: 28px;
      height: 28px;
    }

    &__label {
      @include a400;
      color: $dark-blue;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
}
