.base-select {
  position: relative;
  background-color: #ffffff;
  font-size: 22px;
  //z-index: 1001;
  @include a400;

  &.light-background {
    border: 2px solid #d9e0e6;
  }

  &__inner {
    height: 100%;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: #94a5b4;
    z-index: 0;
  }

  &__value {
    padding: 35px 16px 0 20px;
    width: 100%;
    height: 100%;
    display: block;
    border: none;
    background-color: transparent;
    text-align: left;
    color: #2b4761;
    cursor: pointer;

    span {
      color: #94a5b4;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url('../../../assets/images/common/select/arrow-select.svg') center / cover no-repeat;
    }
  }

  &__dropdown {
    max-height: 330px;
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 100;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #2b4761;

    &__list {
      max-height: 330px;
      overflow-y: auto;

      scrollbar-width: thin;
      scrollbar-color: #db2947 #e8eaed;

      /* для Chrome/Edge/Safari */
      &::-webkit-scrollbar {
        height: 12px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #e8eaed;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #db2947;
        border-radius: 5px;
      }
    }

    &-header {
      display: none;
    }

    &-item {
      padding: 16px 12px 16px 20px;
      display: flex;
      justify-content: space-between;
      position: relative;
      background-color: #ffffff;
      border-bottom: 1px solid #e8eaed;
      transition: background-color 200ms;
      cursor: pointer;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #e8eaed;
        }
      }
    }

    .selected-icon {
      height: 24px;
      width: 24px;

      svg {
        display: none;
      }

      svg path {
        stroke: #2b4761;
      }

      &.visible {
        svg {
          display: block;
        }
      }
    }
  }

  &_multi {
    .selected-icon {
      border: 1px solid #e8eaed;
    }

    .visible {
      border-color: #2b4761;
      background-color: #2b4761;

      svg path {
        stroke: #ffffff;
      }
    }
  }

  &.open {
    .base-select__dropdown {
      display: block;
    }
  }

  &.selected {
    .base-select__title {
      top: 14px;
      transform: translateY(0);
      font-size: 14px;
    }
  }

  button:focus {
    outline: none;
  }
}

@media (max-width: 1123px) {
  .base-select {
    height: 60px;
    font-size: 18px;

    & + & {
      border-top: 1px solid #e8eaed;
    }

    &__title {
      left: 17px;
    }

    &__value {
      padding: 15px 16px 0;
      line-height: 28px;

      &::after {
        right: 13px;
      }
    }

    &__dropdown {
      z-index: 9999;
      max-height: 100%;
      flex-direction: column;
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;

      &-header {
        display: flex;
        justify-content: space-between;
        padding: 24px 16px 16px;
        position: relative;
        @include c900;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.12em;
        color: #2b4761;
        font-stretch: 270%;
        background-color: #ffffff;
        transition: box-shadow 100ms ease-in-out;

        & .close-icon {
          width: 24px;
          height: 24px;
          fill: #2b4761;
        }

        &.fixed {
          box-shadow: 0px 8px 20px -9px rgba(4, 30, 56, 0.1);
          z-index: 10;
        }
      }

      &__list {
        max-height: calc(100vh - 66px);
      }

      &-item {
        padding: 20px 16px 20px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.open {
      .base-select__dropdown {
        display: flex;

        &::before {
          content: '';
          display: block;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background: #2b4761;
          opacity: 0.5;
          z-index: -1;
        }
      }
    }

    &.selected {
      .base-select__title {
        top: 8px;
        font-size: 12px;
      }
    }

    button:focus {
      outline: none;
    }
  }
}
