// PROFILE
.profile-title {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  margin-bottom: 10px;
}
.profile-user {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.profile-user__img {
  width: 48px;
  height: 48px;
  flex-grow: 0;
  border-radius: 50%;
  background-color: $dark-blue;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.profile-user__avatar-input {
  display: none;
}
.profile-user__name {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  margin-bottom: 5px;
}
.profile-user__psw {
  font-size: 13px;
  color: $light-blue;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.profile-contacts {
  display: flex;
  margin-bottom: 20px;
}
.profile-box {
  width: 100%;
  & + .profile-box {
    margin-left: 50px;
  }
}
.profile-text {
  font-size: 13px;
  color: $blue;
}
.profile-bottom {
  margin-top: 25px;
}
.profile-btn {
  font-size: 12px;
  & + .profile-btn {
    margin-left: 20px;
  }
  &.blue {
    background-color: $blue;
  }
}
.profile-input,
.profile-textarea {
  width: 100%;
  font-size: 13px;
  color: $blue;
  padding: 5px 10px;
  border: 1px solid #dedede;
}
.profile-textarea {
  height: 100px;
}
.profile-error {
  font-size: 12px;
  font-weight: 500;
  color: $red;
  margin-top: 5px;
}

@media (max-width: 500px) {
  .profile-error {
    font-size: 11px;
  }
  .profile-contacts {
    flex-wrap: wrap;
    row-gap: 15px;
    margin-bottom: 15px;
  }
  form .profile-box {
    width: 100%;
  }
  .profile-box + .profile-box {
    margin-left: 0;
  }
  .profile-btn {
    font-size: 11px;
  }
  .profile-bottom {
    margin-top: 20px;
  }
  .profile-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .profile-user__name,
  .profile-user__psw {
    font-size: 12px;
  }
  .profile-box {
    width: 50%;
  }
}
