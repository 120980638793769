@import 'src/app/styles/vars';
.block {
  padding: 20px;
  background: #fff;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  flex-grow: 1;
}

.head {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
}

.name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.files {
  display: grid;
  gap: 5px;
}

.subtitle {
  font-weight: 500;
  margin-bottom: 5px;
}

.container {
  display: grid;
  gap: 15px;
}

.secondCont {
  display: grid;
  gap: 15px;
}

.actions {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.filesNo {
  font-size: 14px;
  color: #94A5B4;
  margin-top: -14px;
}

