.registration-page,
.login-page,
.restorePass-page {
  min-height: 100vh;
  height: 100%;
  background: #fff;
  position: relative;

  .header {
    display: none;
  }
}

.registration-portal {
  max-width: 500px;
  max-height: calc(100vh - 10px);
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border: 1px solid #dedede;

  &__header {
    padding: 0 20px;
  }
}

.registration-wrapper {
  padding: 0 20px;
  height: calc(100% - 135px - 20px - 29px);
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #db2947 #e8eaed;

  /* для Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    height: 12px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e8eaed;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #db2947;
    border-radius: 5px;
  }
}

.registration-page__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  &.ornament {
    &::before {
      left: 15%;
      top: 120px;
    }
    &::after {
      right: 10%;
      bottom: calc(100% - 600px);
    }
  }
}
.registration-logo {
  position: absolute;
  top: 5%;
  left: 5%;
}
.registration-title {
  @include c900;
  max-width: 500px;
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 100px 0 20px;

  color: $dark-blue;
}
.registration-text {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  color: $dark-blue;
}
.registration-form {
  padding-bottom: 50px;
  max-width: 500px;
  width: 100%;
  &.ornament {
    &::before,
    &::after {
      @media (min-width: 769px) {
        content: none;
      }
    }
  }
  .profile-error {
    margin-top: 0;
    height: 20px;
    color: $red;
  }
}
.registration-label {
  position: relative;
  display: block;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 19px;
    height: 19px;
  }

  &.login::before {
    // left: 13px;
    width: 13px;
    background: url('../../assets/images/user-input.svg') center / contain no-repeat;
  }
  &.email::before {
    background: url('../../assets/images/email-input.svg') center / cover no-repeat;
  }
  &.phone::before {
    background: url('../../assets/images/phone-input.svg') center / cover no-repeat;
  }
  &.pass::before {
    background: url('../../assets/images/key-input.svg') center / cover no-repeat;
  }
  &.message::before {
    top: 14px;
    background: url('../../assets/images/message-input.svg') center / cover no-repeat;
  }

  & + .registration-label {
    margin-top: 15px;
  }
}
.registration-input {
  width: 100%;
  padding: 7px 10px 7px 38px;
  font-size: 15px;
  color: $dark-blue;
  background-color: #eaecef;
  border: none;
  border-radius: 4px;
  &::placeholder {
    color: rgba($dark-blue, 0.6);
  }
  &__role {
    padding: 7px;
    text-align: center;
  }
  &__textarea {
    resize: vertical;
  }
}
.message .registration-input {
  min-height: 120px;
  resize: vertical;
}
.registration-btn,
.registration-link {
  padding: 15px 0;
  width: 217px;
}
.registration-btn {
  z-index: 0;
}
.registration-label-policy {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
  p {
    color: $dark-blue;
  }
  a {
    color: $light-blue;
  }
  margin: 15px 0 0;
  .registration-policy {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    &:checked + span {
      background-color: $red;
      &::before {
        left: unset;
        right: 10%;
        background-color: #fff;
      }
    }
    &:focus + span {
      box-shadow: 0 0 0 0.1em $blue;
    }
  }
  span {
    width: 36px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 2px;
    background-color: $gray;
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    &::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 14px;
      background-color: $red;
      border-radius: 2px;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
    }
  }
}
.registration-policy-link {
  margin-left: 2px;
  color: #fff;
  text-decoration: underline;
}
.registration-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.registration-btn,
.registration-link {
  text-align: center;
  display: block;
}
.registration-btn.lone {
  margin: 20px auto 0;
  padding: 15px 0;
  width: 257px;
}
.registration-link {
  background-color: $blue;
  &:hover,
  &:focus {
    background-color: darken($blue, 7%);
  }
}

.registration-bottom {
  margin-top: 40px;
}
.footer-row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.registration-restore__link {
  color: $light-blue;
  text-decoration: underline;

  &_mobile {
    display: none;
  }
}
.restorePass-text {
  margin-bottom: 30px;
  max-width: 500px;
  width: 100%;
  font-size: 20px;
}

@media (max-width: 1440px) {
  .restorePass-page,
  .login-page,
  .registration-page {
    & > .container {
      max-width: 800px;
    }
  }
  .registration-page__inner {
    &.ornament {
      &::before {
        left: 70px;
        top: 110px;
      }
      &::after {
        right: 70px;
        bottom: calc(100% - 370px);
      }
    }
  }
  .registration-logo {
    top: 17px;
    img {
      width: 160px;
    }
  }
  .registration-title {
    margin: 60px 0 15px;
    max-width: 340px;
    font-size: 36px;
  }
  .restorePass-text {
    margin-bottom: 20px;
    max-width: 340px;
    font-size: 15px;
  }
  .registration-text {
    padding: 10px 0;
    font-size: 14px;

    &_portal {
      font-size: 15px;
    }
  }
  .registration-form {
    max-width: 340px;

    .profile-error {
      height: 15px;
      font-size: 10px;
    }
  }
  .registration-label-policy {
    margin-top: 5px;
    font-size: 15px;
    span {
      margin-right: 12px;
    }
  }
  .registration-input {
    padding: 3px 10px 3px 38px;
    font-size: 13px;
  }
  .registration-buttons {
    margin-top: 25px;
  }
  .registration-btn,
  .registration-link {
    padding: 11px 0;
    width: 157px;
    &.lone {
      margin: 10px auto 0;
      width: 183px;
    }
  }
  .registration-bottom {
    margin-top: 25px;
  }
  .footer-row {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .registration-page__inner {
    &.ornament {
      &::before {
        top: 100px;
      }
      &::after {
        bottom: calc(100% - 320px);
      }
    }
  }
  .restorePass-page,
  .login-page,
  .registration-page {
    & > .container {
      max-width: 670px;
    }
  }
  .registration-logo {
    top: 17px;
    img {
      width: 130px;
    }
  }
  .registration-title {
    margin-top: 50px;
    margin-bottom: 13px;
    max-width: 280px;
    font-size: 30px;
  }
  .restorePass-text {
    margin-bottom: 13px;
    max-width: 280px;
    font-size: 12px;
  }
  .registration-text {
    padding: 10px 0 5px;
    font-size: 11px;

    &_portal {
      font-size: 12px;
    }
  }
  .registration-input {
    padding: 3px 10px 3px 30px;
    font-size: 12px;
  }
  .registration-form {
    max-width: 280px;
    .profile-error {
      height: 11px;
      font-size: 9px;
    }
  }
  .registration-label::before {
    width: 13px;
    height: 13px;
  }
  .registration-label.message::before {
    top: 11px;
  }
  .registration-label-policy {
    margin-top: 5px;
    font-size: 12px;
    span {
      margin-right: 10px;
    }
  }
  .registration-buttons {
    margin-top: 10px;
  }
  .registration-btn,
  .registration-link {
    padding: 8px 0;
    width: 130px;
    &.lone {
      margin: 10px auto 0;
      width: 153px;
    }
  }
  .registration-bottom {
    margin-top: 20px;
  }
  .footer-row {
    font-size: 12px;
  }
}

@media (max-width: 900px) {
  .registration-page,
  .login-page,
  .restorePass-page {
    min-height: 100vh;
    height: 100%;
    background: #fff;
    position: relative;

    .header {
      display: block;
    }
  }
  .registration-page__inner {
    padding-bottom: 140px;
  }
  .registration-logo {
    display: none;
  }
  .registration-title {
    margin-top: 120px;
  }
}
@media (max-width: 768px) {
  .registration-wrapper {
    height: calc(100% - 135px - 20px);
  }
  .registration-page__inner {
    &.ornament {
      &::before {
        left: 50%;
        top: 50px;
      }
      &::after {
        right: -55px;
        bottom: calc(100% - 550px);
      }
    }
  }
  .restorePass-page,
  .login-page,
  .registration-page {
    & > .container {
      padding: 0 5px;
      max-width: 510px;
    }
  }
  .registration-title {
    margin: 120px 0 60px;
    max-width: 95%;
    font-size: 36px;
  }
  .restorePass-text {
    margin-bottom: 20px;
    max-width: 100%;
    font-size: 16px;
  }
  .registration-text {
    padding: 10px 0 5px;
    font-size: 16px;

    &_portal {
      font-size: 16px;
    }
  }
  .registration-form {
    max-width: 100%;
    max-width: 500px;
    width: 100%;
    .profile-error {
      height: 30px;
      font-size: 12px;
    }
    &.ornament {
      &::before {
        left: -70px;
        bottom: -160px;
      }
      &::after {
        left: 50%;
        bottom: -350px;
      }
    }
  }
  .registration-label::before {
    width: 22px;
    height: 22px;
  }
  .registration-label.message::before {
    top: 18px;
  }
  .registration-label-policy {
    margin-top: 10px;
    font-size: 16px;
    span {
      margin-right: 20px;
    }
  }
  .registration-input {
    padding: 8px 10px 8px 38px;
    font-size: 16px;
  }
  .registration-buttons {
    margin-top: 0;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }

  .registration-btn,
  .registration-link {
    padding: 11px 0;
    width: 100%;
    &.lone {
      margin: 0 auto;
      width: 315px;
    }
  }
  .registration-restore__link_mobile {
    display: block;
  }
  .registration-bottom {
    display: none;
  }
}

@media (max-width: 500px) {
  .registration-page__inner {
    padding-bottom: 100px;
    &.ornament {
      &::before {
        left: 50%;
        top: 0;
      }
      &::after {
        right: -8px;
        bottom: calc(100% - 350px);
      }
    }
  }
  .restorePass-page,
  .login-page,
  .registration-page {
    & > .container {
      padding: 0;
      max-width: 265px;
    }
  }
  .registration-title {
    margin: 60px 0 30px;
    max-width: 95%;
    font-size: 25px;
  }
  .restorePass-text {
    margin-bottom: 20px;
    font-size: 11px;
  }
  .registration-text {
    padding: 10px 0 5px;
    font-size: 11px;

    &_portal {
      font-size: 12px;
    }
  }
  .registration-form {
    .profile-error {
      height: 20px;
      font-size: 9px;
    }
    &.ornament {
      &::before {
        left: 0;
        bottom: -60px;
      }
      &::after {
        right: -55px;
        bottom: -200px;
      }
    }
  }
  .registration-label::before {
    width: 15px;
    height: 15px;
  }
  .registration-label.message::before {
    top: 13px;
  }
  .registration-label-policy {
    margin-top: 5px;
    font-size: 11px;
    span {
      margin-right: 10px;
    }
  }
  .registration-input {
    padding: 5px 10px 5px 32px;
    font-size: 11px;
  }
  .message .registration-input {
    min-height: 80px;
  }
  .registration-buttons {
    gap: 20px;
  }
  .registration-btn,
  .registration-link {
    padding: 8px 0;
    font-size: 12px;
    &.lone {
      width: 140px;
    }
  }
}
