.button-filter-wrapper {
  position: relative;
}

.button-filter {
  padding: 10px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b4761;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);

  &__text {
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
  }

  &__active {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    right: -5px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: #db2947;
  }
}

@media (max-width: 1123px) {
  .button-filter {
    padding: 18px;
    width: 60px;
    height: 60px;
    justify-content: center;

    & svg {
      width: 24px;
      height: 24px;
    }

    &__text {
      display: none;
    }
  }
}
