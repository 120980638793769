@import 'src/app/styles/vars';
.main {
  // margin-left: 250px;
  padding: 30px 0;
  min-height: 100vh;
  @media (max-width: $md) {
    margin-left: 0;
    padding: 0;
    padding-bottom: 60px;
  }
}

.hideMenu {
  padding-bottom: 0 !important;
}

.wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  @media (max-width: $md) {
    grid-template-columns: 1fr;
  }
}