.home-intro {
  padding: 110px 0 320px;
  margin: 0 auto;
  color: #ffffff;

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__inner {
    margin-bottom: 125px;
    max-width: 800px;
  }

  &__title {
    margin-bottom: 32px;
    margin-left: 0;
    @include cv900;
    font-stretch: 670%;
    letter-spacing: 0.12em;
    font-weight: 1000;
    font-size: 80px;
    line-height: 90px;
    white-space: normal;
    color: #ffffff;
    text-transform: uppercase;

    span {
      color: #db2947;
    }
  }

  &__background {
    position: relative;
    &__image {
      position: absolute;
      top: -230px;
      left: 50%;
      z-index: -1;
      width: 100%;
      height: 1043px;
      transform: translateX(-50%);
      background: url(../../../../assets/images/homePage/intro/intro-background.png) no-repeat;
      background-size: cover;
      clip-path: polygon(0 0, 100% 0, 100% 75%, 20% 100%, 0 85%);
    }
  }
}

.home-intro__subtitle {
  max-width: 565px;
  @include a500;
  font-size: 22px;
  line-height: 36px;
  span {
    font-weight: 700;
    color: $blue;
  }
}

.home-intro__btn {
  width: 170px;
  padding: 14px 0;
  text-align: center;
}

@media (max-width: 1123px) {
  .home-intro {
    padding: 44px 0 100px;

    &__container {
      padding: 0 15px;
    }

    &__inner {
      margin-bottom: 107px;
      max-width: 350px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 30px;
      line-height: 40px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 24px;
    }

    &__background {
      &__image {
        top: -123px;
        height: 710px;
        background-position-x: 43%;
        clip-path: polygon(0 0, 100% 0, 100% 91%, 0 100%);
      }
    }
  }
}
