@import 'src/app/styles/vars';
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 30;
  left: 0;
  top: 0;
  background: $overlay;
}

.popup {
  position: fixed;
  left: 0;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.popupInner {
  position: absolute;
  left: 50%;
  overflow: auto;
  text-align: left;
  color: $c-blue-text;
  z-index: 50;
  background: white;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  padding: 25px;
  @media (max-width: $md) {
    max-width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    padding: 16px;
    transform: translate(0, 0);
  }
}

.close {
  position: absolute;
  cursor: pointer;
  right: 24px;
  top: 28px;
  z-index: 20;
}


.min {
  top: 20px !important;
}