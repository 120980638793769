.npa {
  margin-bottom: 95px;
}
.npa__inner {
  &::before {
    top: 0;
    left: -100px;
  }
  &::after {
    top: 345px;
    left: 50%;
  }
}
.npa__title {
  margin-left: 0;
  font-size: 74px;
  font-weight: 900;
  color: $blue;
  letter-spacing: 0.2em;
}
.npa__content {
  padding-top: 45px;
}
.npa__file-list {
  display: flex;
  flex-direction: column;
  gap: 27px;
  font-size: 32px;
  color: #2b4761;
}
.npa__file_link {
  display: inline-flex;
  gap: 20px;

  &:hover {
    color: $light-blue;
  }
}
.npa__file_icon {
  padding-top: 6px;
  width: 30px;
  min-width: 30px;
  color: $light-blue;
}

@media (max-width: 1440px) {
  .npa__title {
    font-size: 63px;
  }
  .npa__content {
    padding-top: 30px;
  }
  .npa__file-list {
    gap: 25px;
    font-size: 28px;
  }
  .npa__file_link {
    gap: 20px;
  }
  .npa__file_icon {
    padding-top: 3px;
    width: 30px;
    min-width: 30px;
  }
}

@media (max-width: 1024px) {
  .npa {
    margin-bottom: 60px;
  }
  .npa__title {
    font-size: 47px;
  }
  .npa__content {
    padding-top: 23px;
  }
  .npa__file-list {
    gap: 15px;
    font-size: 21px;
  }
  .npa__file_link {
    gap: 20px;
  }
  .npa__file_icon {
    padding-top: 0px;
    width: 25px;
    min-width: 25px;
  }
}
@media (max-width: 900px) {
  .npa__inner {
    &::before {
      top: 50px;
      left: 40%;
    }
    &::after {
      top: 400px;
      left: auto;
      right: 0%;
    }
  }
  .npa__title {
    padding-top: 36px;
  }
}
@media (max-width: 768px) {
  .npa__title {
    padding-top: 36px;
    font-size: 33px;
  }
  .npa__content {
    padding-top: 23px;
  }
  .npa__file-list {
    gap: 14px;
    font-size: 20px;
  }
  .npa__file_link {
    gap: 16px;
  }
  .npa__file_icon {
    width: 25px;
    min-width: 25px;
  }
}
@media (max-width: 500px) {
  .npa {
    margin-bottom: 30px;
  }
  .npa__title {
    padding-top: 9px;
    font-size: 23px;
  }
  .npa__content {
    padding-top: 15px;
  }
  .npa__file-list {
    gap: 8px;
    font-size: 14px;
  }
  .npa__file_link {
    gap: 10px;
  }
  .npa__file_icon {
    width: 20px;
    min-width: 20px;
  }
}
