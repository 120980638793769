.news-item {
  z-index: 999;
  position: relative;
  background-color: #0b2040;

  &__slider {

    .slick-dots {
      left: Max(calc((100% - 1200px) / 2), 40px);
      transform: translateY(100%);
      bottom: 0;
      position: absolute;
      display: flex !important;
      justify-content: left;
      align-items: center;
      gap: 12px;

      & li {
        margin: 0;
      }

      & button {
        width: 16px;
        height: 16px;
        border: 1.5px solid #2b4761;
        background-color: #ffffff;

        &::before,
        &::after {
          content: none;
        }

        &:hover {
          border-color: #2b4761;
          background-color: #ffffff;
        }
      }

      li.slick-active button {
        background-color: #2b4761;
        border-color: #2b4761;
      }
    }

    .button-slider {
      position: absolute;
      bottom: 0;

      &.right {
        right: Max(calc((100% - 1200px) / 2), 40px);
        transform: translateY(100%);
      }

      &.left {
        right: Max(calc((100% - 1200px) / 2 + 10px), 50px);
        transform: translate(-100%, 120%);
      }
    }
  }

  &__intro {
    padding: 95px 0 0;
    width: 100%;
    height: 710px;
    position: relative;
    color: #ffffff;
  }

  &__type {
    padding: 6px 12px;
    width: fit-content;
    height: 32px;
    position: relative;
    top: auto;
    left: auto;

    &::before {
      height: 100%;
    }

    &.location-tag {
      margin-top: 10px;
    }
  }

  &__title {
    padding-bottom: 25px;
    max-width: 870px;
    @include cv900;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.12em;
    font-stretch: 300%;
  }

  &__image {
    width: 100%;
    height: calc(100% + 120px);
    position: absolute;
    top: -120px;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;

    &::after,
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::before {
      height: calc(100% + 1px);
      clip-path: polygon(0 80%, 67% 100%, 100% 72%, 100% 100%, 0 100%);
    }

    &.important {
      &::before {
        background-color: white;
      }

      &::after {
        background-image: linear-gradient(179.81deg, rgba(11, 32, 64, 0.8) 18.3%, rgba(11, 32, 64, 0) 72.08%);
      }
    }
    &__btn {
      text-transform: uppercase;
      @include base-btn();
    }
  }
}

@media (max-width: 1123px) {
  .news-item {
    position: relative;

    &__slider {
      .slick-dots {
        left: 0;
        justify-content: center;
      }
    }

    &__intro {
      padding: 75px 0 0;
      position: relative;
      height: 475px;

      &__footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__type {
      padding: 2px 8px;
      height: 24px;
    }

    &__title {
      padding-bottom: 16px;
      font-size: 37px;
      line-height: 46px;
      font-stretch: 270%;
    }

    &__image {
      height: calc(100% + 75px);
      top: -80px;

      &::before {
        clip-path: polygon(0 100%, 100% calc(100% - 40px), 100% 100%);
        top: 4px
      }

      &__btn {
        position: absolute;
        display: flex;
        width: 328px;
        height: 60px;
        padding: 28px 24px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        @include base-btn();
      }
    }
  }
}

.news-container {
  z-index: 1000;
  padding-bottom: 70px;

  &__container {
    padding: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &__slider {
    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 12px;
      bottom: -70px;

      & li {
        margin: 0;
      }

      & button {
        width: 16px;
        height: 16px;
        border: 1.5px solid #2b4761;
        background-color: #ffffff;

        &::before,
        &::after {
          content: none;
        }

        &:hover {
          border-color: #2b4761;
          background-color: #ffffff;
        }
      }

      li.slick-active button {
        background-color: #2b4761;
        border-color: #2b4761;
      }
    }

    .button-slider {
      position: absolute;
      bottom: 0;

      &.right {
        right: Max(calc((100% - 1200px) / 2), 40px);
        transform: translateY(100%);
      }

      &.left {
        right: Max(calc((100% - 1200px) / 2 + 10px), 50px);
        transform: translate(-100%, 120%);
      }
    }
  }
}

@media (max-width: 1123px) {
  .news-container {
    padding-top: 65px;
    padding-bottom: 45px;

    &__slider {
      &.left-slide {
        .slick-list {
          padding: 0 15px 0 0 !important;
        }
      }

      &.right-slide {
        .slick-list {
          padding: 0 0 0 15px !important;
        }
      }

      .slick-dots {
        gap: 12px;
        bottom: -35px;

        & button {
          width: 12px;
          height: 12px;
        }
      }

      .button-slider {
        display: none;
      }
    }
  }
}


@media (max-width: 768px){
  .news-item {
    margin-top: -75px;
  }
}
