.travel-guide-item-gallery {
  padding: 60px 0 205px;
  background-color: #26784b;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 85px;
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 1;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    background-color: #e8eff6;
  }

  &__title {
    padding-bottom: 40px;
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
    color: #ffffff;
  }

  &__image {
    cursor: pointer;

    &__background {
      height: 540px;
      object-fit: cover;
    }
  }

  &__slider {
    .slick-list {
      overflow: unset;
    }

    .slick-track {
      margin: 0;
      display: flex;
      gap: 40px;

      .slick-slide {
        width: Min(580px, calc(50vw - 80px));
        flex-shrink: 0;
      }
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-gallery {
    padding: 30px 0 100px;

    &::before {
      height: 40px;
    }

    &__title {
      padding-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;
    }

    &__image {
      &__background {
        height: 300px;
      }
    }

    &__slider {
      .slick-track {
        gap: 15px;

        .slick-slide {
          width: calc(50vw - 57.5px);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-gallery {
    &__slider {
      .slick-track {
        .slick-slide {
          width: calc(100vw - 60px);
        }
      }
    }
  }
}
