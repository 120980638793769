.location-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #4f8ecc;
  border-radius: 50%;
  background-color: #ffffff;
  flex-shrink: 0;

  span {
    @include cv900;
    color: #2b4761;
    font-size: 12px;
    line-height: 11px;
    font-stretch: 300%;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: #4f8ecc;
  }
}
