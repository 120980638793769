.tourist-map {
  width: 100%;
  padding: 20px 0 60px;
  .balloon-text {
    padding: 10px 0;
  }
  .map-container {
    height: 500px;
  }
  &__inner {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .tourist-map {
    padding: 20px 0;
  }
}

@media (max-width: 500px) {
  .tourist-map {
    padding: 0 0 67px;
    height: 100vh;
    & .container {
      padding: 0;
      height: 100%;
    }
  }
  .tourist-map__inner {
    height: 100%;
  }
}
