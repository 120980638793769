.partner-item {
  padding: 50px 0 0;
  color: #2b4761;

  &__container {
    max-width: 1200px;
    padding: 0 40px;
  }

  &__header-wrapper {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1280px;
    min-width: 1px;
    min-height: 635px;
    display: grid;
    grid-template-areas:
      'header operator'
      'header params';
    grid-template-columns: 1fr 290px;
    grid-template-rows: auto 1fr;
    column-gap: 40px;
  }

  &__header {
    margin: 0 auto;
    padding: 40px 25px;
    width: 100%;
    max-width: 870px;
    min-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    position: relative;
    grid-area: header;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    overflow: hidden;

    &::before {
      content: '';
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;
      clip-path: polygon(0 100%, 100% 93%, 100% 100%);
      z-index: 1;
    }

    & .slick-slider {
      height: 100%;

      & .slick-list {
        height: 100%;

        & div {
          height: 100%;
        }
      }

      .button-slider {
        position: absolute;
        bottom: 55px;

        &.right {
          right: 25px;
          transform: translateY(-9px);
        }

        &.left {
          right: 37px;
          transform: translate(-100%, 0);
        }
      }
    }
  }

  &__back {
    padding: 8px 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 25px;
    left: 25px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;
    z-index: 1;

    &__icon {
      width: 16px;
      height: 16px;
      transform: rotate(180deg);

      path {
        stroke: #2b4761;
      }
    }
  }

  &__type {
    padding: 6px 12px;
    width: fit-content;
    position: relative;
    z-index: 1;
    @include cv900;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.12em;
    color: #ffffff;
    font-stretch: 270%;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
      transform: translateY(-50%);
      background-color: #2b4761;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 3% 90%);
    }
  }

  &__title {
    padding-bottom: 15px;
    max-width: 80%;
    min-height: 130px;
    @include cv900;
    font-size: 47px;
    line-height: 58px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
    z-index: 1;

    span {
      padding: 4px 25px 16px;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
      background-color: #ffffff;
    }
  }

  &__operator {
    grid-area: operator;

    &__container {
      padding: 0;
      max-width: 1200px;
    }

    &-content {
      padding: 25px 20px 50px;
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: #ebf1fa;
        clip-path: polygon(0 0, 100% 0, 100% 80%, 22% 87%, 12% 100%, 7% 87%, 0 87%);
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #ffffff;
        clip-path: polygon(
          4px 4px,
          calc(100% - 4px) 4px,
          calc(100% - 4px) calc(80% - 4px),
          calc(22%) calc(87% - 4px),
          calc(12% + 1px) calc(100% - 5px),
          calc(7% + 2px) calc(87% - 4px),
          4px calc(87% - 4px)
        );
      }
    }

    &-name {
      padding-bottom: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #db2947;
      text-transform: capitalize;
    }

    &-messangers-label {
      margin-top: 8px;
      text-align: center;
      padding-left: 15px;
      font-size: 16px;
    }

    &-messangers {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-telegram,
    &-whatsapp,
    &-vk,
    &-email,
    &-phone,
    &-site {
      padding-left: 32px;
      position: relative;
      font-size: 16px;
      line-height: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-position: center;
      }
    }

    &-phone {
      &::before {
        background: url('../../../../assets/images/common/phone.svg') center / cover no-repeat;
        background-size: 15px;
      }
    }

    &-email {
      margin-top: 8px;

      &::before {
        background: url('../../../../assets/images/common/email.svg') center / cover no-repeat;
        background-size: 16px 12px;
      }
    }

    &-site {
      margin-top: 8px;

      &::before {
        background: url('../../../../assets/images/common/site.svg') center / cover no-repeat;
        background-size: 24px;
      }
    }

    &-telegram {
      margin-top: 8px;
      &::before {
        background: url('../../../../assets/images/common/telegram.svg') center / cover no-repeat;
        background-size: 15px;
      }
    }
    &-whatsapp {
      margin-top: 8px;
      &::before {
        background: url('../../../../assets/images/common/whatsapp.svg') center / cover no-repeat;
        background-size: 15px;
      }
    }
    &-vk {
      margin-top: 8px;
      &::before {
        background: url('../../../../assets/images/common/vk.svg') center / cover no-repeat;
        background-size: 15px;
      }
    }

    &-more {
      padding: 20px 0;
      border: none;
      background-color: #db2947;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
      width: 100%;
      margin: 30px 0 50px 0;
      text-align: center;
    }
  }

  &__params {
    padding-top: 15px;
    grid-area: params;

    &__container {
      padding: 0;
      max-width: 1200px;
    }

    &-content {
      padding: 25px 20px 40px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: #ebf1fa;
        clip-path: polygon(0 0, 100% 3%, 100% 95%, 0 100%);
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #ffffff;
        clip-path: polygon(
          4px 4px,
          calc(100% - 4px) calc(3% + 4px),
          calc(100% - 4px) calc(95% - 4px),
          4px calc(100% - 4px)
        );
      }
    }

    &-name {
      padding-bottom: 2px;
      font-size: 14px;
      line-height: 20px;
      color: #94a5b4;
    }

    &-value {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__description {
    padding-top: 45px;

    &-content {
      width: calc(100% - 290px - 40px);
    }

    &-title {
      padding-bottom: 15px;
      @include cv900;
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 0.12em;
      font-stretch: 270%;
      color: #94a5b4;
    }
  }

  &__video {
    padding-top: 45px;

    .yvideo {
      position: relative;
      width: 100%;
      height: 100%;
      padding-bottom: 56.25%;
      background-color: #000;

      &.yvideo--enabled .yvideo__btn {
        display: block;
      }
    }

    .yvideo__media,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .yvideo__media {
      object-fit: cover;
    }

    .yvideo__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .yvideo__button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      display: none;
      padding: 0;
      width: 68px;
      height: 48px;
      border: none;
      background-color: transparent;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .yvideo__button-shape {
      fill: #212121;
      fill-opacity: 0.8;
    }

    .yvideo__button-icon {
      fill: #ffffff;
    }

    .yvideo__button:focus {
      outline: none;
    }

    .yvideo:hover .yvideo__button-shape,
    .yvideo__button:focus .yvideo__button-shape {
      fill: #ff0000;
      fill-opacity: 1;
    }

    /* Enabled */
    .yvideo--enabled {
      cursor: pointer;
    }

    .yvideo--enabled .yvideo__button {
      display: block;
    }
  }

  &__tours {
    margin-top: 80px;
    padding: 125px 0 220px;
    background-color: #e8eff6;
    clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 140px), 30% 100%, 0 calc(100% - 170px));

    &__title {
      margin: 0 auto;
      padding-bottom: 40px;
      width: fit-content;
      @include cv900;
      text-align: center;
      font-size: 47px;
      line-height: 58px;
      letter-spacing: 0.12em;
      font-stretch: 270%;

      ::before {
        content: '';
        display: block;
        width: calc(100% + 90px);
        height: calc(100% + 45px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #d9e5f0;
        z-index: -1;
        clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 calc(100% - 5px));
      }
    }
  }

  &__bottom {
    height: 120px;
  }
}

@media (max-width: 1123px) {
  .partner-item {
    padding: 0;
    position: relative;
    z-index: 1;

    &__container {
      padding: 0 15px;
    }

    &__header-wrapper {
      padding: 0;
      display: block;
    }

    &__header {
      padding: 15px;
      height: 560px;
      max-width: 1440px;
      gap: 15px;
    }

    &__back {
      top: 15px;
      left: 15px;
      font-size: 12px;
      line-height: 16px;

      &__icon {
        width: 12px;
        height: 12px;
      }
    }

    &__type {
      padding: 2px 8px;

      &::before {
        width: 100%;
        height: 25px;
      }
    }

    &__title {
      padding-bottom: 15px;
      max-width: 410px;
      min-height: 150px;
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;

      span {
        padding: 4px 16px 14px;
      }
    }

    &__operator {
      padding-top: 25px;

      &__container {
        padding: 0 15px;
      }

      &-content {
        padding: 25px 20px 50px;

        &::after {
          clip-path: polygon(0 0, 100% 0, 100% 80%, 22% 87%, 12% 100%, 7% 87%, 0 87%);
        }

        &::before {
          clip-path: polygon(
            4px 4px,
            calc(100% - 4px) 4px,
            calc(100% - 4px) calc(80% - 4px),
            calc(22%) calc(87% - 4px),
            calc(12% + 1px) calc(100% - 5px),
            calc(7% + 2px) calc(87% - 4px),
            4px calc(87% - 4px)
          );
        }
      }

      &-name {
        padding-bottom: 12px;
        font-size: 16px;
      }
    }

    &__params {
      padding-top: 15px;

      &__container {
        padding: 0 15px;
      }

      &-content {
        padding: 25px 20px;

        &::after {
          clip-path: polygon(0 0, 100% 3%, 100% 95%, 0 100%);
        }

        &::before {
          clip-path: polygon(
            4px 4px,
            calc(100% - 4px) calc(3% + 4px),
            calc(100% - 4px) calc(95% - 4px),
            4px calc(100% - 4px)
          );
        }
      }

      &-name {
        font-size: 12px;
        line-height: 16px;
      }

      &-value {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__description {
      padding-top: 25px;

      &-content {
        width: 100%;
      }

      &-title {
        font-size: 18px;
        line-height: 26px;
      }

      &-text {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__video {
      padding-top: 25px;
    }

    &__tours {
      margin-top: 60px;
      padding: 60px 0 65px;
      clip-path: polygon(0 0, 100% 15px, 100% calc(100% + 40px), 0 100%, 0 0);
      position: relative;

      &__container {
        padding: 0 15px;
      }

      &__title {
        padding-bottom: 20px;
        font-size: 24px;
        line-height: 32px;
        font-stretch: 300%;

        ::before {
          width: calc(100% + 50px);
          height: calc(100% + 40px);
          top: 50%;
          left: 50%;
          clip-path: polygon(5px 0, 100% 10px, calc(100% - 5px) calc(100% - 5px), 0 100%);
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 1px;
        left: 0;
        transform: translateY(100%);
        background-color: #e8eff6;
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        z-index: -1;
      }
    }

    &__bottom {
      display: none;
    }
  }
}

@media (min-width: 769px) {
  .partner-item {
    .slick-dots {
      display: none !important;
    }
  }
}

@media (max-width: 768px) {
  .partner-item {
    &__header {
      margin-bottom: 25px;
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 12px;
      bottom: -25px;
      z-index: 1;

      & button {
        width: 12px;
        height: 12px;
        border: 1.5px solid #2b4761;
        background-color: #ffffff;

        &::before,
        &::after {
          content: none;
        }

        &:hover {
          border-color: #2b4761;
          background-color: #ffffff;
        }
      }

      & li {
        margin: 0;
      }

      li.slick-active button {
        background-color: #2b4761;
        border-color: #2b4761;
      }
    }

    &__video {
      .yvideo {
        display: block;
      }
    }
  }
}
