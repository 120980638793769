.partner-card {
  width: 100%;
  min-width: 0;
  height: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 103%;
    height: 109%;
    max-width: calc(100% + 40px);
    max-height: calc(100% + 30px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: background-color 300ms ease-in-out;
    clip-path: polygon(0 7%, 97% 0, 100% 98%, 1% 100%);
  }

  &__content {
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index: 1;
    background: #f5f8fa;
    cursor: pointer;
  }

  .slick-track {
    .slick-slide {
      padding: 0;
    }
  }

  &__preview {
    width: 100%;
    height: 240px;
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__type {
    padding: 0 8px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
    @include cv900;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.12em;
    color: #ffffff;
    font-stretch: 270%;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background-color: #2b4761;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 3% 90%);
    }
  }

  &__description {
    padding: 0 16px;
  }

  &__name {
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #2b4761;
  }

  &__text {
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &::before {
        background-color: #db2947;
      }
    }
  }
}

@media (max-width: 1123px) {
  .partner-card {
    &__content {
      padding-bottom: 15px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      z-index: 1;
      background: #f5f8fa;
      cursor: pointer;
    }

    &__preview {
      height: 240px;
    }

    &__description {
      padding: 0 12px;
    }

    &__name {
      padding-bottom: 12px;
      font-size: 20px;
      line-height: 24px;
    }

    &__text {
      max-height: 72px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
