.travel-guide-item-description {
  padding: 60px 0 60px;
  color: #ffffff;

  &.city {
    background-color: #1c2543;
  }

  &.flora {
    background-color: #26784b;
  }

  .description {
    display: flex;
    gap: 40px;

    &__title {
      padding-bottom: 25px;
      @include cv900;
      font-size: 64px;
      line-height: 78px;
      letter-spacing: 0.12em;
      font-stretch: 300%;
    }

    &__content {
      flex-grow: 1;
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
    }

    &__params {
      width: 285px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-shrink: 0;
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-description {
    padding: 20px 0 30px;

    .description {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &__title {
        padding-bottom: 16px;
        font-size: 24px;
        line-height: 32px;
      }

      &__text {
        font-size: 16px;
      }

      &__content {
        order: 2;
      }

      &__params {
        width: 100%;
        order: 1;
        gap: 16px;
      }
    }
  }
}
