.tour-card {
  width: 100%;
  height: 100%;
  min-width: 0;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 109%;
    max-width: calc(100% + 40px);
    max-height: calc(100% + 30px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: background-color 300ms ease-in-out;
    clip-path: polygon(0 7%, 97% 0, 100% 98%, 1% 100%);
  }

  &__content {
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index: 1;
    background: #f5f8fa;
    cursor: pointer;
  }

  &__preview {
    width: 100%;
    height: 240px;
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__type {
    padding: 15px;
    position: absolute;
    margin-left: 20px;
    top: 0;
    left: 0;
    z-index: 1;
    @include cv900;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
    color: #ffffff;

    &::before {
      content: '';
      display: block;
      width: 80px;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background-color: #2b4761;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 3% 90%);
    }
  }

  &__description {
    padding: 0 16px;

    &.first {
      flex-grow: 1;
    }

    &.second {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
    }
  }

  &__name {
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #2b4761;
  }

  &__operator {
    overflow: hidden;
  }

  &__operator-photo {
    margin-left: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__operator-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #94a5b4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__params {
    display: flex;

    &__data {
      padding: 0 15px;
      border-right: 1px solid #d9e0e6;
      border-left: 1px solid #d9e0e6;

      &:first-child {
        padding-left: 0;
        border: none;
      }
      &:last-child {
        padding-right: 0;
        border: none;
      }
    }

    &__name {
      padding-bottom: 2px;
      font-size: 12px;
      line-height: 16px;
      color: #94a5b4;
    }

    &__value {
      font-size: 16px;
      line-height: 24px;
      color: #2b4761;

      &.group,
      &.age {
        white-space: nowrap;
      }

      &.price {
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #2b4761;
        white-space: nowrap;

        & .ruble-icon {
          height: 14px;
          display: inline-block;
        }
      }

      &.duration {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #94a5b4;
      }
    }
  }

  &__text {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &::before {
        background-color: #db2947;
      }
    }
  }
}

@media (max-width: 1123px) {
  .tour-card {
    &__content {
      padding-bottom: 15px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      z-index: 1;
      background: #f5f8fa;
      cursor: pointer;
    }

    &__preview {
      height: 200px;
    }

    &__description {
      padding: 0 12px;
    }

    &__name {
      padding-bottom: 12px;
      font-size: 20px;
      line-height: 24px;
    }

    &__params {
      &__data {
        &:nth-child(2),
        &:nth-child(3) {
          white-space: nowrap;
        }
      }
      &__value.price {
        padding: 0 0 2px;
      }
    }
  }
}
