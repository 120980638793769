.designer-locations-list {
  padding-right: 12px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: #db2947 #1c2543;

  /* для Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    height: 12px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #1c2543;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #db2947;
    border-radius: 5px;
  }
}

.designer-locations-item {
  padding: 12px 16px;
  background-color: #2e3652;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #db2947;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__content {
    display: flex;
    gap: 16px;
  }

  &__name {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
  }

  &__desription {
    overflow: hidden;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 16px;
    color: #d9e0e5;
  }

  &__image {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    object-fit: cover;
  }

  &__territory {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;

    span {
      font-weight: 400;
      color: #94a5b4;
    }
  }
}

@media (max-width: 900px) {
  .designer-locations-list {
    width: 100%;
    height: 100%;
  }
}
