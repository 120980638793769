
.bb {
  border-bottom: 1px solid #D9E0E5;
}

.null {
  padding: 0;
  max-height: 568px;
}

.popupContainer {
  padding: 16px;
}

.oper {
  margin-top: 16px;
}

.operFiles {
  &:last-child {
    border-bottom: none;
  }
}

.userFiles {
  &:last-child {
    border-bottom: none;
  }
}