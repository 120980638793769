.filters-manage {
  padding: 40px;
  display: flex;
  gap: 15px;
  border-top: 1px solid #e8eaed;
  background-color: #ffffff;
}

.filters-button {
  padding: 28px 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;

  &.full {
    flex-grow: 1;
  }

  &.light {
    border: 2px solid #2b4761;
    background-color: #ffffff;
    color: #2b4761;

    & svg path {
      stroke: #ffffff;
    }

    &.disabled {
      border-color: #e8eaed;
      color: #d9e0e6;
    }
  }

  &.dark {
    border: 2px solid #2b4761;
    background-color: #2b4761;
    color: #ffffff;

    & svg path {
      stroke: #ffffff;
    }
  }

  &[disabled] {
    border-color: #e8eaed;
    color: #d9e0e6;
    background-color: #ffffff;
  }
}

@media (max-width: 1123px) {
  .filters-manage {
    padding: 24px 15px;
    flex-direction: column;
    gap: 15px;
    border-top: 1px solid #e8eaed;
  }
  .filters-button {
    padding: 20px 0;
    font-size: 14px;
    line-height: 20px;

    &.light {
      border-color: #d9e0e6;
    }

    &_clear {
      order: 2;

      &[disabled] {
        display: none;
      }
    }
  }
}
