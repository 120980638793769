.cookie-notification {
  padding: 10px 50px;
  width: 100%;
  display: flex;
  gap: 50px;
  position: fixed;
  z-index: 100000;
  bottom: 0;
  background: linear-gradient(0deg, #181813 0%, rgba(24, 24, 19, 0) 174.29%);

  .link {
    color: inherit;
    text-decoration: underline;
  }

  &__text {
    font-size: 20px;
    color: #ffffff;
  }

  &__accept {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1440px) {
  .cookie-notification {
    &__text {
      font-size: 16px;
    }
    &__btn {
      padding: 11px 24px;
    }
  }
}
@media (max-width: 1024px) {
  .cookie-notification {
    padding: 10px 25px;
    gap: 25px;
    &__text {
      font-size: 12px;
    }
    &__btn {
      padding: 8px 24px;
      font-size: 11px;
    }
  }
}

@media (max-width: 768px) {
  .cookie-notification {
    padding: 10px 10px;
    flex-direction: column;
    gap: 5px;
    &__text {
      font-size: 12px;
    }
    &__accept {
      align-self: flex-end;
    }
    &__btn {
      padding: 8px 24px;
      font-size: 11px;
    }
  }
}

@media (max-width: 500px) {
  .cookie-notification {
    padding: 10px 10px;
    &__text {
      font-size: 10px;
    }
    &__btn {
      padding: 5px 10px;
      font-size: 10px;
    }
  }
}
