@mixin base-title($color: $blue) {
  @include cv900;
  color: $color;
  font-size: 47px;
  line-height: 58px;
  letter-spacing: 5.64px;
  font-stretch: 317%;
}

@mixin base-become-member-btn {
  margin-top: 40px;
  border-radius: 0;
  text-transform: uppercase;
}

.members-content {
  margin-bottom: 60px;
  overflow: hidden;
  &__title {
    @include base-title();
    z-index: 5;
  }

  &__grid-3 {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;

    &__item {
      position: relative;
      height: 241px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      padding: 30px;
      color: #db2947;
      transition: color 200ms;
      z-index: 5;

      p {
        color: #d9e0e5;
        @include a400;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 32px;
      }

      @media (hover: hover) {
        &:hover {
          color: white;
        }

        &:hover &__icon {
          fill: white;
        }
      }

      &__icon {
        fill: #db2947;
        flex-shrink: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #0b2040;
      }

      &:hover {
        &::before {
          background-color: #db2947;
        }
      }
      &__first {
        &:before {
          clip-path: polygon(0 0, 100% 0, 97% 100%, 8px 100%);
        }
      }

      &__second {
        &:before {
          clip-path: polygon(8px 8px, 100% 0, 100% 100%, 0 100%);
        }
      }

      &__third {
        &:before {
          clip-path: polygon(0 0, 100% 8px, 96% 100%, 0 100%);
        }
      }

      &__fourth {
        &:before {
          clip-path: polygon(8px 0, 97% 0, 100% 100%, 0 100%);
        }
      }

      &__fifth {
        &:before {
          clip-path: polygon(0 0, 100% 0, 96% 100%, 10px 100%);
        }
      }

      &__sixth {
        &:before {
          clip-path: polygon(10px 0, 97% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  &__cluster {
    margin-top: 64px;
    display: flex;
    align-items: flex-start;
    gap: 58px;

    &__become-member {
      @include base-become-member-btn;
    }

    span {
      width: 16px;
      height: 16px;
      background: #db2947;
      flex-shrink: 0;
      margin-top: 4px;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 16px;
      align-self: stretch;

      &__desc {
        margin-top: -8px;
        margin-left: 18px;
      }

      h4 {
        color: $dark-blue;
        @include cv900;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 2.64px;
        font-stretch: 300%;
      }

      p {
        color: $gray40;
        @include a400;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 8px;
      }
    }
  }
}

.become-member-content {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  &__send-btn {
    @include a500;
    margin-top: 24px;
    border-radius: 0;
    padding: 16px 48px;
    text-transform: uppercase;
    width: 100%;
    font-size: 16px;
  }
}

.become-member_desc {
  color: $gray40;
  @include a400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}

.become-member-input {
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 20px;
  align-items: center;
  gap: 10px;
  border: 2px solid #ebf1fa;
  background-color: #fff;
  &::placeholder {
    color: $gray40;
  }
}

.members-cards-sm {
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: flex-start;
  gap: 18px;
  background: #f3f4f5;

  p {
    @include a400;
    color: $dark-blue;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 900px) {
  .members-content {
    &__title {
      @include cv1000;
      color: $gray40;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 2.88px;
      font-stretch: 390%;
    }

    &__grid-3 {
      margin-top: 24px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0.5rem;

      &__item {
        &__icon {
          width: 32px;
          height: 32px;
        }
      }
    }

    &__cluster {
      display: block;

      &__become-member {
        display: none;
      }

      &__item {
        h4 {
          color: $dark-blue;
          @include a700;
          font-size: 19px;
          line-height: 30px;
          letter-spacing: 0;
        }

        p {
          color: $dark-blue;
          @include a400;
          font-size: 16px;
          line-height: 24px;
          padding-top: 8px;
        }
      }
    }

    .cl-sm {
      margin-bottom: 34px;
    }
  }
}
