.galleryBlock {
  margin-top: 40px;

   .slide {
     height: 550px;
     img{
       height: 100%;
       width: 100%;
     }
     position: relative;
     &__author{
       display: flex;
       padding: 5px 8px 5px 7px;
       align-items: flex-start;
       gap: 10px;
       background: #FFF;
       color: #2B4761;
       font-family: 'AkzidenzGroteskPro', sans-serif;
       font-size: 12px;
       font-style: normal;
       font-weight: 400;
       line-height: 16px; /* 133.333% */
       position: absolute;
       bottom: 16px;
       right: 16px;
     }
   }
  .slideImgDot{
    display: flex !important;
    align-items: flex-start;
    gap: 15px;
    padding-top: 15px;
  }
  .slideImgDot img{
    height: 95px;
  }

  .dotMobile {
    border: 2px solid #2B4761;
    width: 12px;
    height: 12px;
    border-radius: 10px;
  }
  .slick-active .dotMobile {
    background: #2B4761;
  }
}

@media (max-width: 768px){
.galleryBlock{
  margin-bottom: 40px;
  .slide {
    height: 207px;
  }
}
}
