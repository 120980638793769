.news-filter-item {
  padding: 10px 15px;
  flex-direction: column;
  gap: 35px;
  border: 2px solid #e8eaed;
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
  color: #2b4761;
  display: flex;

  &_full {
    padding: 15px 10px;
    flex-grow: 1;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: $gray;
    }
  }

  &.active {
    background: $gray;
  }
}
