.travel-guide-item-map {
  padding: 100px 0 0;

  &__container {
    padding: 0;
    max-width: 100%;
  }

  &__title {
    margin: 0 auto;
    width: fit-content;
    position: relative;
    z-index: 1;
    @include cv900;
    font-size: 47px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.12em;
    font-stretch: 270%;

    &::before {
      content: '';
      width: 520px;
      height: 105px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 calc(100% - 5px));
    }
  }

  &.blue {
    background-color: #e8eff6;

    .travel-guide-item-map {
      &__title::before {
        background-color: #d9e5f0;
      }
    }
  }

  &.black {
    background-color: #0f0f0f;

    .travel-guide-item-map {
      &__title {
        color: #ffffff;

        &::before {
          background-color: #202020;
        }
      }
    }
  }

  &.beige {
    background-color: #f2eee8;

    .travel-guide-item-map {
      &__title {
        &::before {
          background-color: #edebde;
        }
      }
    }
  }

  &__map {
    margin-top: 100px;
    width: 100%;
    height: 770px;
    position: relative;
    z-index: 1;
    background-color: #ffffff;

    &::before {
      content: '';
      width: 100%;
      height: 10%;
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 1;
      clip-path: polygon(0 100%, 100% 0, 100% 100%);
    }

    &.white::before {
      background-color: #ffffff;
    }

    &.black::before {
      background-color: #0f0f0f;
    }

    &.blue::before {
      background-color: #e8eff6;
    }

    .ol-overlay-container {
      top: 0;
    }
  }

  &__designer-btn {
    padding: 11px 12.5px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    border: none;
    background-color: #ffffff;

    span {
      display: inline-block;
      padding: 15px 16px;
      background-color: #db2947;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.64px;
      text-transform: uppercase;
      color: #ffffff;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-map {
    padding: 60px 0 0px;

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;

      &::before {
        width: 240px;
        height: 70px;
        clip-path: polygon(10px 3px, 100% 5px, 100% calc(100% - 5px), 3px calc(100% - 5px));
      }
    }

    &__map {
      margin-top: 50px;
      height: 640px;

      &::before {
        width: 100%;
        height: 10%;
        clip-path: polygon(0 100%, 100% 0, 100% 100%);
      }

      .ol-overlay-container {
        top: 0;
      }
    }

    &__designer-btn {
      padding: 10px;
      bottom: 80px;

      span {
        padding: 13px 50px;
        font-size: 14px;
      }
    }
  }
}
