.location-gallery {
  display: flex;
  gap: 20px;
  height: 100%;
  position: relative;

  &__aside {
    padding: 16px;
    padding-right: 0;
    width: 26%;
    max-width: 360px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
    position: relative;
    left: 0;
    background-color: #1c2543;
    overflow: hidden;
  }

  &__header {
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
  }

  &__close {
    margin-top: 3px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    fill: #ffffff;
    cursor: pointer;

    &.mobile {
      margin-top: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__list {
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    scroll-behavior: smooth;

    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #1c2543;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #1c2543;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__item {
    height: 200px;
    flex-shrink: 0;
    cursor: pointer;

    &.active {
      border: 4px solid #db2947;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gallery-slider {
    overflow: hidden;

    &__wrapper {
      padding: 0 75px;
      height: calc(100vh - 40px);
    }

    &__image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .button-slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }
    }
  }
}

@media (max-width: 1123px) {
  .location-gallery {
    flex-direction: column;
    gap: 60px;

    .gallery-slider {
      &__wrapper {
        height: calc(100vh - 172px);
      }
    }

    .double-slider {
      height: 72px;
      width: 100%;
      max-width: calc(87px * 4);
      margin: 0 auto;

      &_flex {
        .slick-track {
          display: flex;
          justify-content: center;
        }
      }

      .slick-list {
        height: 100%;
      }

      .slick-slide {
        width: 84px;
      }

      &__wrapper {
        padding: 0 6px;
        height: 72px;
      }

      &__image {
        width: 100%;
        height: 100%;

        &.active {
          border: 2px solid #db2947;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .location-gallery {
    .gallery-slider {
      &__wrapper {
        padding: 30px 2px 0;
      }

      .button-slider {
        display: none;
      }
    }
  }
}
