.home-impression {
  padding-top: 140px;
  padding-bottom: 90px;

  &__container {
    padding: 0;
    max-width: 1440px;
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &__slider {
    .slick-slide {
      width: 33.33333333% !important;
      position: relative;

      &:nth-child(1) {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);

        .home-impression__img {
          background-image: url('../../../../assets/images/homePage/impression/helicopter.svg');
          background-size: 120%;
        }

        .home-impression__background {
          background-color: #c7e0f1;
          clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
        }
      }
      &:nth-child(2) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);

        .home-impression__img {
          background-image: url('../../../../assets/images/homePage/impression/quad_bike.svg');
          background-size: 100%;
        }

        .home-impression__background {
          background-color: #a2d0ea;
          clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
        }
      }
      &:nth-child(3) {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);

        .home-impression__img {
          background-image: url('../../../../assets/images/homePage/impression/cruise.svg');
          background-size: 167%;
        }

        .home-impression__background {
          background-color: #c7e0f1;
          clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
        }
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 12px;
      bottom: -70px;

      & li {
        margin: 0;
      }

      & button {
        width: 16px;
        height: 16px;
        border: 1.5px solid #2b4761;
        background-color: #ffffff;

        &::before,
        &::after {
          content: none;
        }

        &:hover {
          border-color: #2b4761;
          background-color: #ffffff;
        }
      }

      li.slick-active button {
        background-color: #2b4761;
        border-color: #2b4761;
      }
    }

    .button-slider {
      position: absolute;
      bottom: 0%;

      &.right {
        right: Max(calc((100% - 1200px) / 2), 40px);
        transform: translateY(100%);
      }

      &.left {
        right: Max(calc((100% - 1200px) / 2 + 10px), 50px);
        transform: translate(-100%, 120%);
      }
    }
  }

  &__item {
    padding: 125px 0 0 120px;
    width: 100%;
    height: 660px;
    position: relative;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &__title {
      padding-bottom: 20px;
      text-align: unset;

      .title-section__title {
        margin: 0;
        font-size: 47px;
        font-stretch: 160%;
      }
    }

    &__text {
      max-width: 420px;
      font-size: 18px;
      line-height: 26px;
    }

    &__btn {
      padding: 24px 0;
      width: 165px;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: uppercase;
      background-color: transparent;
      border: none;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.04em;
      color: #2b4761;
      position: relative;

      & svg path {
        stroke: #2b4761;
      }
    }
  }
}

.home-impression__btn {
  margin-top: 60px;
  width: 170px;
  padding: 14px 0;
  text-align: center;
}

@media (max-width: 1123px) {
  .home-impression {
    padding-top: 65px;
    padding-bottom: 45px;

    &__slider {
      &.left-slide {
        .slick-list {
          padding: 0 15px 0 0 !important;
        }
      }

      &.right-slide {
        .slick-list {
          padding: 0 0 0 15px !important;
        }
      }

      .slick-slide {
        &:nth-child(1) {
          .home-impression__img {
            background-size: Max(100%, 470px);
          }
        }
        &:nth-child(2) {
          .home-impression__img {
            background-size: Max(85%, 450px);
          }
        }
        &:nth-child(3) {
          .home-impression__img {
            background-size: Max(140%, 680px);
          }
        }
      }

      .slick-dots {
        gap: 12px;
        bottom: -35px;

        & button {
          width: 12px;
          height: 12px;
        }
      }

      .button-slider {
        display: none;
      }
    }

    &__item {
      padding: 70px 15px 0;
      width: 100%;
      height: 480px;

      &__title {
        padding-bottom: 16px;

        .title-section__title {
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.12em;
          font-stretch: 200%;
        }
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
      }

      &__btn {
        padding: 24px 0;
        width: 165px;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }

  .home-impression__btn {
    margin-top: 60px;
    width: 170px;
    padding: 14px 0;
    text-align: center;
  }
}
