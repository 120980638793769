.tours-header {
  padding: 0 30px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  grid-area: header;
}
.tours-header__logo {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  img {
    max-width: 135px;
  }
}
.tours-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.tours-header__nav-item {
  display: inline-block;
  & + .tours-header__nav-item {
    margin-left: 35px;
  }
}
.tours-header__link {
  @include a500;
  font-size: 14px;
  &.active {
    color: $red;
  }
}
.tours-header__search {
  display: flex;
  gap: 15px;
  align-items: center;
  max-width: 500px;
  width: 100%;
}
.tours-header__search-text {
  font-size: 14px;
  @include a500;
  flex-shrink: 0;
}
.tours-header__search-label {
  max-width: 380px;
  width: 100%;
  position: relative;
}
.tours-header__input {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 4px;
  background-color: $gray;
  padding: 5px 15px 5px 40px;
  @include a500;
  font-size: 14px;
}
.tours-header__search-btn {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: url('../../assets/images/search.svg') center / cover no-repeat;
  width: 17px;
  height: 17px;
  border: none;
  font-size: 0;
}
.tours-header__lk {
  position: relative;
  padding-right: 30px;
  @include a500;
  font-size: 14px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 25px;
    background: url('../../assets/images/lk-user.svg') center / cover no-repeat;
  }
}
.tours-header__home {
  position: relative;
  padding-right: 30px;
  @include a500;
  font-size: 14px;
  margin-right: 30px;
  &::after {
    content: '';
    position: absolute;
    top: 49%;
    right: 0;
    width: 16px;
    height: 25px;
    background: url('../../assets/images/Arrow.svg') no-repeat;
  }
}

.Aheader__links-item {
  margin-right: 0;
  margin-left: auto;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  button {
    border: none;
    background-color: transparent;
    color: $dark-blue;
  }

  &.open {
    .Aheader__links-tosite {
      display: block;
    }
  }
}

.Aheader__links-tosite {
  position: absolute;
  top: calc(100% + 5px);
  width: max-content;
  padding: 10px 10px 15px;
  border: 1px solid #dedede;
  background-color: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 20;
  display: none;
  cursor: default;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
    .Aheader__tosite-item {
      padding-left: 25px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: center / cover no-repeat;
      }
      &.profile::after {
        width: 9px;
        height: 13px;
        background-image: url('../../assets/images/account/blue-user.svg');
      }
      &.logout::after {
        width: 13px;
        height: 13px;
        background-image: url('../../assets/images/account/logout-arrow.svg');
      }
    }
  }
}
.Aheader__tosite-itemtitle {
  font-size: 13px;
  font-weight: 500;
  color: $blue;
  padding-bottom: 7px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.Aheader__tosite-item {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  & + .Aheader__tosite-item {
    margin-top: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 1px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    &::before {
      width: 100%;
      background-color: $red;
    }
  }
  &:hover::before {
    width: 100%;
    background-color: $red;
  }
}
.Aheader__tosite-link,
button.Aheader__tosite-link {
  width: 100%;
  color: $blue;
  text-align-last: left;
}

@media (max-width: 1280px) {
  .tours-header {
    height: 50px;
  }
  .tours-header__link {
    font-size: 12px;
  }
  .tours-header__lk {
    font-size: 12px;
    padding-right: 20px;
    &::after {
      width: 12px;
      height: 19px;
    }
  }
}
@media (max-width: 900px) {
  .tours-header {
    &:not(.tours-header__admin) {
      display: none;
    }
  }
}
