@import 'src/app/styles/vars';
.wrapper {
  display: grid;
  overflow-x: auto;
  align-content: start;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 10px;
  gap: 15px;
}

.column {
  display: grid;
  gap: 20px;
  align-self: start;
}

.head {
  padding: 15px;
  background: $c-blue-dark;
  min-width: 330px;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-top: 4px solid;
  &.available {
    border-color: $c-blue-main;
  }
  &.now {
    border-color: $c-orange;
  }
  &.end {
    border-color: $c-green;
  }
  &.unaccepted {
    border-color: $c-red;
  }
}