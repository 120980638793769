.inner-about-content {
  width: 100% !important;
  position: absolute;
  z-index: 1;
  top: 200px;
  overflow: hidden;
  &__content {
    padding: 80px 70px 60px 120px;
  }
}

.card-modal-info {
  background-color: #ffffff;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  position: relative;

  &__rec {
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: -10px;
  }

  &__content {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  &__title {
    color: $dark-blue;
    @include cv1000;
    font-size: 22px;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 2.64px;
  }

  &__grip-2 {
    @include grid-base(2);
    width: 100%;
  }

  &__feature {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    background: $gray10;

    &__title {
      color: $gray40;
      @include a400;
      font-size: 12px;
      line-height: 16px;
    }

    &__count {
      color: $dark-blue;
      @include a500;
      font-size: 16px;
      line-height: 24px;
    }

    &__description {
      color: $dark-blue;
      @include a400;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
@mixin main-about-container {
  color: #ffffff;
  margin-top: -200px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 2524px;
  background: linear-gradient(to top, transparent 43%, rgba(101, 154, 170, 0.7) 50%, rgb(101, 154, 170) 100%);
  border: none;
  outline: none;
  margin-bottom: 50px;

  &__info {
    position: absolute;
    right: 18.5%;
    bottom: 25%;
    z-index: 5;
  }
}

.old-container {
  @include main-about-container;
  &:before {
    content: '';
    margin-top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: bottom;
    background-size: contain;
    background-image: url(../../../assets/images/trk/map.png);
    background-repeat: no-repeat;
  }
}

.new-container {
  @include main-about-container;
  &:before {
    content: '';
    margin-bottom: -50px;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-position: bottom;
    background-size: cover;
    background-image: url(../../../assets/images/trk/map.png);
    background-repeat: no-repeat;
  }

  &__point-on-map {
    position: relative;

    p {
      @include c900;
      position: absolute;
      left: 25px;
      top: 13px;
      color: #fff;
      font-size: 12px;
      line-height: normal;
      letter-spacing: 1.32px;
      text-transform: uppercase;
    }
  }
}

.first-block {
  padding-top: 240px;
  z-index: 2;

  &__become-member {
    text-transform: uppercase;
    white-space: nowrap;
    width: 225px;
    @include a500;
    padding: 14px 24px;
    background-color: $red;
    border-radius: 0;
    border: none;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.64px;

    &:hover,
    &:focus {
      background-color: darken($red, 7%);
    }
    margin-top: 40px;
    margin-bottom: 120px;
  }

  &.inner {
    display: flex;
    flex-direction: column;

    p {
      color: #ffffff;
      @include a400;
      font-size: 18px;
      line-height: 24px;

      br {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }

  &__text-container {
    width: 549px;
    z-index: 100;

    p {
      color: #ffffff;
      @include a400;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.about-main-slider {
  overflow: hidden;
  width: 100%;
  margin-top: -156px;
  z-index: 1;

  h4 {
    color: #ffffff;
  }
}

@media (min-width: 1368px) {
  .old-container,
  .new-container {
    background: linear-gradient(to top, transparent 40%, #659aaa 100%);
    height: 3100px;
    &:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      background-size: cover;
      background-image: url(../../../assets/images/trk/map.png);
      background-repeat: no-repeat;
    }
    width: 100%;
    &__info {
      bottom: 28%;
    }

    &__imag {
      height: 2592px;
    }
  }
}

@media (max-width: 1123px) {
  .old-container,
  .new-container {
    background: linear-gradient(to top, transparent 40%, #659aaa 100%);
  }
}

@media (max-width: 900px) {
  .portal-title {
    font-weight: 900;
    max-width: 500px;
    font-size: 24px;
    text-align: start;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #2b4761;
  }

  .card-modal-info {
    padding: 0;
    align-items: unset;
    margin-top: 10px;

    &__rec {
      display: none;
    }

    &__content {
      display: flex;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
    }

    &__title {
      display: none;
    }

    &__grip-2 {
      @include grid-base(2);
      width: 100%;
      gap: 5px;
    }

    &__feature {
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      flex: 1 0 0;
      background: $gray10;

      &__title {
        color: $gray40;
        @include a400;
        font-size: 12px;
        line-height: 16px;
      }

      &__count {
        color: $dark-blue;
        @include a500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
      }

      &__description {
        color: $dark-blue;
        @include a400;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .inner-about-content {
    &__content {
      padding: 32px 16px;
    }
  }

  .old-container,
  .new-container {
    height: 2200px;
    position: relative;
    background: linear-gradient(to top, transparent 43%, rgba(101, 154, 170, 0.7) 50%, rgb(101, 154, 170) 100%);

    &:before {
      content: ' ';
      height: 100%;
      width: 100%;
      margin-top: 65px;
      position: absolute;
      background-size: 1860px;
      background-position: -250px;
    }

    &__point-on-map {
      display: none;
    }
  }

  .first-block {
    padding-left: 25px;

    &__text-container {
      width: 100%;
      margin-top: 32px;
      p {
        color: #ffffff;
        @include a400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0;
      }
    }

    &.inner {
      flex-direction: column-reverse !important;
    }
    &__become-member {
      width: 100%;
      margin-top: 32px;
      text-transform: uppercase;
      white-space: nowrap;
      padding: 14px 24px;
      background-color: $red;
      border-radius: 0;
      border: none;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      position: relative;
      @include a500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
}
