.mobileFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.mobileFilter-icon {
  width: 40px;
  height: 40px;
  background-color: #285b7d;
  border-radius: 6px;
  padding: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.mobileFilter-text {
  font-size: 14px;
  font-weight: 500;
  color: $blue;
  margin-top: 7px;
  text-align: center;
  &.active {
    color: $light-blue;
  }
}
.mobileFilter-children {
  position: absolute;
  top: 110%;
  left: 30%;
  // min-width: 195px;
  width: max-content;
  padding: 15px;
  background-color: #fff;
  box-shadow: -2.14769px -2.14769px 4.29538px rgba(0, 0, 0, 0.1), 2.14769px 2.14769px 4.29538px rgba(0, 0, 0, 0.1);
  display: none;
  cursor: default;
  &.open {
    display: block;
  }
  &.right {
    left: unset;
    right: 30%;
  }
  .select__dropdown-item {
    button {
      font-size: 12px;
      padding: 0;
    }
    &.active {
      button {
        font-weight: 500;
      }
    }
  }
}
@media (max-width: 700px) {
  .mobileFilter-icon {
    width: 30px;
    height: 30px;
  }
  .mobileFilter-text {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .mobileFilter-text {
    font-size: 10px;
  }
}
