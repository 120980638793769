.password-strength-bar {
  div {
    margin: 0 !important;
  }

  p {
    margin: 0 !important;
    text-align: right !important;
    font-size: 14px !important;
    color: #2b4761 !important;
  }
}
