.designer-search-list {
  width: calc(100% - 16px);
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 5;
  background-color: #1c2543;
  overflow: hidden;

  scrollbar-width: thin;
  scrollbar-color: #db2947 #1c2543;

  /* для Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    height: 12px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #1c2543;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #db2947;
    border-radius: 5px;
  }

  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 12px;
    fill: #ffffff;
    cursor: pointer;
  }

  &__title {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
  }

  &__main {
    margin-top: 16px;
    height: calc(100% + 20px);
  }
}

@media (max-width: 900px) {
  .designer-search-list {
    padding: 24px 0 24px 16px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1c2543;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &__header {
      padding-right: 16px;
      gap: 16px;
      position: relative;
      flex-wrap: wrap;
    }

    &__title {
      padding: 5px 0;
      width: 90%;
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #ffffff;
      flex-grow: 1;
      text-transform: uppercase;
    }

    &__main {
      margin-top: 16px;
      overflow: hidden;
    }
  }
}
