// ASIDE
.Aaside {
  // flex-shrink: 0;
  // height: 100%;
  grid-area: aside;
  width: 260px;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 1;
}
.Aaside__top {
  height: 50%;
  padding: 20px;
  border-bottom: 1px solid #ebeff2;
  overflow-y: auto;
}
.Aaside__bottom {
  padding: 20px;
  height: 50%;
  overflow-y: auto;
}
.Aaside__menu {
  & + .Aaside__menu {
    margin-top: 45px;
  }
}

.download-analytics {
  margin-left: -45px;

}

.Aaside__list-item {
  font-size: 14px;
  padding-left: 35px;
  position: relative;
  &.active {
    font-weight: 500;
  }
  &.unread {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background-color: $red;
      border-radius: 50%;
    }
  }
  & + .Aaside__list-item {
    margin-top: 20px;
  }
}
.Aaside__list-item__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.Aaside__list-link {
  color: $blue;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 1px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    font-weight: 500;
    color: $red;
    &::before {
      width: 100%;
      background-color: $red;
    }
  }
  &:hover::after {
    width: 100%;
    background-color: $red;
  }
}

@media (max-width: 1024px) {
  .Aaside-mobile {
    padding: 10px 15px 12px;
  }
  .Aaside-mobile__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 0 10px;
    position: relative;
  }
  .Aaside-mobile__title {
    font-size: 15px;
  }
  .Aaside-mobile__head-btn {
    width: 21px;
    height: 15px;
    border: none;
    background: url('../../assets/images/map/menu.svg') center / cover no-repeat;
  }
  .Aaside-nav {
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 1;
    background-color: #fff;
    padding: 11px 11px 14px;
    border: 1px solid #dedede;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .Aaside-nav__item {
    display: block;
    background-color: #fff;
    border: none;
    font-size: 14px;
    color: $blue;
    & + .Aaside-nav__item {
      margin-top: 12px;
    }
  }
}
@media (max-width: 900px) {
  .Aaside {
    max-width: 90%;
    width: 100%;
    margin: 20px auto 10px;
    background-color: #fff;
  }
}
@media (max-width: 768px) {
  .Aaside {
    margin: 10px auto;
  }
  .Aaside-mobile__head {
    margin-bottom: 15px;
  }
}
@media (max-width: 500px) {
  .Aaside-mobile {
    padding: 10px 5px 5px;
  }
  .Aaside-mobile__title {
    font-size: 14px;
  }
  .Aaside {
    max-width: 96%;
  }
}
