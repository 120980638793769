@import 'src/app/styles/vars';
.chat {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-height: calc(100vh - 85px);
  @media (max-width: 1240px) {
    min-height: 100%;
  }
}

.growContainer {
  position: relative;
  flex-grow: 1;
  height: auto;
 
}

.scrollContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0rem;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -3px;
  background: #fff;
  height: 100%;
  padding: 0 25px;
  overflow: auto;
}

.messagesList {
  margin-top: auto;
  display: grid;
  gap: 10px;
  padding: 25px 0;
}

.writeMessage {
  background: white;
  padding: 20px 25px;
  margin-bottom: 30px;
  @media (max-width: $md) {
    border-top: 1px solid $c-gray-20;
    padding: 12px 16px;
    margin-bottom: 0;
  }
}

.writeMessageInner {
  display: flex;
  gap: 25px;
  align-items: end;
}

.withFiles {
  padding-top: 15px;
}

.textarea {
  background: #fff;
  padding: 9px 10px 9px 0px;
  flex-grow: 1;
  outline: none;
  resize: none;
  border: none;
  &::placeholder {
    color: $c-gray-40;
  }
}

.date {
  margin: 0 auto 15px;
}

.files {
  display: flex;
  gap: 10px;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
}
.wrapperFiles {
  height: 35px;
  margin-bottom: 15px;
  position: relative;
}

.files::after {
  content: "";
  display: block;
  background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
}


.closeChat {
  display: flex;
  gap: 20px;
  color: #2B4761;
  font-size: 14px;
  align-items: center;
}