@import 'src/app/styles/vars';
.application {
  padding: 15px;
  color: $c-blue-text;
  min-width: 330px;
  background: $c-gray-10;
  position: relative;
}

.head {
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 13px;
  justify-content: space-between;
}

.footer {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.lastUpdate {
  color: $c-gray-40;
}
