.header {
  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1002;
    transition: background-color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
  }

  &:not(.white) {
    --dropdown-bg-color: #ffffff;
    background-color: var(--dropdown-bg-color);
    border-bottom: 1px solid #e8eaed;
  }

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &-imitation {
    height: 120px;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.header__logo {
  height: 70px;
}

.header__main {
  display: flex;
  align-items: center;
  flex-grow: 1;
  z-index: 1;

  &_container {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &.white {
    border: none;
    --dropdown-bg-color: #2B4761 !important;

    .header__menu-link {
      color: #ffffff !important;
    }
  }
}

.header__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.header__menu {
  display: flex;
  gap: 25px;
  position: relative;
}

.header__menu-item {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  mix-blend-mode: multiply;
}

.header__menu-link {
  position: relative;
  cursor: pointer;
  color: #2b4761;

  &::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    &::before {
      width: 100%;
      background-color: $red;
    }
  }

  &:hover::before {
    width: 100%;
    background-color: $red;
  }
}

.header-mobile__btn {
  display: none;
}

.header-mobile__nav {
  display: none;
}

.header-mobile__item {
  display: flex;
  align-items: center;

  & + .header-mobile__item {
    margin-top: 15px;
  }
}

.header-mobile__item-icon {
  max-width: 18px;
  width: 100%;
  object-fit: contain;
}

.header-mobile__link {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: $blue;
  &.active {
    color: $red;
  }
}

.scroll-top {
  width: 65px;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  background-color: rgba(43, 71, 97, 0.85);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  & svg {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 1123px) {
  .header {
    &-imitation {
      height: 75px;
    }

    &__container {
      padding: 0 15px;
    }

    &__inner {
      padding: 15px 0;
      justify-content: space-between;
    }

    &__logo {
      width: 140px;
      height: 45px;

      img {
        height: 100%;
      }
    }

    &__main {
      flex-grow: 0;

      &_container {
        display: block;
      }

      &.white {
        .header-mobile__btn {
          &__row {
            border-color: #ffffff;
          }
        }
      }

      &.fixed {
        .header-mobile__btn {
          &__row {
            border-color: #2b4761;
          }
        }
      }
    }

    &__nav,
    &__menu-btn,
    &__menu-item {
      display: none;
    }

    &-mobile {
      &__btn {
        margin-left: auto;
        padding: 10px;
        width: 40px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        cursor: pointer;

        &__row {
          width: 20px;
          border-top: 2px solid #2b4761;
        }
      }

      &__nav {
        display: block;

        &__cover {
          display: none;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 100;
          background-color: rgba(0, 0, 0, 0.5);
        }

        &__container {
          width: 100vw;
          height: 100vh;
          max-width: 360px;
          padding: 100px 16px 0;
          position: fixed;
          top: 0;
          right: 0;
          z-index: 100;
          background-color: #fff;
          transform: translateX(100%);
          transition: transform 300ms ease-in-out;

          &::before {
            content: '';
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-color: #f5f8fa;
            clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
          }

          .close-icon {
            width: 24px;
            height: 24px;
            display: block;
            position: absolute;
            top: 24px;
            right: 16px;
            fill: #2b4761;
          }
        }

        &.open &__cover {
          display: block;
        }

        &.open &__container {
          transform: translateX(0);
        }

        &__head {
          padding-bottom: 40px;
          @include cv900;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.12em;
          font-stretch: 670%;
          color: #94a5b4;
        }

        .header__nav,
        .header__menu-item {
          display: block;
        }

        .header__menu {
          padding-bottom: 40px;
          flex-direction: column;
          gap: 20px;

          &-item {
            @include cv900;
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.12em;
            font-stretch: 200%;
            color: #2b4761;
          }

          &-link {
            &:before {
              content: none;
            }

            &.active {
              color: #db2947;
            }
          }
        }
      }
    }
  }
}

.isWhiteLink {
  z-index: 999;
  background: transparent !important;
  border: none !important;
  .header__main_container {
    .header__menu-link {
      color: #fff;
    }
  }
  .header-mobile__btn__row {
    border-top: 2px solid #fff;
  }
}
