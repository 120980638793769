.home-trk {
  padding: 185px 0 120px;
  background-color: #e8eff6;
  clip-path: polygon(0 0, 100% 80px, 100% 100%, 0 100%);

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__title {
    ::after {
      content: '';
      width: 980px;
      height: 135px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #d9e5f0;
      z-index: -1;
      clip-path: polygon(2% 0, 100% 10%, 97% 90%, 0 100%);
    }
  }

  &__btn {
    margin: 60px auto 0;
    padding: 24px 0;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #d9e0e6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #2b4761;
    position: relative;
    transition: background-color 200ms, border-color 200ms;

    & svg path {
      stroke: #2b4761;
    }

    &:hover {
      background-color: #2b4761;
      border-color: #2b4761;
      color: #ffffff;

      & svg path {
        stroke: #ffffff;
      }
    }
  }
}

@media (max-width: 1123px) {
  .home-trk {
    padding: 60px 0 62px;
    clip-path: polygon(0 0, 100% 15px, 100% 100%, 0 100%);

    &__container {
      padding: 0 15px;
    }

    &__title {
      ::after {
        width: 280px;
        height: 95px;
      }

      & .title-section__subtitle {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__btn {
      margin: 26px auto 0;
      padding: 18px 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
