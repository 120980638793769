.tag-entity {
  padding: 2.5px 8px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 5;
  @include cv900;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12em;
  color: #ffffff;
  font-stretch: 270%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: #2b4761;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 2px 90%);
  }

  &.red {
    &::before {
      background-color: #db2947;
    }
  }
}
