.selectLanguage {
  position: relative;
  cursor: pointer;
  margin-right: auto;
  font-size: 19px;
  font-weight: bold;
  svg {
    margin-left: 10px;
  }
  &__list {
    transition: all 0.5s cubic-bezier(0.44, 0.47, 0.14, 1.07);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    z-index: 1;
    left: 0;
    background: #fff;
    width: 125px;
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    &.active {
      border: 1px solid #dedede;
      box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
      height: 101px;
    }
  }
}
.currentLanguage {
  transition: all 0.5s;

  svg {
    path {
      fill: $dark-blue;
    }
  }
  &:hover {
    svg {
      path {
        fill: $dark-blue;
      }
    }
  }
}

.language {
  display: flex;
  align-items: center;
  padding: 3px 0;
  box-sizing: border-box;
  padding: 5px 10px;
  &-flag {
    margin-right: 10px;
  }
  &-name {
    font-size: 14px;
    font-weight: 400;
    color: $blue;
  }
  &:hover {
    background: $light-gray;
  }
}

@media (max-width: 1440px) {
  .selectLanguage {
    font-size: 15px;

    & svg {
      margin-left: 5px;
    }
  }
}
@media (max-width: 1024px) {
  .selectLanguage {
    font-size: 11px;

    & svg {
      margin-left: 2px;
      width: 10px;
      height: 6px;
    }

    &:hover {
      svg {
        path {
          fill: $dark-blue;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .selectLanguage {
    margin-left: auto;
    margin-right: 20px;
    font-size: 14px;
    & svg {
      margin-left: 5px;
      width: 12px;
      height: 8px;
    }
  }
}

@media (max-width: 600px) {
  .selectLanguage {
    margin-right: 13px;
    font-size: 12px;

    & svg {
      margin-left: 2px;
      width: 10px;
      height: 6px;
    }

    &__list {
      left: -15px;
    }
  }
}
