.home-fauna {
  padding: 110px 0 220px;
  background-color: #f7f6ed;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__title {
    position: relative;
    z-index: 1;

    .title-section__subtitle {
      font-size: 18px;
      line-height: 26px;
    }

    &::before {
      content: '';
      display: block;
      width: 835px;
      height: 120px;
      position: absolute;
      top: -24px;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      background-color: #edebde;
      clip-path: polygon(5% 1%, 91% 15%, 93% 90%, 0% 100%);
    }
  }

  &__cards-list {
    display: grid;
    grid-template-areas:
      'A B'
      'C B'
      'C D';
    gap: 40px;
  }

  &__card {
    &:nth-child(1) {
      grid-area: A;
    }

    &:nth-child(2) {
      grid-area: B;
    }

    &:nth-child(3) {
      grid-area: C;
    }

    &:nth-child(4) {
      grid-area: D;
    }

    &__title {
      padding: 0;

      & .title-section__title {
        letter-spacing: 0.05em;
        white-space: normal;
        color: #ffffff;
        font-stretch: 160%;
        font-size: 70px;
        line-height: 64px;
      }
    }

    &__content {
      padding: 36px 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    &__btn {
      @include c900;
      padding: 10px 15px;
      position: relative;
      border: none;
      outline: none !important;
      background-color: transparent;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      font-size: 22px;
      color: #ffffff;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: calc(100% + 5px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: background-color 300ms;
      }

      &::before {
        background-color: #ffffff;
        clip-path: polygon(
          0 3px,
          100% 0,
          100% 2px,
          2px 5px,
          2px calc(100% - 2px),
          calc(100% - 7px) calc(100% - 7px),
          calc(100% - 2px) 2px,
          100% 2px,
          100% 0,
          calc(100% - 5px) calc(100% - 5px),
          0 100%
        );
      }

      &::after {
        background-color: transparent;
        clip-path: polygon(0 3px, 100% 0, calc(100% - 5px) calc(100% - 5px), 0 100%);
      }

      &:hover {
        &::before {
          background-color: transparent;
        }

        &::after {
          background-color: #db2947;
        }
      }
    }
  }
}

@media (max-width: 1123px) {
  .home-fauna {
    padding: 50px 0 100px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 98%);

    &__container {
      padding: 0 15px;
      overflow: hidden;
    }

    .title-section__title {
      white-space: normal;
    }

    &__pretitle {
      padding-bottom: 35px;

      .title-section__title {
        font-size: 18px;
        line-height: 26px;
        white-space: normal;
      }
    }

    &__title {
      position: relative;

      .title-section__subtitle {
        max-width: 100%;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }

      &::before {
        width: 330px;
        height: 110px;
        clip-path: polygon(5% 1%, 91% 15%, 93% 90%, 0% 100%);
      }
    }

    &__cards-list {
      grid-template-areas:
        'A B'
        'C D';
      gap: 15px;
    }

    &__card {
      height: 440px;

      &__background {
        height: 100%;
        object-fit: cover;
      }

      &__title {
        & .title-section__title {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.12em;
          font-stretch: 300%;
        }
      }

      &__content {
        padding: 22px 10px;
        gap: 13px;
      }

      &__btn {
        @include a500;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        letter-spacing: normal;

        &::before {
          opacity: 0.3;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .home-fauna {
    &__cards-list {
      grid-template-areas:
        'A'
        'B'
        'C'
        'D';
      gap: 15px;
    }
  }
}
