.search-container {
  position: relative;
  display: inline-block; /* Важное свойство для правильного позиционирования */
}

.search-icon {
  cursor: pointer;
  font-size: 24px;
}

.search-box {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  max-width: 1200px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}

.search-box.open {
  display: block;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}

.loading-spinner {
  padding: 8px;
  text-align: center;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 30dvh;
  overflow: auto;
}

.search-result-item {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.search-result-item a {
  text-decoration: none;
  color: black;
}

.search-result-item a:hover {
  text-decoration: underline;
}

.search-component {
 margin-right: 24px;
}

@media (max-width: 768px) {
  .search-component {
    float: left;
  }
}
