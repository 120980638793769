.Amain-reviews {
  padding: 20px;
}
.Amain-review {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.06);
  & + .Amain-review {
    margin-top: 20px;
  }
}
.review-header {
  display: grid;
  grid-template-columns: 46px 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  padding: 20px;
  border-bottom: 1px solid #dedede;
  flex-grow: 0;
}
.review-header__user-photo {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $blue;
  color: #ffffff;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}
.review-header__text {
  padding-top: 8px;
  font-size: 14px;
  color: $blue;
  &.bold {
    font-weight: 500;
    color: $dark-blue;
    span {
      color: $light-blue;
      font-weight: 400;
    }
  }
  &.duration,
  &.date-start,
  &.price,
  &.name {
    white-space: nowrap;
  }
  &.name {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.price {
    grid-column-start: 4;
    grid-row-start: 2;
  }
  &.edit {
    grid-column-start: 5;
    grid-row-start: 2;
    span {
      font-weight: 500;
      cursor: pointer;
      color: $light-blue;
      &:hover {
        color: $red;
      }
    }
  }
  &.last-message {
    grid-column-start: 6;
    grid-row-start: 2;
    text-align: right;
  }
}
.Amain-review__rating {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 15px;
}
.Amain-review__body {
  padding: 15px 15px 20px;
  overflow-y: auto;
}
.Amain-review__top {
  display: flex;
  gap: 55px;
}
.Amain-review__subtitle {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  margin-bottom: 10px;
}
.Amain-review__text {
  font-size: 13px;
  color: $blue;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.Amain-review__mostliked-list {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.Amain-review__mostliked-item {
  font-size: 11px;
  font-weight: 500;
  color: $blue;
  padding: 0.6em 0.8em;
  border: 1px solid #dedede;
  border-radius: 4px;
  &.active {
    border-color: $red;
  }
  & + .Amain-review__mostliked-item {
    margin-left: 15px;
  }
}

.Amain-review__edit {
  display: flex;
  align-items: center;
  gap: 25px;
}
.Amain-review__edit-btn {
  font-size: 11px;
  padding: 7px 0;
  min-width: 95px;
  text-align: center;

  &.blue {
    background-color: $blue;
    &:hover,
    &:focus {
      background-color: darken($blue, 7%);
    }
  }
}
.Amain-review__edit-text {
  font-size: 14px;

  a {
    color: $light-blue;
  }
}
.Amain-review__textarea {
  flex-wrap: wrap;
  row-gap: 15px;
  width: 100% !important;
  height: 160px !important;
  background: #eaecef;
  border: none;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 13px;
  color: $blue;
}
form .Amain-review__mostliked-item {
  cursor: pointer;
}

@media (max-width: 1440px) {
  .Amain-review__top {
    gap: 35px;
  }
  .Amain-review__textarea {
    height: 120px !important;
  }
}

@media (max-width: 1280px) {
  .review-header {
    grid-template-columns: 46px 1fr 1fr 145px;
  }
  .review-header__text {
    &.email {
      grid-column-start: 2;
      grid-row-start: 2;
    }
    &.name {
      grid-column-start: 3;
      grid-row-start: 1;
    }
    &.last-message {
      padding-top: 15px;
      grid-column-start: 4;
      grid-row-start: 3;
    }
    &.date-start {
      grid-column-start: 3;
      grid-row-start: 2;
    }
    &.duration {
      padding-top: 15px;
      grid-column-start: 2;
      grid-row-start: 3;
    }
    &.price {
      grid-column-start: 4;
      grid-row-start: 2;
      text-align: right;
    }
    &.edit {
      padding-top: 15px;
      grid-column-start: 3;
      grid-row-start: 3;
    }
  }
}
@media (max-width: 900px) {
  .Amain-reviews {
    padding: 0;
  }
  .Amain-review + .Amain-review {
    margin-top: 10px;
  }
  .Amain-review__top {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .Amain-review__textarea {
    height: 120px !important;
  }
}
@media (max-width: 768px) {
  .review-header {
    grid-template-columns: 63px 1fr 180px;

    .Amain-review__rating {
      grid-column-start: 3;
      grid-row-start: 1;
    }
  }
  .review-header__user-photo {
    width: 52px;
    height: 52px;
  }
  .review-header__text {
    &.login {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
    }
    &.email {
      padding-top: 30px;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
    }
    &.name {
      padding-top: 14px;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
    }
    &.last-message {
      padding-top: 5px;
      grid-column-start: 3;
      grid-row-start: 4;
      text-align: left;
    }
    &.date-start {
      padding-top: 14px;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
    }
    &.duration {
      padding-top: 5px;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
    }
    &.price {
      padding-top: 14px;
      grid-column-start: 3;
      grid-row-start: 3;
      text-align: left;
    }
    &.edit {
      padding-top: 14px;
      grid-column-start: 3;
      grid-row-start: 2;
      text-align: right;
    }
  }

  .Amain-review__rating {
    column-gap: 5px;
  }
  .Amain-review__text {
    margin-bottom: 14px;
    font-size: 14px;
  }
  .Amain-review__edit {
    flex-wrap: wrap;
    row-gap: 15px;
  }
}
@media (max-width: 500px) {
  .review-header {
    padding: 10px;
    grid-template-columns: 40px 1fr 115px;
  }
  .review-header__text {
    &.login {
      padding-top: 5px;
    }
    &.email {
      padding-top: 20px;
    }
    &.name {
      padding-top: 14px;
    }
    &.date-start {
      padding-top: 10px;
    }
    &.duration {
      padding-top: 5px;
    }
    &.price {
      padding-top: 10px;
    }
    &.last-message {
      padding-top: 5px;
    }
  }
  .review-header__user-photo {
    width: 36px;
    height: 36px;
  }
  .review-header__text {
    font-size: 11px;
  }
  .Amain-review__top {
    gap: 5px;
  }
  .Amain-review__textarea {
    height: 90px !important;
    margin-bottom: 10px;
    font-size: 11px;
  }
  .Amain-review__subtitle {
    margin-bottom: 5px;
    font-size: 11px;
  }
  .Amain-review__text {
    margin-bottom: 10px;
    font-size: 11px;
  }
  .Amain-review__edit {
    gap: 20px;
    row-gap: 10px;
  }
  .Amain-review__edit-btn {
    padding: 5px 0;
    min-width: 70px;
    font-size: 10px;
  }
  .Amain-review__edit-text {
    font-size: 11px;
  }
  .Amain-review__mostliked-list {
    margin-bottom: 10px;
  }
  .Amain-review__mostliked-item {
    font-size: 10px;
    padding: 0.4em 0.6em;
    & + .Amain-review__mostliked-item {
      margin-left: 8px;
    }
  }
  .Amain-review__body {
    padding: 10px 10px 15px;
  }
  .Amain-review__rating {
    img {
      width: 15px;
    }
  }
}
