.designer-order-list {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
  }

  &__info {
    width: 100%;

    .order-info {
      padding: 12px 14px;
      display: flex;
      gap: 10px;
      background-color: #2e3652;

      &__icon {
        flex-shrink: 0;
      }

      &__text {
        align-self: center;
        font-size: 14px;
        line-height: 20px;
        color: #d9e0e5;
      }
    }
  }

  &__clear {
    padding: 6px;

    .clear {
      width: 20px;
      height: 20px;
      cursor: pointer;

      &__icon {
        stroke: #c6c8d0;
      }
    }
  }

  &__territory {
    height: calc(100% - 48px);
    padding-right: 12px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #1c2543;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #1c2543;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__territory-title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--white, #fff);
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__drag {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .list-drag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      line-height: 2px;
      color: #c6c8d0;
    }
  }
}

@media (max-width: 900px) {
  .designer-order-list {
    padding-left: 16px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1c2543;
    position: relative;
    z-index: 10;

    &__header {
      margin-bottom: 16px;
      padding-right: 16px;
    }

    &__title {
      padding: 5px 0;
      width: 90%;
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #ffffff;
      flex-grow: 1;
    }

    &__close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3px;
      right: 12px;
      fill: #ffffff;
      cursor: pointer;
    }

    &__territory {
      height: 100%;
      padding-bottom: 85px;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &__item {
      display: flex;
      align-items: center;
      position: relative;
    }

    &__cover {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &__drag {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      .list-drag {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        line-height: 2px;
        color: #c6c8d0;
      }
    }
  }
}
