.chat-automessage {
  width: 100%;
  font-size: 13px;
  color: $blue;

  &__text {
    margin-top: 15px;
  }

  &__buttons {
    margin-top: 30px;
  }

  &__btn {
    padding: 6px 9px;
    width: auto;
    min-width: 95px;
    height: 27px;
    font-size: 12px;
  }
}
