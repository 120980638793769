.designer-manager-box {
  width: 360px;
  height: calc(100% - 40px);
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  background-color: #1c2543;
  &-hidden,
  &.hidden {
    height: auto;
    top: 20px;
    transform: none;
  }

  &__toggle-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    background-color: #1c2543;
    border: none;
    outline: none;
  }

  &__toggle-icon {
    stroke: #ffffff;
    transition: transform 0.2s ease-in-out;

    &.rotate {
      transform: rotate(-180deg);
    }
  }

  &__header {
    padding: 16px;
    display: flex;
    align-items: center;
    position: relative;

    .link-to-home {
      height: 24px;
      width: 24px;
      flex-shrink: 0;
    }

    .filters-wrapper {
      margin-left: 16px;
      padding: 12px 0;
      height: 48px;
      display: flex;
      flex-grow: 1;
      background: #ffffff;

      .filter-locations {
        padding: 0 20px;
        border-left: 1px solid #d9e0e5;
        display: flex;
        align-items: center;
        justify-content: center;

        &__button {
          width: 20px;
          height: 20px;
          background-color: transparent;
          border: none;
          position: relative;
        }

        &__icon {
          width: 100%;
          height: 100%;
          stroke: #94a5b4;
        }

        &__count {
          padding: 3.5px 4px;
          height: 17px;
          min-width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          border: 2px solid #fff;
          background: #db2947;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          font-size: 10px;
          text-align: center;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }

  &__main {
    height: calc(100% - 80px);
    padding: 8px 0 16px 16px;
    overflow: hidden;
    position: relative;

    &.hidden {
      height: 0;
      padding: 0;
      padding-left: 16px;
    }
  }

  &__nav {
    margin: 1px 16px 16px 0;
    padding: 5px;
    display: flex;
    border: 1.3px solid #3e465f;

    button {
      padding: 8px 17px;
      height: 32px;
      flex: 1 0 0;
      background-color: transparent;
      border: none;
      color: #ffffff;
      transition: background-color 0.2s ease-in-out;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      &.active,
      &.active:focus {
        background-color: #db2947;
        border: none;
        outline: none;
        cursor: auto;
      }

      &:focus {
        outline: 1px solid #666666;
      }

      &.list-button {
        display: none;
      }
    }
  }

  &__location {
    padding: 8px 0 16px 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #1c2543;
  }

  &__order-button {
    padding: 12px 0;
    position: absolute;
    bottom: 16px;
    right: 16px;
    left: 16px;
    background-color: #db2947;
    border: none;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .designer-manager-box {
    width: 100%;
    //height: 100%;
    //position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transform: none;
    background-color: transparent;

    .under-layer {
      z-index: -1;
    }

    &.hidden {
      height: 0;
      padding: 0;
      top: 0;
    }

    &__toggle-button {
      display: none;
    }

    &__header {
      margin: 0 16px;
      padding: 5px 5px 5px 16px;
      width: calc(100% - 32px);
      top: 16px;
      left: 0;
      z-index: 10;
      background-color: #1c2543;

      &.hidden {
        display: none;
      }

      .filters-wrapper {
        margin-left: 15px;
        padding: 10px 15px 10px 14px;
        height: 44px;

        .filter-locations {
          padding: 0 0 0 20px;
        }
      }
    }

    &__main {
      padding: 24px 0 0;
      height: 100%;
      width: 100%;
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: #1c2543;

      &.hidden {
        height: 0;
        padding: 0;
        z-index: -1;
      }
    }

    &__nav {
      margin: 0 16px;
      width: calc(100% - 32px);
      position: fixed;
      bottom: 16px;
      left: 0;
      z-index: 10;
      gap: 5px;
      background-color: #1c2543;

      &.hidden {
        display: none;
      }

      button {
        padding: 10px 30px;
        height: 46px;
        flex: 1 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: transparent;
        border: none;
        color: #ffffff;
        transition: background-color 0.2s ease-in-out;

        &.active,
        &.active:focus {
          background-color: inherit;
        }

        &:focus {
          outline: 1px solid #666666;
        }

        &.popular-button {
          display: none;
        }

        &.list-button {
          background-color: #db2947;
          display: flex;

          &.active,
          &.active:focus {
            background-color: #db2947;
            color: #ffffff !important;
          }
        }
      }
    }

    &__locations-list {
      margin-top: 60px;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
    }

    &__locations-list-mobile {
      padding-bottom: 85px;
    }

    &__location {
      padding: 24px 0 24px 16px;
      width: 100%;
      height: 100%;
      z-index: 20;
      overflow: hidden;
    }

    &__order-button {
      padding: 12px 0;
      position: absolute;
      bottom: 16px;
      right: 16px;
      left: 16px;
      background-color: #db2947;
      border: none;
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      cursor: pointer;
    }

    &__back-mobile {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 16px;
      left: 16px;
      background-color: #1c2543;
      z-index: 5;
      border: none;

      svg {
        stroke: #ffffff;
        transform: rotate(180deg);
      }
    }
  }
}
