html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: 1920px;
  font-size: 10px;
  margin: 0 auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  height: 100%;
  font-size: 18px;
  @include a400;
  max-width: 1920px;
  margin: 0 auto;
  color: $dark-blue;
}
a {
  display: inline-block;
  text-decoration: none;
  color: $dark-blue;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
button {
  cursor: pointer;
  &:focus {
    outline: thin dotted;
  }
}
select {
  &:focus {
    outline: thin dotted !important;
  }
}
#root {
  height: 100%;
  overflow-y: clip;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}
.container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 150px;
  box-sizing: content-box;
}

@mixin line-clamp($n: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin base-btn($bg-color: $red) {
  @include a700;
  font-size: 16px;
  padding: 14px 24px;
  background-color: $bg-color;
  border-radius: 4px;
  border: none;
  color: #fff;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  &:hover,
  &:focus {
    background-color: darken($bg-color, 7%);
  }
}

.btn {
  @include base-btn;
}
.subtitle {
  @include a500;
  font-size: 36px;
  color: $blue;
  padding-left: 48px;
  position: relative;
  white-space: nowrap;
  mix-blend-mode: multiply;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 38px;
    height: 38px;
    background: url('../../assets/images/subtitle.svg') center / cover no-repeat;
    transform-origin: center center;
  }
  &.revert {
    padding-left: 0;
    padding-right: 48px;
    &::before {
      left: unset;
      right: 0;
    }
  }
}
.title {
  @include c900;
  font-size: 80px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $red;
  white-space: nowrap;
  margin-left: 20px;
  &.blue {
    color: $blue;
  }
  &.revert {
    margin-left: 0;
  }
}
.top {
  display: flex;
  align-items: center;
  gap: 20px;
  &.revert {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

@keyframes wf0t100 {
  0% {
    width: 0;
    overflow: hidden;
  }
  100% {
    width: 100%;
  }
}
.top-line {
  width: 100%;
  height: 1px;
  background-color: $dark-blue;
}
.desc-title {
  font-size: 36px;
  font-weight: 400;
  span {
    font-weight: 800;
    color: $blue;
  }
}
.text {
  font-size: 24px;
  color: $blue;
}
.ornament {
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 190px;
    height: 190px;
    background: url('../../assets/images/bg.svg') center / cover no-repeat;
    z-index: -1;
    animation: levitation 10s ease-in-out infinite;
  }
  &.ornament_big {
    &::before,
    &::after {
      width: 270px;
      height: 270px;
    }
  }
}

.slick-track:before,
.slick-track:after {
  content: none;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  text-align: center;
  li {
    display: inline-block;
    & + li {
      margin-left: 50px;
    }
    button {
      font-size: 0;
      border: none;
      width: 14px;
      height: 14px;
      background-color: transparent;
      border: 1px solid $dark-blue;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      &:hover {
        @media (min-width: 769px) {
          background-color: $red;
          border-color: $red;
        }
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        opacity: 1;
        height: 2px;
      }
      &:focus {
        outline: none;
      }
    }
    &.slick-active {
      button {
        background-color: $red;
        border-color: $red;
        position: relative;
        &::before,
        &::after {
          opacity: 1;
          top: 50%;
          width: 35px;
          height: 2px;
          background-color: $dark-blue;
          transition: all 0.3s ease-in-out;
        }
        &::before {
          right: calc(100% + 1px);
        }
        &::after {
          left: calc(100% + 1px);
        }
      }
      &:last-child button::after,
      &:first-child button::before {
        display: none;
      }
    }
  }
}

.top-content {
  display: flex;
  width: 0%;
  align-items: center;
  overflow: hidden;
  &.revert {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.subTitle-anim {
  animation: movingSubTitle 3s forwards;
}
.title-anim {
  animation: 2s linear wf0t100 both;
}
@keyframes moving {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moving2 {
  0% {
    transform: translateY(-50%) rotate(0);
  }
  50% {
    transform: translateY(-50%) rotate(180deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes movingSubTitle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes levitation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-45px);
  }
}
@keyframes soaring {
  0%,
  100% {
    transform: translateX(0px) translateY(0px);
  }
  30% {
    transform: translateX(20px) translateY(-25px);
  }
  60% {
    transform: translateX(-10px) translateY(5px);
  }

  80% {
    transform: translateX(10px) translateY(20px);
  }
}
@media (max-width: 1440px) {
  .container {
    /*max-width: 1050px;*/
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: content-box;
  }
  .btn {
    font-size: 14px;
  }
  .title {
    font-size: 65px;
  }
  .top {
    gap: 10px;
  }
  .subtitle {
    font-size: 28px;
    padding-left: 43px;

    &.revert {
      padding-right: 43px;
    }
    &::before {
      width: 33px;
      height: 33px;
    }
  }
  .desc-title {
    font-size: 30px;
  }
  .text {
    font-size: 20px;
  }
  .ornament {
    &::before,
    &::after {
      width: 140px;
      height: 140px;
    }
    &.ornament_big {
      &::before,
      &::after {
        width: 190px;
        height: 190px;
      }
    }
  }
}
@media (max-width: 1280px) {
  .title {
    font-size: 56px;
  }
  .desc-title,
  .subtitle {
    font-size: 24px;
  }
  .text {
    font-size: 17px;
  }
  .btn {
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 800px;
  }
  .top {
    margin-bottom: 20px;
  }
  .title {
    font-size: 48px;
  }
  .desc-title,
  .subtitle {
    font-size: 22px;
  }
  .text {
    font-size: 15px;
  }
  .btn {
    padding: 8px 0;
    width: 105px;
    text-align: center;
    font-size: 11px;
  }
  .slick-dots li button {
    width: 10px;
    height: 10px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    width: 16px;
  }
  .slick-dots li + li {
    margin-left: 34px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    top: 3px;
  }
  .ornament {
    &::before,
    &::after {
      width: 117px;
      height: 117px;
    }
    &.ornament_big {
      &::before,
      &::after {
        width: 140px;
        height: 140px;
      }
    }
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 36px;
  }
  .desc-title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 18px;
    padding-left: 35px;
    &::before {
      width: 25px;
      height: 25px;
    }
  }
  .text {
    font-size: 16px;
  }
  .btn {
    padding: 8px 0;
    width: 105px;
    font-size: 14px;
  }
  .slick-arrow {
    display: none;
  }
  .slick-dots li button {
    width: 10px;
    height: 10px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    width: 16px;
  }
  .slick-dots li + li {
    margin-left: 30px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    top: 3px;
  }
  @keyframes soaring {
    0%,
    100% {
      transform: translateX(0px) translateY(0px);
    }
    30% {
      transform: translateX(10px) translateY(-15px);
    }
    60% {
      transform: translateX(-5px) translateY(5px);
    }

    80% {
      transform: translateX(5px) translateY(10px);
    }
  }
}

@media (max-width: 700px) {
  .container {
    padding: 0 10px;
  }
  .subtitle {
    font-size: 0;
    padding-left: 25px;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 26px;
  }
  .desc-title {
    font-size: 14px;
  }
  .text {
    font-size: 11px;
  }
  .btn {
    padding: 5px 0;
    width: 75px;
    font-size: 10px;
  }
  .slick-dots {
    bottom: -40px;
  }
  .slick-dots li button {
    width: 9px;
    height: 9px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    width: 8px;
  }
  .slick-dots li + li {
    margin-left: 16px;
  }
  .slick-dots li.slick-active button::before,
  .slick-dots li.slick-active button::after {
    height: 1px;
  }
  .ornament {
    &::before,
    &::after {
      width: 90px;
      height: 90px;
    }
    &.ornament_big {
      &::before,
      &::after {
        width: 90px;
        height: 90px;
      }
    }
  }
  @keyframes levitation {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-25px);
    }
  }
}
