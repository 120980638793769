.portal-filters {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #272a2e;
    opacity: 0.5;
  }
  &__wrapper {
    margin: 5px;
    width: calc(100% - 10px);
    max-width: 768px;
    max-height: calc(100vh - 10px);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1003;
  }

  &__header {
    padding: 0 40px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    @include c900;
    border-bottom: 1px solid #e8eaed;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0.12em;
    color: #2b4761;
  }

  &__close {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: transparent;
    background-size: 24px 24px;
    border: none;
    border-radius: 50%;
    transition: background-color 300ms ease-in-out;

    &__icon {
      width: 24px;
      height: 24px;
      fill: #2b4761;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #f5f8fa;
      }
    }
  }

  &__content {
    padding: 30px 40px 45px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #e8eaed;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #e8eaed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 1123px) {
  .portal-filters {
    &__header {
      padding: 0 15px;
      height: 75px;
      display: flex;
      justify-content: flex-start;
      font-size: 20px;
      line-height: 26px;
    }

    &__close {
      width: 24px;
      height: 24px;
    }

    &__content {
      padding: 15px 15px 28px;
    }
  }
}

@media (max-width: 900px) {
  .portal-filters {
    &__wrapper {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__content {
      flex-grow: 1;
    }

    &__inner {
      gap: 25px;
    }
  }
}
