.benefits-tour {
  padding-top: 15px;
  font-size: 18px;
  line-height: 24px;

  &__title {
    font-weight: 500;
  }

  &__list {
    padding-left: 25px;
  }

  &__item {
    list-style: square;
  }
}

@media (max-width: 1123px) {
  .benefits-tour {
    padding-top: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}
