.travels-guide-filters {
  margin-bottom: 22px;
  position: sticky;
  top: calc(100% - 108px);
  z-index: 10;

  &.hidden {
    z-index: -1;
    animation: layer-travels-bottom 0.3s ease-in-out;
  }

  @keyframes layer-travels-bottom {
    0% {
      opacity: 1;
      z-index: 10;
    }

    95% {
      z-index: 10;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }

  &__container {
    z-index: -1;
    padding: 10px 0;
    margin: 0 auto;
    width: 716px;
    background-color: #ffffff;
    box-shadow: 0px 4px 20px -9px rgba(4, 30, 56, 0.1);

    &.fixed {
      opacity: 1;
      animation: show-travels-guide-filters 0.3s ease-in-out;
    }

    &.hidden {
      opacity: 0;
      animation: hide-travels-guide-filters 0.3s ease-in-out;
    }

    @keyframes show-travels-guide-filters {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes hide-travels-guide-filters {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
  }

  &_full &__types-list {
    max-width: 100%;
  }

  &__types-buttons {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__type {
    padding: 12px 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #ffffff;
    color: #2b4761;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:nth-child(odd) {
      clip-path: polygon(0 0, 100% 3px, 100% calc(100% - 3px), 0 100%);
    }

    &:nth-child(even) {
      clip-path: polygon(0 3px, 100% 0, 100% 100%, 0 calc(100% - 3px));
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #d9e0e6;
      }
    }

    &_active {
      background-color: #db2947;
      color: #ffffff;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #db2947;
        }
      }
    }

    &__icon {
      width: 28px;
      height: 28px;
      fill: #db2947;
    }

    &__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1123px) {
  .travels-guide-filters {
    margin: 0;
    height: 60px;
    position: static;
    opacity: 1;

    &.fixed,
    &.hidden {
      animation: none;
    }

    &__container {
      padding: 0;
      width: 100%;
      box-shadow: none;

      &.fixed {
        width: 100%;
        padding: 15px;
        position: fixed;
        top: 76px;
        left: 0;
        bottom: auto;
        z-index: 10;
        box-shadow: 0px 12px 20px -9px rgba(4, 30, 56, 0.1);
        overflow: hidden;
        animation: fix-travels-guide-filters 0.2s ease-in-out;
      }

      &.hidden {
        animation: none;
        opacity: 1;
      }

      @keyframes fix-travels-guide-filters {
        0% {
          height: 0;
          padding: 0px 15px;
        }

        100% {
          height: 90px;
          padding: 15px;
        }
      }
    }

    &__types-list {
      flex-grow: 1;
    }

    &__content {
      gap: 12px;
    }
  }
}
