.opacity-content-card {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 102%;
    height: 105%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: background-color 300ms;
    clip-path: polygon(0% 3%, 100% 1%, 99% 97%, 2% 99%);
  }

  &__background {
    width: 100%;
    display: block;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.76%, rgba(0, 0, 0, 0.8) 100%);
    opacity: 1;
    transition: opacity 300ms;
  }

  @media (hover: hover) and (pointer: fine) {
    &__content {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7) 100%);
      opacity: 0;
    }

    &:hover {
      &::before {
        background-color: #db2947;
      }
    }

    &:hover &__content {
      z-index: 1;
      opacity: 1;
    }
  }
}

@media (max-width: 900px) {
  .opacity-content-card {
    &__content {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.76%, rgba(0, 0, 0, 0.8) 100%);
      opacity: 1;
    }
  }
}
