.admin-field {
  &__helper {
    padding: 4px 14px;
    margin-bottom: 10px;
    width: 100%;
    height: 20px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
  }

  &__error {
    border-top: 2px solid #d32f2f;
    color: #d32f2f;
  }
}

.admin-image {
  max-width: 100%;
  object-fit: cover;
  &__single {
    max-height: 300px;
  }
  &__multi {
    max-height: 200px;
  }
}

.tour-form__title {
  margin-bottom: 12px;
  width: 200px;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}

@media (max-width: 1280px) {
  .Amain {
    .tours__checkbox-label span {
      width: 36px;
      height: 20px;
      &::before {
        width: 5px;
        height: 14px;
      }
    }
    .tours__checkbox-label {
      font-size: 14px;
    }
  }
}

@media (max-width: 500px) {
  .Amain {
    .tour-form__title,
    .tours__checkbox-label {
      font-size: 12px;
    }
    .tours__checkbox-label span {
      width: 30px;
      height: 17px;
      &::before {
        width: 4px;
        height: 11px;
      }
    }
  }
  .tour-form__title {
    width: 150px;
  }
}
