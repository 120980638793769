
@import 'src/app/styles/vars';

.tag {
  display: inline-block;
  line-height: 1;
}

.blueDark {
  background: $c-blue;
  color: white;
}

.red {
  background: $c-red;
  color: white;
}


.gray {
  background: $c-gray-40;
  color: white;
}

.grayLight {
  background: $c-gray-20;
}

.outlined {
  color: $c-gray-40;
  border: 1px solid $c-gray-30;
  background: white;
}

.orange {
  color: white;
  background: $c-orange;
}

.green {
  color: white;
  background: $c-green;
}


.blueLight {
  color: $c-blue-tag;
  background: $c-blue-light-secondary;
}

.m {
  padding: 8px 9px;
}

.s {
  padding: 5px 8px;
}

.main {
  font-family: $font-main;
}

.title {
  font-family: $font-title;
}

.pointer {
  cursor: pointer;
}