.tour-designer-popup {
  margin: 5px;
  padding: 24px 0;
  width: calc(100% - 10px);
  max-width: 440px;
  max-height: calc(100vh - 10px);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(50% - 5px);
  left: calc(50% + 190px);
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1001;

  &__header {
    padding: 0 24px;
    position: relative;
    @include c900;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2.88px;
    color: #2b4761;
  }

  &__close {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;

    &__icon {
      width: 20px;
      height: 20px;
      fill: #2b4761;
    }
  }

  &__content {
    padding: 0 24px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #e8eaed;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #e8eaed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__annotation {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #94a5b4;
  }
}

@media (max-width: 900px) {
  .tour-designer-popup {
    margin: 0;
    padding: 24px 0;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    background-color: #ffffff;

    &__header {
      padding: 0 16px;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 2.4px;
    }

    &__close {
      width: 24px;
      height: 24px;
      right: 16px;
    }

    &__content {
      padding: 0 16px;
    }

    &__inner {
      gap: 12px;
    }

    &__annotation {
      margin-top: 10px;
    }
  }
}
