@import 'src/app/styles/vars';
.button {
  font-size: 12px;
  text-align: center;
}

.primary {
  background: $c-red;
  color: white;
}

.dark {
  background: $c-blue-dark;
  color: white;
}

.stretched {
  width: 100%;
  text-align: center;
}

.secondary {
  background: $c-gray-20;
  color: $c-blue-text;
}

.outlined {
  border: 1px solid $c-gray-30;
  color: $c-blue-text;
}

.xs {
  padding: 5px 15px;
}

.s {
  padding: 10px 15px;
}

.sm {
  padding: 12px;
}

.m {
  padding: 18px 20px;
}

.base {
  font-weight: 400;
  
}

.uppercase {
  font-weight: 500;
  text-transform: uppercase;
}