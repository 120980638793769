.home-partners {
  padding: 0 0 120px;
  background-color: #e8eff6;
  overflow: hidden;

  &__container {
    padding: 0 28px;
    max-width: 1224px;
  }

  &__title {
    padding: 0 15px;
    padding-bottom: 65px;

    .title-section__subtitle {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        width: 100%;
        height: calc(100% + 20px);
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background-color: #d9e5f0;
        z-index: -1;
        clip-path: polygon(
          20px 0,
          98% 0,
          95% calc(100% - 20px),
          70% calc(100% - 25px),
          68% 100%,
          27% calc(100% - 3px),
          28% calc(100% - 25px),
          0 calc(100% - 27px)
        );
      }
    }
  }

  .partner-card__content {
    background-color: #d9e5f0;
  }

  &__slider {
    .slick-track {
      margin: 0;

      .slick-slide {
        padding: 12px;
      }
    }

    .button-slider {
      position: absolute;
      top: 50%;

      &.right {
        right: Max(calc((100% - 100vw + 130px) / 2), -15px);
        transform: translate(100%, -50%);
      }

      &.left {
        left: Max(calc((100% - 100vw + 130px) / 2), -15px);
        transform: translate(-100%, -50%);
      }
    }
  }

  &__btn {
    margin: 45px auto 0;
    padding: 24px 0;
    width: calc(100% - 24px);
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #d9e0e6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #2b4761;
    position: relative;
    transition: background-color 200ms, border-color 200ms;

    & svg path {
      stroke: #2b4761;
    }

    &:hover {
      background-color: #2b4761;
      border-color: #2b4761;
      color: #ffffff;

      & svg path {
        stroke: #ffffff;
      }
    }
  }
}

@media (max-width: 1123px) {
  .home-partners {
    padding: 16px 0 62px;

    &__container {
      padding: 0 3px;
    }

    &__title {
      padding-bottom: 35px;

      .title-section__subtitle {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__btn {
      margin: 10px auto 0;
      padding: 18px 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: 900px) {
  .home-partners {
    &__title {
      .title-section__subtitle {
        padding: 0 5px;
        &::after {
          content: '';
          height: 100%;
          clip-path: polygon(
            0 0,
            98% 0,
            95% calc(100% - 20px),
            80% calc(100% - 25px),
            79% 100%,
            20% calc(100% - 3px),
            22% calc(100% - 25px),
            0 calc(100% - 27px)
          );
        }
      }
    }

    &__slider {
      .slick-arrow {
        display: none;
      }

      .slick-track {
        height: auto !important;
      }
    }
  }
}
