@import 'src/app/styles/vars';
.price {
  font-size: 20px;
  font-weight: 900;
  font-family: $font-title;
  margin-top: 5px;
  margin-bottom: 10px;
}

.group {
  border-right: 1px solid $c-gray-20;
  &:last-child {
    border: none;
  }
}

.groups {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.groupDd {
  color: $c-gray-40;
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 2px;
}

.groupDt {
  font-weight: 500;
  color: $c-blue-text;
}

.text {
  color: $c-gray-40;
}