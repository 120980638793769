.privileges-content {
  overflow: hidden;
  flex: 1 0 auto;
  position: relative;
  padding-bottom: 60px;


  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #f5f8fa;
    clip-path: polygon(0 84px, 100% 0, 100% 100%, 0 90%);
  }

  &__first{
    margin-top: 118px;
  }

  &__second{
    margin-top: 65px;
  }

  &__title {
    color: $dark-blue;
    @include cv1000;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 7.68px;
    font-stretch: 300%;
  }

  &__desc {
    @include a400;
    color: $gray40;
    font-size: 22px;
    line-height: 36px;
    padding-bottom: 36px;
    padding-top: 8px;
  }

  &__grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
  }

  &__footer {
    @include a400;
    margin-bottom: 204px;
    margin-top: 32px;
    font-size: 22px;
    line-height: 36px;
    color: $gray40;

    &__description {
      font-size: 22px;
      display: inline-block;
      a {
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.privileges-content-sm{
  overflow: hidden;
}

.break-line {

}

@media (max-width: 900px) {
  .privileges-content{
    &__title {
      color: $dark-blue;
      @include cv1000;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 2.88px;
      font-style: normal;
      font-stretch: 300%;
    }

    &__desc {
      color: $gray40;
      @include a400;
      font-size: 16px;
      line-height: 24px;
      padding-top: 16px;
    }

    &__footer {
      &__description {
        font-size: 18px;
      }
    }
  }

}