.popupInfo-title {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  &.--top {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dedede;
  }
}

@media (max-width: 500px) {
  .popupInfo-title {
    font-size: 12px;
    &.--top {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
}
