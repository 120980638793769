@import "src/app/styles/vars";
.wrapper {
  display: flex;
  gap: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mapIcon {
  flex-shrink: 0;
  width: 55px;
}

.requests {
  margin-bottom: 12px;
}

.right {
  max-width: 350px;
}

.text {
  color: $c-gray-40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 20px;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  a {
    text-align: center !important;
  }
}