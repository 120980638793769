.partners-filters {
  height: 60px;

  &_full &__types-list {
    max-width: 100%;
  }

  &__types-list {
    &.fixed {
      width: 100%;
      padding: 24px 40px;
      position: fixed;
      top: 121px;
      left: 0;
      z-index: 100;
      background-color: #ffffff;
      box-shadow: 0px 12px 20px -9px rgba(4, 30, 56, 0.1);
      overflow: hidden;
      animation: fix-partners-filters 0.2s ease-in-out;

      .partners-filters__types-slider {
        margin: 0 auto;
        max-width: 1200px;
      }
    }
  }

  @keyframes fix-partners-filters {
    0% {
      height: 0;
      padding: 0px 40px;
    }

    100% {
      height: 108px;
      padding: 24px 40px;
    }
  }

  &__types-slider {
    display: flex;
    align-items: center;
    gap: 28px;
  }

  &__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid transparent;
    transition: border-color 300ms ease-in-out;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: #d9e0e6;
      }
    }

    &_active {
      border-color: #db2947;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #db2947;
        }
      }
    }

    &__icon {
      width: 28px;
      height: 28px;
      fill: #db2947;
    }

    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #2b4761;
    }
  }
}

@media (max-width: 1123px) {
  .partners-filters {
    &__types-list {
      &.fixed {
        padding: 15px;
        top: 76px;

        .partners-filters__types-slider {
          margin: 0 auto;
          max-width: 1200px;
        }
      }
    }

    @keyframes fix-partners-filters {
      0% {
        height: 0;
        padding: 0px 15px;
      }

      100% {
        height: 90px;
        padding: 15px;
      }
    }
  }
}