.account {
  height: 100vh;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header header'
    'aside main';
}

@media (max-width: 1280px) {
  .account {
    grid-template-rows: 50px 1fr;
  }
}

@media (max-width: 900px) {
  .account {
    background-color: #eaecef;
    grid-template-columns: 1fr;
    grid-template-rows: 170px 1fr;
    grid-template-areas:
      'aside'
      'main';
    height: 100vh;
    padding-bottom: 103px;
  }
}
@media (max-width: 768px) {
  .account {
    grid-template-rows: 140px 1fr;
  }
}
@media (max-width: 500px) {
  .account {
    height: 100vh;
    padding-bottom: 68px;
    grid-template-rows: 120px 1fr;
  }
}
