.travel-guide-item-video {
  padding: 125px 0 60px;
  background-color: #0f0f0f;

  &__title {
    padding-bottom: 80px;

    .title-section__title {
      color: #ffffff;
      z-index: 1;

      &::after {
        content: '';
        width: 520px;
        height: 105px;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #202020;
        z-index: -1;
        clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 calc(100% - 5px));
      }
    }
  }

  &__before-rectangle {
    padding-top: 60px;
    height: 95px;
    width: 100%;
    background-color: #fff;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 45px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: #0f0f0f;
      clip-path: polygon(0 calc(100% + 1px), 100% 0, 100% calc(100% + 1px));
    }
  }

  &__after-rectangle {
    height: 85px;
    width: 100%;
    background-color: #0f0f0f;
    clip-path: polygon(0 -1px, 100% -1px, 0 100%);
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-video {
    padding: 50px 0 30px;

    &__title {
      padding-bottom: 40px;

      .title-section__title {
        &::after {
          width: 155px;
          height: 70px;
          clip-path: polygon(0px 5px, 100% 0, calc(100% - 6px) 100%, 3px calc(100% - 5px));
        }
      }
    }

    &__before-rectangle {
      padding-top: 50px;
      height: 90px;

      &::after {
        height: 40px;
      }
    }

    &__after-rectangle {
      height: 30px;
    }
  }
}
