.search-locations {
  height: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__form {
    width: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &__input {
    padding: 0 14px;
    flex-grow: 1;
    color: #2b4761;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border: none;
  }

  &__submit {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;

    svg {
      stroke: #db2947;
    }

    &[disabled] {
      svg {
        stroke: #94a5b4;
      }
    }
  }

  &__result {
    padding: 10px;
    width: 288px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    left: 0;
    bottom: -22px;
    transform: translateY(100%);
    z-index: 20;
    background-color: #ffffff;
    font-size: 14px;
    color: #2b4761;
    line-height: 20px;
    box-shadow: 0px 5px 16px 0px rgba(28, 37, 67, 0.3);
  }

  &__card {
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #db2947;
        color: #ffffff;
      }
    }
  }

  &__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }

  &__nothing {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .search-locations {
    height: 100%;
    position: relative;
    flex-grow: 1;

    &__header {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      position: relative;
      flex-wrap: wrap;
    }

    &__form {
      margin-right: 0;
      width: 180px;

      &_inner {
        flex-grow: 1;
      }
    }

    &__input {
      padding: 0 14px 0 0;

      &_inner {
        padding: 10px 14px;
        width: 100%;
      }
    }

    &__submit {
      display: none;
    }

    &__close {
      width: 20px;
      height: 20px;
      fill: #ffffff;
    }

    &__result {
      padding: 24px 16px;
      width: 100%;
      height: 100%;
      gap: 11px;
      position: fixed;
      left: 0;
      top: 0;
      bottom: auto;
      transform: none;
      z-index: 10;
      background-color: #1c2543;
      color: #ffffff;
      box-shadow: none;
    }

    &__card {
      padding: 5px 0;
      display: flex;
      align-items: center;
      background-color: #1c2543;
      transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    }

    &__nothing {
      text-align: center;
    }

    &__button {
      margin: 0 16px;
      padding: 20px 0;
      width: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      text-transform: uppercase;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.56px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #db2947;
      position: fixed;
      left: 0;
      bottom: 24px;

      svg {
        stroke: #ffffff;
      }
    }
  }
}
