@mixin grid-base($cols: 2) {
  display: grid;
  grid-template-columns: repeat($cols, minmax(0, 1fr));
  gap: 0.5rem;
}

.line-sep {
  max-width: 100%;
  height: 2px;
  background: #172b4a !important;

  z-index: 1;

  &__top{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &__bottom{
    margin-top: 12px;
  }
}



.metrics-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  &__become-member {
    @include base-become-member-btn;
    width: 100%;
    padding: 28px 24px 24px 28px;
    font-size: 16px;
    text-align: center;
    @include a500;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.64px;
  }

  &__chart {
    width: 100%;
    background-image: url('../../../../assets/images/trk/prog-bar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    &__container {
      @include grid-base(3);
      padding-left: 40px;
      padding-right: 40px;

      &__item {
        height: 340px;
        width: 100%;
        flex-shrink: 0;
        position: relative;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        button {
          @include cv1000;
          text-align: center;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 2.64px;
          width: 100%;
          bottom: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background: #091a33;
          color: $gray40;
          padding: 8px 46px;
          &:hover,
          &:focus {
            outline: none;
            background: $red;
          }
        }
      }
    }
  }

  &__contact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @include a400;

    p {
      color: $gray40;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    span,
    a {
      color: $red;
      font-size: 18px;
      line-height: 24px;
      border: none;
      outline: none;
    }
  }

  &__first {
    display: flex;
    max-width: 500px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: #0B2040;
    position: relative;
    padding-bottom: 28px;

    color: #FFFFFF;

    &__header {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 36px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__footer {
      padding-left: 40px;
      margin-top: 30px;
    }

    &__count {
      color: #ffffff;
      @include cv900;
      font-size: 47px;
      line-height: 58px;
      letter-spacing: 5.64px;
      white-space: nowrap;
      font-stretch: 300%;
    }



    &__label {
      color: $gray40;
      @include a400;
      font-size: 22px;
      line-height: 36px;
      white-space: nowrap;
    }

    &__note {
      display: flex;
      flex-direction: column;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;

      p {
        @include a500;
        color: $gray40;
        font-size: 12px;
        line-height: 16px;
        padding-top: 10px;
        letter-spacing: 0;
      }

      &__line {
        clear: both;
        height: 0;
        padding: 0;
        border: 0;
        line-height: 1;
      }
      hr:after {
        content: '  ';
        letter-spacing: 1em;
        color: #172b4a !important;
      }
    }
  }

  &__grid {
    @include grid-base(2);
    grid-gap: 10px;

    &__item {
      display: flex;
      width: 100%;
      height: 188px;
      padding: 24px 32px 32px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      border: 2px solid $gray30;
      background: #fff;
      overflow: hidden;
      position: relative;

      &__icon {
        position: absolute;
        right: 32px;
        top: 32px;
        display: none;
      }

      h4 {
        color: #2b4761;
        @include c900;
        font-size: 47px;
        line-height: 58px;
        letter-spacing: 5.64px;
      }

      span {
        color: $gray40;
        @include a400;
      }

      @media (hover: hover) and (pointer: fine){
        svg {
          fill: #ffffff;
          display: block;
        }
      }

      &:hover {
        background: #db2947;
        border: none;
        cursor: pointer;

        h4,
        span {
          color: #ffffff;
        }


      }
    }
  }

  &__title {
    @include base-title();
    padding-bottom: 40px;
  }
}

.participants-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 60px;
  padding-top: 60px;

  &__list {
    width: 100%;
    &__row {
      border-top-width: 2px;
      border-bottom: 1.5px solid $gray30;
      color: $dark-blue;
      width: 100%;
      cursor: pointer;
      display: flex;
      padding: 32px 0;
      align-items: center;
      font-size: 22px;
      line-height: 36px;
      @include a500;

      &__icon{
        width: 30px;
        height: 30px;
        margin-right: 16px;
      }

      svg {
        color: $dark-blue;
      }

      &:hover {
        color: $red;
        svg {
          fill: $red;
        }
      }

      a:hover, li:hover{
        outline: none;
        border: none;
      }
    }
  }

  &__title{
    color: $dark-blue;
    @include cv1000;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 7.68px;
    font-stretch: 300%;
  }
}

@mixin base-indicator {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.first-year {
  &__indicator {
    bottom: 120px;
    @include base-indicator;
  }
}

.second-year {
  &__indicator {
    bottom: 155px;
    @include base-indicator;
  }
}

.third-year {
  &__indicator {
    @include base-indicator;
    bottom: 190px;
  }
}

.active-year {
  transition: all 0.3s ease-in-out;
  background: linear-gradient(183deg, rgba(11, 32, 64, 0) 23%, rgba(255, 255, 255, 0.2) 200%);

  button {
    background: $red !important;
    color: #FFFFFF;

  }
}

.not-active-year {
  svg {
    display: none;
  }
}

@media (max-width: 900px) {
  .first-year {
    &__indicator {
      bottom: 78px;
      padding-right: 13px;
    }
  }

  .second-year {
    &__indicator {
      bottom: 107px;
      padding-right: 13px;
    }
  }

  .third-year {
    &__indicator {
      bottom: 123px;
      padding-right: 13px;
    }
  }

  .metrics-content {
    flex-direction: column;
    overflow: hidden !important;
    box-sizing: border-box;

    .metrics-content {

      &__become-member {
        @include base-become-member-btn;
        width: 100%;
        text-align: center;
        @include a500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
      }
    }

    &__chart {
      width: 100%;
      background-image: url('../../../../assets/images/trk/prog-bar-mobile.svg');
      margin-top: -20px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      &__container {
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 32px;
        &__item {
          width: 100%;
          height: 226px;

          button {
            padding: 10px 23px;
            font-size: 18px;
            font-stretch: 250%;
          }
        }
      }
    }

    &__first {
      max-width: 100%;
      height: 449px;

      &__note{
        padding-left: 18px;
        padding-right: 18px;
        p{
          color: $gray40;
          @include a400;
          font-size: 12px;
          line-height: 16px;
          padding-top: 19px;
        }
      }

      &__header {
        padding-top: 26px;
        padding-left: 24px;
        padding-right: 24px;
      }

      &__count{
        @include cv1000;
        font-weight: 1000;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 2.88px;
        font-stretch: 400%;
      }

      &__label {
        font-size: 16px;
        line-height: 24px;
        color: #94A5B4;
        @include a400;
        font-weight: 400;
        letter-spacing: 1px;
        padding-bottom: 5px;
      }
    }

    &__title {
      padding-bottom: 20px;
      color:  #253E54;
      @include cv1000;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 2.88px;
      font-stretch: 500%;
    }
    &__grid {
      &__item {
        padding: 16px;
        height: 118px;

        @media (hover: hover) and (pointer: fine){
          svg {
            display: none;
          }
        }

        h4 {
          font-size: 22px;
          line-height: 34px;
        }

        p {
          font-size: 14px;
        }

      }
    }
  }

  .participants-infos {
    padding-bottom: 32px;

    &__title{
      color: #253E54;
      @include cv1000;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 2.88px;
      padding-bottom: 32px;
    }

    &__list {
      margin-top: -30px;
      &__row {
        padding: 20px 0;
        font-size: 16px;
      }
    }
  }

  .active-year {
    background-image: linear-gradient(180deg, rgba(11, 32, 64, 0.00) 0%, rgba(255, 255, 255, 0.20) 169%);

    svg {
      left: 15px;
    }
  }
}
