.travel-guide-item-facts {
  padding: 60px 0 205px;
  background-color: #1c2543;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 85px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    background-color: #ffffff;
  }

  &__title {
    padding-bottom: 40px;
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
    color: #ffffff;
  }

  &__slider {
    .slick-list {
      overflow: unset;
    }

    .slick-track {
      margin: 0;
      display: flex;
      gap: 40px;

      .slick-slide {
        width: Min(580px, calc(50vw - 80px));
        flex-shrink: 0;
      }
    }
  }

  &__group {
    cursor: pointer;
  }

  &__image {
    position: relative;

    & img {
      width: 100%;
      height: 540px;
      object-fit: cover;
    }

    &::before {
      content: '';
      display: block;
      width: 102%;
      height: 105%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: background-color 300ms;
      clip-path: polygon(0% 0%, 100% 3%, 100% 99%, 0% 97%);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &__group:hover {
      .travel-guide-item-facts {
        &__image {
          &::before {
            background-color: #db2947;
          }
        }
        &__description {
          opacity: 1;
        }
      }
    }
  }

  &__description {
    padding-top: 30px;
    font-size: 18px;
    line-height: 24px;
    opacity: 0.5;
    color: #ffffff;
    transition: opacity 0.3s ease-in-out;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-facts {
    padding: 30px 0 75px;

    &::before {
      height: 40px;
    }

    &__title {
      padding-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;
    }

    &__slider {
      .slick-track {
        gap: 15px;

        .slick-slide {
          width: calc(50vw - 57.5px);
        }
      }
    }

    &__image {
      & img {
        height: 300px;
      }
    }

    &__description {
      padding-top: 12px;
      font-size: 16px;
      opacity: 1;
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-facts {
    &__slider {
      .slick-track {
        .slick-slide {
          width: calc(100vw - 60px);
        }
      }
    }
  }
}
