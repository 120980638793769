.yvideo-app {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  background-color: #000;
  cursor: pointer;

  &__media,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &__media {
    object-fit: cover;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    padding: 0;
    width: 107px;
    height: 135px;
    border: none;
    background-color: transparent;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 1123px) {
  .yvideo-app {
    &__button {
      width: 40px;
      height: 50px;

      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
