.travel-guide-item-similar {
  padding: 60px 0 0;

  &__list {
    min-height: 0;
    min-width: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    gap: 40px;

    .travel-card {
      height: 300px;
    }

    .travel-card:nth-child(3) {
      height: 640px;
      grid-column: 2;
      grid-row: 1 / span 2;
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-similar {
    padding: 30px 0 0;

    &__title {
      padding-bottom: 30px;
    }

    &__list {
      gap: 16px;

      .travel-card:nth-child(3) {
        height: 616px;
      }
    }
  }
}

@media (max-width: 850px) {
  .travel-guide-item-similar {
    &__list {
      grid-template-columns: repeat(1, 1fr);

      .travel-card {
        height: 440px;
      }

      .travel-card:nth-child(3) {
        height: 440px;
        grid-column: 1;
        grid-row: 3 / span 1;
      }
    }
  }
}
