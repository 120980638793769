
@import 'src/app/styles/vars';
.avatar {
  border-radius: 50%;
  flex-shrink: 0;
  font-family: $font-title;
  overflow: hidden;
  font-weight: bold;
}

.avatarSize22 {
  width: 22px;
  height: 22px;

  font-size: 10px;
  // Ваши другие стили
}

.avatarSize40 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  // Ваши другие стили
}

.avatarSize90 {
  width: 90px;
  height: 90px;
  font-size: 28px;
  // Ваши другие стили
}

.avatarDark {
  background-color: $c-gray-40;
  color: #fff;
}

.avatarActive {
  background-color: $c-red;
  color: #fff;
}

.avatarLight {
  border: 1px solid $c-gray-20;
  background-color: $c-gray-10;
  color: $c-gray-40;
}

.spanClass {
  line-height: 1;
  position: relative;
  top: -2px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}