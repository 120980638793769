.button-slider {
  width: 48px;
  height: 48px;
  cursor: auto;
  z-index: 1;

  &.slick-arrow {
    top: auto;
    left: auto;
  }

  &__dark,
  &__light {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      transition: background-color 200ms;
      z-index: -1;
    }

    & svg path {
      transition: stroke 200ms;
    }
  }

  &__dark {
    background-color: #2b4761;

    &::before {
      background-color: #2b4761;
    }

    & svg path {
      stroke: #ffffff;
    }
  }

  &__light {
    background-color: #2b4761;

    &::before {
      background-color: #ffffff;
    }

    & svg path {
      stroke: #2b4761;
    }
  }

  &__right {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);

    &::before {
      clip-path: polygon(0px 10%, 100% 0, 100% 100%, 0 90%);
    }
  }

  &__up {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);

    &::before {
      clip-path: polygon(0px 10%, 100% 0, 100% 100%, 0 90%);
    }

    svg {
      transform: rotate(-90deg);
    }
  }

  &__down {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);

    &::before {
      clip-path: polygon(0px 10%, 100% 0, 100% 100%, 0 90%);
    }

    svg {
      transform: rotate(90deg);
    }
  }


  &__left {
    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);

    &::before {
      clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &:hover &__dark svg path {
    stroke: #ffffff;
  }

  &:hover &__light svg path {
    stroke: #2b4761;
  }

  &:not(.slick-disabled) &__dark:hover {
    cursor: pointer;
    &::before {
      background-color: #ffffff;
    }

    & svg path {
      stroke: #2b4761;
    }
  }

  &:not(.slick-disabled) &__light:hover {
    cursor: pointer;

    &::before {
      background-color: #2b4761;
    }

    svg path {
      stroke: #ffffff;
    }
  }

  &.slick-disabled {
    opacity: 1;
  }

  &.slick-disabled &__light,
  &.slick-disabled &__light:hover,
  &.slick-disabled &__dark,
  &.slick-disabled &__dark:hover {
    background-color: #d9e0e6;
    cursor: auto;

    &::before {
      background-color: #ffffff;
    }

    & svg path {
      stroke: #d9e0e6;
    }
  }
}
