.travel-guide-item-digits {
  padding: 60px 0 60px;
  overflow: hidden;
  position: relative;
  background-color: #f2eee8;

  &__title {
    padding-bottom: 60px;
  }

  &__image {
    padding-right: 60px;
    width: 67%;
    position: relative;
    float: left;

    & img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    color: #2b4761;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-digits {
    padding: 30px 0 30px;

    &__title {
      padding-bottom: 25px;
    }

    &__image {
      padding-right: 16px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-digits {
    &__image {
      padding-right: 0px;
      width: 100%;
      height: 280px;
      float: unset;

      & img {
        width: 100%;
        height: 100%;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 35px;
        position: absolute;
        left: 0;
        bottom: 0;
        clip-path: polygon(0 100%, 100% 0, 100% 100%);
        background-color: #f2eee8;
      }
    }

    &__description {
      padding-top: 24px;
    }
  }
}
