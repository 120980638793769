.tour-application {
  &.ready {
    left: 50%;
  }

  &__login {
    font-size: 16px;
    line-height: 24px;
    color: #94a5b4;

    a {
      font-weight: 500;
      color: #db2947;
    }
  }

  &__agree {
    padding-top: 24px;
    font-size: 12px;
    line-height: 16px;
    color: #94a5b4;

    a {
      display: inline;
      font-weight: 500;
      color: #94a5b4;
    }
  }
}

@media (max-width: 900px) {
  .tour-application {
    &.ready {
      left: 0;
    }

    &__login {
      font-size: 16px;
      line-height: 24px;
    }

    &__agree {
      text-align: center;
    }
  }
}
