@import 'src/app/styles/vars';

.active {
  color: $c-red !important;
}


.link {
  font-size: 13px;
  padding: 12px 0 12px 12px;
  color: white;
  border-left: 4px solid $c-blue-dark;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: $md) {
    color: $c-gray-40;
    flex-direction: column;
    border-left: 0;
    gap: 2px;
    text-transform: uppercase;
    font-size: 8px;
    flex-shrink: 1;
    width: auto;
    padding: 0 5px;
  }
}