h1,
.h1 {
  font-family: "CoFoPeshkaV0.6", sans-serif;
  font-size: 2.4rem;

  leading-trim: both;
  text-edge: cap;
  text-transform: uppercase;
  letter-spacing: 0.28rem;
  font-weight: 900;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 162.5%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
}
