@import 'src/app/styles/vars';
.container {
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: $md) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.noMobile {
    @media (max-width: $md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}