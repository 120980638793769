@import 'src/app/styles/vars';
.application {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 350px);
  gap: 15px;
  flex-grow: 1;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    
  }
  @media (max-width: $md) {
    background: white;
    gap: 0;
  }
}