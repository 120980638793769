.hotnews {
  padding: 60px 0 0;
}
.hotnews__top {
  margin-bottom: 55px;
}
.hotnews__slider {
  & .slick-arrow {
    &:hover svg path {
      fill: #33404f;
      stroke: #33404f;
    }
  }
  & .slick-dots {
    bottom: 0;
    left: auto;
    right: 0;
    width: 40%;
  }
}
.hotnews__inner {
  &::before {
    top: 0;
  }
  &::after {
    left: 50%;
    bottom: -15%;
  }
}
.hotnews__content {
  width: 100%;
}
.hotnews__img {
  margin-left: 20px;
  padding-bottom: 65px;
  max-width: 40%;
  width: 100%;
  float: right;
  text-align: center;
  font-size: 0;
  img {
    margin: 0 auto;
    width: 100%;
    height: 440px;
    object-fit: cover;
    display: inline;
  }
}
.hotnews__desc-title {
  margin-bottom: 25px;
}
.hotnews__text {
  & + .hotnews__text {
    margin-top: 30px;
  }
}
.news__footer {
  display: flex;
  align-items: center;
  margin-top: 60px;
  .news__views {
    display: flex;
    align-items: center;
    color: $dark-blue;
    font-size: 24px;
    margin-left: 80px;
    &-counter {
      margin-left: 25px;
      padding: 5px 27px;
      background: $light-gray;
      border: 1px solid rgba(40, 91, 125, 0.2);
      border-radius: 4px;
      font-weight: bold;
      opacity: 0.6;
      color: #33404f;
    }
  }
  &_mobile {
    display: none;
  }
}
@media (max-width: 1440px) {
  .hotnews {
    padding: 50px 0 0;
  }
  .hotnews__img {
    padding-bottom: 45px;
    img {
      height: 390px;
    }
  }
  .news__footer {
    margin-top: 55px;
    .news__views {
      margin-left: 60px;
      font-size: 20px;
    }
    .news__views-counter {
      margin-left: 20px;
      padding: 5px 20px;
    }
  }
}
@media (max-width: 1280px) {
  .hotnews__btn {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .hotnews {
    padding: 30px 0 0;
  }
  .hotnews__desc-title {
    margin-bottom: 15px;
  }
  .hotnews__img {
    padding-bottom: 35px;
    img {
      height: 290px;
    }
  }
  .news__footer {
    margin-top: 40px;
    .news__views {
      margin-left: 50px;
      font-size: 15px;
    }
    .news__views-counter {
      margin-left: 15px;
      padding: 5px 15px;
    }
  }
}
@media (max-width: 768px) {
  .hotnews {
    padding: 20px 0 28px;
  }
  .hotnews__inner {
    &::before {
      left: 50%;
    }
    &::after {
      left: 88%;
      bottom: -100px;
    }
  }
  .hotnews__slider {
    padding: 10px 16px 16px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    & .slick-dots {
      width: 100%;
      bottom: -28px;
      left: auto;
      right: 0;
    }
  }
  .hotnews__desc-title {
    margin-bottom: 15px;
  }
  .hotnews__img {
    margin-left: 15px;
    padding: 10px 0;
    img {
      height: 150px;
    }
  }
  .news__footer {
    display: none;
    margin-top: 18px;
    .news__views {
      margin-left: 0;
      font-size: 13px;
    }
    .news__views-counter {
      margin-left: 0;
      padding: 6px 10px;
    }
    &_mobile {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
@media (max-width: 500px) {
  .hotnews {
    padding: 10px 0 20px;
  }
  .hotnews__inner {
    &::after {
      left: 50%;
      bottom: -80px;
    }
  }
  .hotnews__slider {
    padding: 10px 16px 16px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    & .slick-dots {
      bottom: -20px;
    }
  }
  .hotnews__desc-title {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .hotnews__text {
    font-size: 11px;
  }
  .hotnews__img {
    margin-left: 7px;
    padding: 10px 0 5px;
    min-width: 115px;
    img {
      height: 90px;
    }
  }
  .news__footer {
    margin-top: 13px;
    .news__views {
      margin-left: 0;
      font-size: 10px;
    }
    .news__views-counter {
      margin-left: 0;
      padding: 4px 7px;
    }
    &_mobile {
      justify-content: space-between;
    }
  }
}
