.newslist {
  padding: 70px 0 90px;
}
.newslist__top {
  margin-bottom: 50px;
  &::before {
    top: -80px;
    left: -50px;
  }
}
.newslist__box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 50px;
}
@media (max-width: 1440px) {
  .newslist {
    padding: 65px 0 90px;
  }
  .newslist__box {
    row-gap: 45px;
  }
}
@media (max-width: 1024px) {
  .newslist {
    padding: 50px 0 70px;
  }
  .newslist__box {
    row-gap: 35px;
  }
}
@media (max-width: 768px) {
  .newslist {
    padding: 40px 0 60px;
  }
  .newslist__box {
    row-gap: 30px;
  }
}
@media (max-width: 500px) {
  .newslist {
    padding: 25px 0 30px;
  }
  .newslist__box {
    row-gap: 12px;
  }
}
