.travel-guide-item {
  padding: 0 0 120px;
  color: #2b4761;

  &__container {
    max-width: 1200px;
    padding: 0 40px;
  }
}
.travel-guide-share {
  color: #2b4761;
  padding-bottom: 34px;

  &.city {
    background-color: #161d37;

    .shareBlog__title {
      color: #8a8e9b;
    }
  }

  &.flora {
    background-color: #1c5f3a;

    .shareBlog__title {
      color: #8daf9c;
    }
  }

  .shareBlog {
    border: none;
  }
  .lineHidden,
  .lineBottom {
    display: none;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item {
    padding: 0 0 60px;

    &__container {
      padding: 0 15px;
    }
  }
}
