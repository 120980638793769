.home-faq {
  padding-bottom: 120px;
  background-color: #e8eff6;

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__title {
    padding-bottom: 25px;
  }

  &__answer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 1123px) {
  .home-faq {
    padding-bottom: 60px;

    &__container {
      padding: 0 15px;
    }

    &__title {
      padding-bottom: 5px;
    }
  }
}
