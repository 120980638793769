.tour-item {
  padding: 50px 0 120px;
  color: #2b4761;

  &__container {
    max-width: 1200px;
    padding: 0 40px;
  }

  &__header-wrapper {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1280px;
    display: grid;
    grid-template-areas:
      'header operator'
      'header params';
    grid-template-columns: 1fr 290px;
    column-gap: 40px;
  }

  &__header {
    margin: 0 auto;
    padding: 40px 25px;
    width: 100%;
    max-width: 870px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    position: relative;
    grid-area: header;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;

    &::before {
      content: '';
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;
      clip-path: polygon(0 100%, 100% 93%, 100% 100%);
    }
  }

  &__back {
    padding: 8px 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 25px;
    left: 25px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;

    &__icon {
      width: 16px;
      height: 16px;
      transform: rotate(180deg);

      path {
        stroke: #2b4761;
      }
    }
  }

  &__type {
    padding: 6px 12px;
    position: relative;
    z-index: 1;
    @include cv900;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.12em;
    color: #ffffff;
    font-stretch: 270%;

    &::before {
      content: '';
      display: block;
      width: 90px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
      transform: translateY(-50%);
      background-color: #2b4761;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 3% 90%);
    }
  }

  &__title {
    padding-bottom: 15px;
    @include cv900;
    font-size: 47px;
    line-height: 58px;
    letter-spacing: 0.12em;
    font-stretch: 270%;

    span {
      padding: 4px 25px 16px;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
      background-color: #ffffff;
    }
  }

  &__order {
    padding: 20px 0;
    border: none;
    background-color: #db2947;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;

    &_desktop {
      margin-top: 10px;
    }

    &_mobile {
      display: none;
      bottom: -200px;
      transition: bottom 300ms ease-in-out;

      &.fixed {
        width: calc(100% - 30px);
        position: fixed;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        z-index: 20;
      }
    }
  }

  &__operator {
    grid-area: operator;

    &__container {
      padding: 0;
      max-width: 1200px;
    }

    &-content {
      padding: 25px 20px 50px;
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: #ebf1fa;
        clip-path: polygon(0 0, 100% 0, 100% 80%, 22% 87%, 12% 100%, 7% 87%, 0 87%);
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #ffffff;
        clip-path: polygon(
          4px 4px,
          calc(100% - 4px) 4px,
          calc(100% - 4px) calc(80% - 4px),
          calc(22%) calc(87% - 4px),
          calc(12% + 1px) calc(100% - 5px),
          calc(7% + 2px) calc(87% - 4px),
          4px calc(87% - 4px)
        );
      }
    }

    &-name {
      padding-bottom: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #db2947;
      text-transform: capitalize;
    }

    &-email,
    &-phone {
      padding-left: 32px;
      position: relative;
      font-size: 16px;
      line-height: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-position: center;
      }
    }

    &-phone {
      margin-bottom: 8px;

      &::before {
        background: url('../../../../assets/images/common/phone.svg') center / cover no-repeat;
        background-size: 15px;
      }
    }

    &-email {
      &::before {
        background: url('../../../../assets/images/common/email.svg') center / cover no-repeat;
        background-size: 16px 12px;
      }
    }

    &-more {
      padding: 20px 0;
      border: 2px solid #db2947;
      background-color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #db2947;
      width: 100%;
      margin: 30px 0 50px 0;
      text-align: center;
    }
    
    &-more:hover {
    color: #ffffff;
    background-color: #db2947;
    cursor: pointer;
    }

  }

  &__params {
    padding-top: 15px;
    grid-area: params;

    &__container {
      padding: 0;
      max-width: 1200px;
    }

    &-content {
      padding: 25px 20px 40px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: #ebf1fa;
        clip-path: polygon(0 0, 100% 3%, 100% 95%, 0 100%);
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #ffffff;
        clip-path: polygon(
          4px 4px,
          calc(100% - 4px) calc(3% + 4px),
          calc(100% - 4px) calc(95% - 4px),
          4px calc(100% - 4px)
        );
      }
    }

    &-name {
      padding-bottom: 2px;
      font-size: 14px;
      line-height: 20px;
      color: #94a5b4;
    }

    &-value {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    &_fixed {
      padding-top: 0;
      width: 100%;
      position: fixed;
      left: 50%;
      top: -120px;
      transform: translateX(-50%);
      z-index: 20;
      background-color: #ffffff;
      box-shadow: 0px 12px 20px -9px rgba(4, 30, 56, 0.1);
      transition: top 0.3s ease-in-out;

      .tour-item__container {
        padding: 24px 40px;
        display: flex;
        gap: 24px;
      }

      .tour-item__name {
        flex-grow: 1;
        @include cv900;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.12em;
        color: #2b4761;
        font-stretch: 270%;
      }

      .tour-item__params-content {
        padding: 0;
        flex-direction: row;
        gap: 0;
        flex-shrink: 0;

        .tour-item__params-group {
          height: 56px;
          flex-shrink: 0;

          &:not(:first-child) {
            padding-left: 24px;
            border-left: 1px solid #d9e0e6;
          }
          &:not(:last-child) {
            padding-right: 24px;
          }
        }

        &::after {
          content: none;
        }

        &::before {
          content: none;
        }
      }

      .tour-item__order_desktop {
        margin-top: 0;
        margin-left: 69px;
        padding: 16px 32px;
        height: 56px;
        font-size: 18px;
        text-transform: capitalize;
      }

      &.show {
        top: 120px;
      }
    }
  }

  &__description {
    padding-top: 45px;
    white-space: pre-line;

    &-title {
      padding-bottom: 15px;
      @include cv900;
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 0.12em;
      font-stretch: 270%;
      color: #94a5b4;
    }
  }

  &__program-tour {
    padding-top: 80px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #e8eff6;
      clip-path: polygon(0 125px, 100% 80px, 100% 100%, 0 100%);
    }

    &__container {
      padding-top: 125px;
      padding-bottom: 85px;
    }
  }

  &__path {
    padding-top: 15px;
    background-color: #e8eff6;

    &__container {
      padding: 0;
      max-width: 100%;
    }

    &__title {
      margin: 0 auto;
      width: fit-content;
      position: relative;
      z-index: 1;
      @include cv900;
      font-size: 47px;
      line-height: 58px;
      text-align: center;
      letter-spacing: 0.12em;
      font-stretch: 270%;

      &::before {
        content: '';
        display: block;
        width: calc(100% + 110px);
        height: calc(100% + 45px);
        position: absolute;
        top: -15px;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        background-color: #d9e5f0;
        clip-path: polygon(10% 0%, 100% 0, 90% 100%, 0 95%);
      }
    }
  }

  &__photo {
    padding-top: 80px;

    &__container {
      max-width: 1440px;
      padding: 0;
    }

    &__title {
      @include cv900;
      text-align: center;
      font-size: 47px;
      line-height: 58px;
      letter-spacing: 0.12em;
      font-stretch: 270%;
    }
  }

  &__faq {
    padding-top: 120px;

    &__container {
      padding: 0 40px;
      max-width: 1200px;
    }
  }

  &__recommendations {
    padding-top: 120px;

    &__container {
      padding: 0 28px;
      max-width: 1224px;
    }

    &__title {
      @include cv900;
      text-align: center;
      font-size: 47px;
      line-height: 58px;
      letter-spacing: 0.12em;
      font-stretch: 270%;
    }
  }

  &__to-designer {
    padding-top: 60px;
  }

  &__btn {
    padding: 28px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    outline: none !important;
    border: 2px solid #2b4761;
    background-color: #2b4761;
    color: #ffffff;

    &-text {
      &::before {
        content: 'попробовать ';
      }
    }

    & svg path {
      stroke: #ffffff;
    }
  }
}

.button-slider {
  height: 40px;
  width: 40px;
}

@media (max-width: 1123px) {
  .tour-item {
    padding: 0 0 60px;

    &__container {
      padding: 0 15px;
    }

    &__header-wrapper {
      padding: 0;
      display: block;
    }

    &__header {
      padding: 15px;
      height: 560px;
      max-width: 1440px;
      gap: 15px;
    }

    &__image {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &::before {
        content: '';
        width: 100%;
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
        clip-path: polygon(0 100%, 100% 93%, 100% 100%);
      }
    }

    &__back {
      top: 15px;
      left: 15px;
      font-size: 12px;
      line-height: 16px;

      &__icon {
        width: 12px;
        height: 12px;
      }
    }

    &__type {
      padding: 2px 8px;

      &::before {
        width: 80px;
        height: 25px;
      }
    }

    &__title {
      padding-bottom: 15px;
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;

      span {
        padding: 4px 16px 14px;
      }
    }

    &__order {
      font-size: 14px;
      line-height: 20px;

      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    &__operator {
      padding-top: 25px;

      &__container {
        padding: 0 15px;
      }

      &-content {
        padding: 25px 20px 50px;

        &::after {
          clip-path: polygon(0 0, 100% 0, 100% 80%, 22% 87%, 12% 100%, 7% 87%, 0 87%);
        }

        &::before {
          clip-path: polygon(
            4px 4px,
            calc(100% - 4px) 4px,
            calc(100% - 4px) calc(80% - 4px),
            calc(22%) calc(87% - 4px),
            calc(12% + 1px) calc(100% - 5px),
            calc(7% + 2px) calc(87% - 4px),
            4px calc(87% - 4px)
          );
        }
      }

      &-name {
        padding-bottom: 12px;
        font-size: 16px;
      }

      &-email,
      &-phone {
        padding-left: 32px;
        position: relative;
        font-size: 16px;
        line-height: 24px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          background-position: center;
        }
      }

      &-phone {
        margin-bottom: 8px;

        &::before {
          background: url('../../../../assets/images/common/phone.svg') center / cover no-repeat;
          background-size: 15px;
        }
      }

      &-email {
        &::before {
          background: url('../../../../assets/images/common/email.svg') center / cover no-repeat;
          background-size: 16px 12px;
        }
      }
    }

    &__params {
      padding-top: 15px;

      &__container {
        padding: 0 15px;
      }

      &-content {
        padding: 25px 20px;

        &::after {
          clip-path: polygon(0 0, 100% 3%, 100% 95%, 0 100%);
        }

        &::before {
          clip-path: polygon(
            4px 4px,
            calc(100% - 4px) calc(3% + 4px),
            calc(100% - 4px) calc(95% - 4px),
            4px calc(100% - 4px)
          );
        }
      }

      &-name {
        font-size: 12px;
        line-height: 16px;
      }

      &-value {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__params_fixed {
      display: none;
    }

    &__description {
      padding-top: 25px;

      &-title {
        font-size: 18px;
        line-height: 26px;
      }

      &-text {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__program-tour {
      padding-top: 40px;

      &::before {
        clip-path: polygon(0 65px, 100% 40px, 100% 100%, 0 100%);
      }

      &__container {
        padding-top: 60px;
        padding-bottom: 40px;
      }
    }

    &__path {
      padding-top: 20px;
      background-color: #e8eff6;

      &__container {
        padding: 0;
        max-width: 100%;
      }

      &__title {
        font-size: 24px;
        line-height: 32px;
        font-stretch: 300%;

        &::before {
          width: calc(100% + 50px);
          height: calc(100% + 40px);
          top: 50%;
          transform: translate(-50%, -50%);
          clip-path: polygon(10% 0%, 100% 0, 90% 100%, 0 95%);
        }
      }
    }

    &__photo {
      padding-top: 40px;

      &__title {
        font-size: 24px;
        line-height: 32px;
        font-stretch: 300%;
      }
    }

    &__faq {
      padding-top: 60px;

      &__container {
        padding: 0 15px;
      }
    }

    &__recommendations {
      padding-top: 60px;

      &__container {
        padding: 0 15px;
      }

      &__title {
        font-size: 24px;
        line-height: 32px;
        font-stretch: 300%;
      }
    }

    &__to-designer {
      padding-top: 25px;
    }

    &__btn {
      padding: 18px 0;
      font-size: 14px;
      line-height: 20px;

      &-text {
        &::before {
          content: none;
        }
      }

      & svg path {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.modal-overlay {
  position: absolute;
  z-index: 1000;
  border: 1px solid #db2947;
  position-area: top; 
}

.modal-content {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px 45px 45px 24px;
  max-width: 642px;
  text-align: start;
  position: relative;
  gap: 1rem;
}
