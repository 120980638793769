.auth-user-form {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__submit {
    margin-top: 8px;
    padding: 16px;
    background-color: #db2947;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    border: none;
    color: #ffffff;

    &:focus {
      outline: 1px dotted #94a5b4;
    }
  }

  &__restore-pass {
    margin-left: auto;
    width: fit-content;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;
  }
}

@media (max-width: 900px) {
  .auth-user-form {
    padding: 24px 0 16px;
    gap: 12px;

    &__submit {
      padding: 16px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.56px;
    }
  }
}
