.travel-guide-item-params,
.travel-guide-item-address {
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .address-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    &.city {
      color: #8a8e9b;
    }

    &.flora {
      color: #8daf9c;
    }
  }

  &__name {
    padding-bottom: 2px;
    font-size: 14px;
    line-height: 20px;

    &.city {
      color: #8a8e9b;
    }

    &.flora {
      color: #8daf9c;
    }
  }

  &__value {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &.city {
    background-color: #161d37;
  }

  &.flora {
    background-color: #1c5f3a;
  }
}

.travel-guide-item-params {
  clip-path: polygon(0 0, 100% 10px, 100% 100%, 0 100%);
}

.travel-guide-item-address {
  padding-bottom: 34px;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), 0 100%);

  .travel-guide-item-params__value {
    font-weight: 400;
  }

  &.city {
    gap: 10px;
  }

  .city-address-group {
    display: flex;
    gap: 8px;

    &__icon {
      flex-shrink: 0;

      &.address {
        fill: #505669;
      }
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-params {
    padding: 37px 24px 25px;
  }
  .travel-guide-item-address {
    padding: 24px 24px 25px;
  }

  .travel-guide-item-params,
  .travel-guide-item-address {
    gap: 16px;

    .address-title {
      font-size: 16px;
    }

    &__name {
      padding-bottom: 2px;
      font-size: 12px;
      line-height: 16px;
    }

    &__value {
      font-size: 16px;
    }
  }

  .travel-guide-item-params {
    clip-path: polygon(0 10px, 100% 0, 100% 100%, 0 100%);
  }

  .travel-guide-item-address {
    padding-bottom: 34px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), 0 100%);

    &.city {
      gap: 10px;
    }

    .city-address-group {
      display: flex;
      gap: 8px;

      &__icon {
        flex-shrink: 0;
      }
    }
  }
}
