.home-tours {
  padding-top: 80px;
  padding-bottom: 140px;
  background-color: white;

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__list {
    padding: 60px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  &__item {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 103%;
      height: 109%;
      max-width: calc(100% + 40px);
      max-height: calc(100% + 30px);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      transition: background-color 300ms ease-in-out;
      clip-path: polygon(0 10%, 97% 0, 100% 96%, 1% 100%);
    }

    &__content {
      padding: 30px;
      width: 100%;
      display: flex;
      gap: 25px;
      position: relative;
      z-index: 1;
      background: #f5f8fa;
      cursor: pointer;
    }

    &__preview {
      position: relative;
    }

    &__img {
      width: 340px;
      height: 260px;
      object-fit: cover;
    }

    &__type {
      padding: 15px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      @include c900;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.12em;
      color: #ffffff;

      &::before {
        content: '';
        display: block;
        width: 80px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        background-color: #2b4761;
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 3% 90%);
      }
    }

    &__description {
      &.left {
        padding-right: 30px;
        flex-grow: 1;
        border-right: 1px solid #d9e0e6;
      }
      &.right {
        padding-left: 5px;
        min-width: 145px;
      }
    }

    &__name {
      padding-bottom: 15px;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
      color: #2b4761;
    }

    &__params {
      padding-bottom: 25px;
      display: flex;

      &__data {
        padding: 0 25px;
        border-right: 1px solid #d9e0e6;
        border-left: 1px solid #d9e0e6;

        &:first-child {
          padding-left: 0;
          border: none;
        }
        &:last-child {
          padding-right: 0;
          border: none;
        }
      }

      &__name {
        padding-bottom: 5px;
        font-size: 14px;
        line-height: 20px;
        color: #94a5b4;
      }

      &__value {
        font-size: 18px;
        line-height: 28px;
        color: #2b4761;

        &.price {
          padding-bottom: 5px;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #2b4761;
          white-space: no-wrap;
          
          .ruble-icon {
            width: 10px;
            display: inline-block;
          }
        }


        &.duration {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #94a5b4;
        }
      }
    }

    &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #2b4761;
    }

    &:hover {
      &::before {
        background-color: #db2947;
      }
    }
  }

  &__btn {
    margin: 0 auto;
    padding: 24px 0;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #d9e0e6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #2b4761;
    position: relative;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

    & svg path {
      stroke: #2b4761;
    }

    &[disabled] {
      color: #d9e0e6;

      & svg path {
        stroke: #d9e0e6;
      }
    }

    &:hover:not([disabled]) {
      background-color: #2b4761;
      border-color: #2b4761;
      color: #ffffff;

      & svg path {
        stroke: #ffffff;
      }
    }
  }
}

.home-tours__slider {
  .slick-track {
    display: flex;
    justify-content: flex-start;
    column-gap: 26px;
  }
  .slick-dots {
    transform: translateY(20px);
  }
}

@media (max-width: 1123px) {
  .home-tours {
    padding-top: 50px;
    padding-bottom: 60px;

    &__container {
      padding: 0 15px;
    }

    &__list {
      padding: 25px 0 25px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }

    &__item {
      &__content {
        padding: 0;
        flex-direction: column;
        gap: 15px;
      }

      &__img {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }

      &__description {
        padding: 0 15px 0;
        &.left {
          padding-right: 15px;
          border-right: none;
        }
        &.right {
          padding-left: 15px;
          padding-bottom: 15px;
          min-width: 145px;
        }
      }

      &__name {
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 24px;
      }

      &__params {
        padding-bottom: 0;

        &__data {
          padding: 0 15px;

          &:nth-child(2),
          &:nth-child(3) {
            white-space: nowrap;
          }
        }

        &__name {
          padding-bottom: 2px;
          font-size: 12px;
          line-height: 16px;
        }

        &__value {
          font-size: 16px;
          line-height: 24px;

          &.price {
            padding-bottom: 2px;
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 20px;
            line-height: 24px;

            .ruble-icon {
              width: 10px;
              display: inline-block;
            }
          }

          &.duration {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      &__text {
        display: none;
      }
    }

    &__btn {
      padding: 18px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      & svg path {
        height: 16px;
        width: 16px;
      }
    }
  }

  .home-tours__slider {
    .slick-arrow {
      display: block;
    }
    .slick-track {
      display: flex;
      justify-content: flex-start;
      column-gap: 26px;
    }
    .slick-dots {
      transform: translateY(20px);
    }
  }
}

@media (max-width: 850px) {
  .home-tours {
    &__list {
      display: flex;
    }
  }
}
