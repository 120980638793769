.filterBody {
  position: relative;
}

.simpleFilterInput {
  display: flex;
  padding: 16px 12px 16px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border: 2px solid  #D9E0E5;
  background:#FFF;
  &__value {
    color:  #94A5B4;
    @include a400;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
  }
}

.simpleListPanel {
  z-index: 1003;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  &__title{
    display: flex;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    color: #2B4761;
    @include c900;
    font-size: 20px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 2.4px;
  }
  .list {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 176px - 90px - 90px);
    li{
      display: flex;
      padding: 8px 0px 20px 0px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-bottom: 1px solid #EBF1FA;
      color:  #2B4761;
      @include a400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      span{
        flex: 1 0 0;
      };
    }
  }
  .controls {
    display: flex;
    width: 100%;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid  #EBF1FA;
    background:  #FFF;
    &__btn {
      display: flex;
      height: 60px;
      padding: 28px 48px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      @include a500;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.56px;
      text-transform: uppercase;


    }
    .blueBtn {
      color: #fff;
      border: 2px solid #2B4761;
      background:  #2B4761;
    }
    .whiteBtn{
      color: #2B4761;
      border: 2px solid #D9E0E5;
    }
  }
}

.simpleFilterCheckbox {
  width: 24px;
  height: 24px;
  border: 1px solid  #EBF1FA;
  cursor: pointer;
}
