.privileges-step {
  display: flex;
  padding: 20px 0px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1.5px solid $gray30;
  position: relative;

  p {
    a {
      color: #db2947;
    }
  }

  &__order {
    color: white;
  }

  &__label {
    @include a400;
    color: $dark-blue;
    font-size: 22px;
    font-weight: 400;
    line-height: 120%;
  }
}

.steps-list {
  border-bottom: 1.5px solid $gray30;
}

.step-download-btn {
  @include a400;
  position: absolute;
  right: 4px;
  font-size: 16px;
  padding: 12px 16px 12px 12px;
  display: inline-flex;
  border: none;
  background-color: $dark-blue;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: #DB2947;
  }

  svg{
    fill: #FFFFFF;
  }

  span{
    margin-left: 10px;
  }
}

@media (max-width: 900px) {
  .break-line{
    display: none ;
  }
}
