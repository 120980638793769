.travel-card {
  height: 640px;

  &__background {
    height: 100%;
    object-fit: cover;
  }

  &__title {
    padding: 0;
    padding-bottom: 60px;
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #ffffff;
    font-stretch: 270%;
  }

  &__content {
    padding: 50px 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    opacity: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.24) 51.5%, rgba(0, 0, 0, 0.6) 75.5%);
  }

  &__btn {
    padding: 10px 15px;
    @include c900;
    position: absolute;
    bottom: 35px;
    border: none;
    outline: none !important;
    background-color: transparent;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    opacity: 1;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + 5px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: background-color 300ms;
    }

    &::before {
      background-color: #ffffff;
      clip-path: polygon(
        0 3px,
        100% 0,
        100% 2px,
        2px 5px,
        2px calc(100% - 2px),
        calc(100% - 7px) calc(100% - 7px),
        calc(100% - 2px) 2px,
        100% 2px,
        100% 0,
        calc(100% - 5px) calc(100% - 5px),
        0 100%
      );
    }

    &::after {
      background-color: transparent;
      clip-path: polygon(0 3px, 100% 0, calc(100% - 5px) calc(100% - 5px), 0 100%);
    }

    &:hover {
      &::before {
        background-color: transparent;
      }

      &::after {
        background-color: #db2947;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &__title {
      padding-bottom: 0;
      transition: padding-bottom 0.3s ease-in-out;
    }

    &__btn {
      opacity: 0;
      display: none;
    }

    &__content:hover {
      .travel-card__btn {
        display: block;
        opacity: 1;
        animation: travel-btn 0.3s ease-in-out;
      }

      .travel-card__title {
        padding-bottom: 60px;
      }
    }
  }
}

@keyframes travel-btn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1123px) {
  .travel-card {
    height: 440px;

    &__title {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.12em;
      font-stretch: 200%;
    }

    &__content {
      padding: 22px 10px;
      gap: 13px;
    }

    &__btn {
      padding: 8px 15px;
      bottom: 25px;
      @include a500;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      letter-spacing: normal;

      &::before {
        opacity: 0.3;
      }
    }
  }
}
