@mixin base-btn-see-more($bg-color: #2b4761) {
  @include a500;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  outline: none !important;
  border: 2px solid $bg-color;
  background-color: $bg-color;
  color: #ffffff;
  margin-top: 16px;

  & svg path {
    stroke: #ffffff;
  }
}

@mixin base-banner {
  align-items: center;
  height: 60px;
  width: 100vw;
  gap: 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
  padding-left: 4px;
}

@mixin banner-common($from, $to) {
  @include base-banner;
  animation: banner-shared 30s infinite linear;

  @keyframes banner-shared {
    from {
      transform: translateX($from);
    }
    to {
      transform: translateX($to);
    }
  }
}

.banner-common-to-left {
  @include base-banner;
  animation: toLeft 70s infinite linear;

  @keyframes toLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

.banner-common-to-right {
  @include base-banner;
  animation: toRight 70s infinite linear;

  @keyframes toRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
}

.cluster-member-gallery {
  margin-bottom: 50px;
  &__content {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
  }

  &__imag {
    height: 188.056px;
    flex-shrink: 0;
    background-size: 100%;
    opacity: 0.2;
    object-fit: contain;
    margin-right: 1rem;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1;
      }
    }

    &__1 {
      margin-top: 30px;
      width: 342.646px;
      flex-shrink: 0;
      position: relative;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    &__2 {
      width: 437px;
      height: 238px;
      position: relative;
      clip-path: polygon(0 10px, 100% 10px, 94% 96%, 20px 100%);

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    &__3 {
      margin-top: 30px;
      width: 338.646px;
      height: 188.056px;
      flex-shrink: 0;
      background-image: url('../../../../assets/images/trk/participants/1.jpeg');
      clip-path: polygon(10px 0, 95% 0, 100% 100%, 0 90%);
    }

    &__hammer-active-img {
      margin-top: 30px;
      width: 338.646px;
      height: 188.056px;
      position: relative;
      flex-shrink: 0;
      clip-path: polygon(10px 0, 95% 0, 100% 100%, 0 90%);
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    &__hammer-1 {
      margin-top: 30px;
      width: 342.646px;
      flex-shrink: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    &__hammer-2 {
      width: 437px;
      height: 238px;
      clip-path: polygon(0 10px, 100% 10px, 94% 96%, 20px 100%);
    }

    &__4 {
      width: 338.646px;
      height: 188.056px;
      flex-shrink: 0;
      background-image: url('../../../../assets/images/trk/participants/5.jpeg');
      clip-path: polygon(0 0, 98% 0, 100% 100%, 0 90%);
    }

    &__5 {
      width: 430.826px;
      height: 236.666px;
      flex-shrink: 0;
      background-image: url('../../../../assets/images/trk/participants/4.jpeg');
      clip-path: polygon(10px 0, 98% 0, 96% 100%, 0 96%);
    }
  }

  &__frist {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-items: center;
    justify-content: center;
  }

  &__second {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-items: center;
    justify-content: center;
  }
}

.about-main {
  color: #ffffff;
  background-color: #0b2040;
  margin-top: -200px;
  position: relative;
  overflow: hidden;
  height: 2524px;

  &__container {
    margin-top: 200px;
    padding: 0 40px;
    height: 100%;
    position: absolute;
  }

  &__title {
    margin-left: 0;
    @include cv1000;
    font-size: 80px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 1000;
    line-height: 115%;
    letter-spacing: 0.12em;
    font-stretch: 300%;
  }

  &__background {
    position: relative;

    &__inner {
      z-index: 2;
      width: 549px;

      p {
        color: #ffffff;
        @include a400;
        font-size: 18px;
        line-height: 24px;

        br {
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }
    }

    &__image {
      position: absolute;
      background: #0b2040;
      transform: translateX(-50%);
      z-index: 1;
      width: 100%;
      height: 2520px;
      left: 50%;
      background: url(../../../../assets/images/trk/participants/map.png) no-repeat;
      background-size: contain;
    }
  }
}

.cluster-member {
  overflow: hidden;
  flex: 1 0 auto;
  position: relative;
  height: 100%;
  padding-top: 150px;
  margin-top: -210px;
  padding-bottom: 300px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #0b2040;
    opacity: 90;
    clip-path: polygon(0 0, 100% 110px, 100% 100%, 0 80%);
  }

  &__title {
    @include cv1000;
    font-size: 64px;
    text-transform: uppercase;
    color: #FFFFFF;
    letter-spacing: 7.68px;
    line-height: 78px;
    font-stretch: 300%;
  }

  &__news {
    position: relative;
    max-width: 1200px;
    margin: 60px auto;
    padding-left: 110px;
    padding-right: 0;
    box-sizing: content-box;

    &__content {
      margin-top: 14.85px;
      display: flex;
      gap: 10px;
    }

    &__slider {
      margin-left: -9px;
      margin-right: -10px;

      .slick-dots {
        display: flex;
        justify-items: center;
        justify-content: center;
        width: 100%;
        & button {
          width: 16px;
          height: 16px;
          border: 1.5px solid #ffffff;

          &::before,
          &::after {
            content: none;
          }

          &:hover {
            border-color: #2b4761;
            background-color: #ffffff;
          }
        }

        li.slick-active button {
          background-color: #ffffff;
          border-color: #2b4761;
        }
      }

      .button-slider {
        position: absolute;
        top: -110px;
        right: -20px;

        &.right {
          right: Max(calc((100% - 1200px) / 2), 40px);
          transform: translateY(100%);
        }

        &.left {
          right: Max(calc((100% - 1200px) / 2 + 10px), 50px);
          transform: translate(-100%, 120%);
        }
      }
    }
  }
}

.container-sm {
  margin-left: -10px;
  margin-top: 20px;
  padding-bottom: 106px;
}

.about-news-no-image {
  width: 300px;
  height: 400px;
  position: relative;
  margin: 0 9px;

  &__desc {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  h5 {
    bottom: 30px;
    overflow: hidden;
    color: var(--White, #fff);
    text-overflow: ellipsis;
    white-space: pre-wrap;
    @include cv1000;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 2.64px;
  }
}

.about-news-card-sm {
  width: auto;
  height: 400px;
  position: relative;

  @mixin base-text {
    overflow: hidden;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    @include cv1000;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 2.64px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
  }

  &__content {
    bottom: 20px;

    height: 400px;
    z-index: 1;
    cursor: pointer;
    margin-right: 30px !important;
    position: relative;
    background: #091a33;
    p {
      @include line-clamp(3);
      @include base-text;
      position: absolute;
      bottom: 198px;
      left: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }

    &__desc {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 80%;
    }

    h5 {
      @include base-text;
      bottom: 30px;
      padding-top: 12px;
      padding-right: 15px;
      @include line-clamp(3);
      overflow: hidden;
      color: #ffffff;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.info-card-container {
  position: relative;
  height: 420px;
  max-width: 300px;
  margin-right: 5px;

  z-index: 2;

  &__btn {
    position: absolute;
    bottom: 40px;
    left: 20px;
    max-width: 144px;
  }
}

.about-main-info-card {
  height: 360px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url('../../../../assets/images/trk/participants/1.jpeg');

  &__content {
    position: absolute;
    bottom: 20px;
    padding: 20px;
    z-index: 3;

    p {
      padding-top: 12px;
      @include line-clamp(3);
      overflow: hidden;
      color: #ffffff;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      @include a400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__preveiw {
    width: 300px;
    height: 440px;
    position: relative;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29.77%, #000 100%), lightgray 50% / cover no-repeat;
    }
  }
}

.title_content {
  margin-bottom: 20px;
  margin-top: 50px;
}

.about-news-card {
  height: 249.653px;
  position: relative;
  padding: 22px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  &__btn {
    @include base-btn-see-more();
    width: 150px;
  }

  &__go-to-btn {
    @include base-btn-see-more($red);
    width: 100%;
  }

  @media (hover: hover) {
    &:hover &__btn {
      background: #ffffff;
      color: #2b4761;
      border: none;
    }

    &:hover {
      &:before {
        background-color: $red;
        clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
      }

      & svg path {
        stroke: #2b4761;
      }
    }
  }
  img {
    width: 180px;
    height: 147.427px;
    flex-shrink: 0;
  }

  &__desciption {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    align-self: stretch;
    overflow: hidden;
    color: #ffffff;
    text-overflow: ellipsis;
    @include a400;
    font-size: 22px;
    line-height: 36px;
    height: 144px;
    font-stretch: 300%;
  }
}

.banner {
  &__start {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background: var(--Red, #db2947);
    margin-left: 15px;

  }

  &__row{
    display: flex;
    align-items: center;

    p {
      overflow: hidden;
      color: $dark-blue;
      text-overflow: ellipsis;
      @include cv900;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 2.4px;
      white-space: nowrap !important;
      text-transform: uppercase;
      width: 100%;
      font-stretch: 300%;
    }
  }

  &__first {
    height: 54px;
    width: 100vw;
    background: #ffffff;
    transform: rotate(-0.7deg);
    @include base-banner;
  }

  &__second {
    margin-top: 10px;
    height: 54px;
    width: 100vw;
    background: #f5f8fa;
    transform: rotate(0.25deg);
    @include base-banner;
  }
}

@media (max-width: 1123px) {
  .about-main {
    margin-top: -200px;

    &__container {
      padding: 0 15px;
      margin-top: 113px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 30px;
      line-height: 40px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 24px;
    }

    &__background {
      &__inner {
        z-index: 2;
        max-width: 350px;
      }

      &__image {
        height: 1500px;
        background-size: cover;
      }
    }
  }
}

@media (min-width: 1441px) {
  .about-main {
    height: 2300px;
  }

  .cluster-member {
    &__news {
      max-width: 1391px;
    }
  }
}

@media (max-width: 900px) {
  .container-sm {
    margin-left: 0;
    padding-bottom: 0;
  }

  .cluster-member-gallery {
    &__frist {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 73px;
    }

    &__imag {
      height: 188.056px;
      flex-shrink: 0;
      background-size: 100%;
      opacity: 0.2;
      object-fit: contain;
      margin-right: 1rem;

      &__1 {
        width: 199px;
        height: 108px;
        flex-shrink: 0;
      }

      &__2 {
        width: 239px;
        height: 133px;
        flex-shrink: 0;
        margin-top: 62px;
      }

      &__3 {
        width: 299px;
        height: 166.533px;
        flex-shrink: 0;
        margin-top: -252px;
        opacity: 0.7;
      }

      &__hammer-active-img {
        width: 299px;
        height: 166.533px;
        flex-shrink: 0;
        margin-top: -252px;
        opacity: 1;
      }
    }
  }

  .main-slider {
    position: absolute;
    height: 440px;
    width: 100%;
    top: 1254px;
    z-index: 2;
  }

  .members-content {
    &__cluster {
      &__become-member {
        @include base-become-member-btn;
        padding: 14px 24px;
        font-size: 16px;
        width: 350px;
        display: block;
        position: absolute;
        bottom: -190px;
      }
    }
  }

  .about-main {
    height: 2524px;
    &__title {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 3.6px;
      font-stretch: 720%;
      span {
        display: none;
      }
    }

    &__background {
      &__inner {
      }

      &__image {
        margin-top: 95px;
      }
    }
  }
  .title_content {
  }

  .banner {
    &__row{
      p {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 1.56px;
      }
    }

    &__first {
      transform: rotate(-0.9deg);
      height: 40px;
    }

    &__second {
      margin-top: 0;
      transform: rotate(0.5deg);
      height: 40px;
    }
  }

  .cluster-member {
    height: 100%;

    &__title {
      color: #FFFFFF;
      @include cv1000;
      font-weight: 1000;
      font-size: 28px;
      line-height: 24px;
      letter-spacing: 2.88px;
      font-stretch: 400%;
      padding-left: 10px;
    }
    &__news {
      max-width: 800px;
      padding-left: 0;

      &__slider {
        margin-left: 7px;
        margin-right: -10px;

        .slick-arrow {
          display: none !important;
        }
      }
    }
  }

  .about-news-card {
    &__btn {
      border: none;
      background-color: #ffffff;
      color: #2b4761;
      margin-top: 16px;

      & svg path {
        stroke: #2b4761;
      }
    }
  }
}
