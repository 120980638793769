.params-filters-item {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  border: 2px solid #e8eaed;
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
  color: #2b4761;

  &_full {
    padding: 15px 10px;
    flex-grow: 1;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-color: #94a5b4;
    }
  }

  &.active {
    border-color: #db2947;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: #db2947;
      }
    }
  }
}

@media (max-width: 900px) {
  .params-filters-item {
    padding: 12px 16px;
    gap: 35px;
    font-size: 16px;
    line-height: 24px;

    &_full {
      padding: 15px 10px;
      min-width: 157px;
      flex-grow: 0;
    }
  }
}
