.travel-guide-item-advantage {
  padding: 120px 0 170px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 85px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    background-color: #ffffff;
  }

  &__title {
    padding-bottom: 60px;
  }

  &__slider {
    .slick-list {
      overflow: unset;
    }

    .slick-track {
      margin: 0;
      display: flex;
      gap: 60px;

      .slick-slide {
        width: Min(1100px, calc(100vw - 215px));
        flex-shrink: 0;
      }
    }
  }

  &__group {
    height: 1000px;
    display: flex !important;
    align-items: center;
    position: relative;
  }

  &__image {
    position: relative;

    & img {
      width: 100%;
      height: 850px;
      object-fit: cover;
      transition: height 0.3s ease-in-out;
    }
  }

  &__content {
    width: 395px;
    max-height: 900px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    right: 50px;
    bottom: -70px;
    background-color: #1c2543;
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__full-btn {
    padding: 6px;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: #1c2543;
    clip-path: polygon(0 2px, 100% 0, 100% 100%, 0 calc(100% - 2px));
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__name {
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    font-stretch: 300%;
  }

  &__description {
    font-size: 22px;
    line-height: 36px;
  }

  &__slider {
    .slick-active {
      .travel-guide-item-advantage {
        &__image {
          img {
            height: 1000px;
          }
        }

        &__content,
        &__full-btn {
          opacity: 1;
        }
      }
    }
  }

  &__after {
    width: 100%;
    height: 85px;
    clip-path: polygon(0 100%, 100% 0, 100% 100%);

    &.beige {
      background-color: #f2eee8;
    }

    &.black {
      background-color: #0f0f0f;
    }

    &.blue {
      background-color: #e8eff6;
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-advantage {
    padding: 60px 0 60px;

    &::before {
      height: 40px;
    }

    &__title {
      padding-bottom: 24px;
    }

    .slick-track {
      gap: 15px;

      .slick-slide {
        width: calc(50vw - 57.5px);
      }
    }

    &__group {
      height: auto;
      flex-direction: column;
    }

    &__image {
      width: 100%;

      & img {
        height: 300px;
      }
    }

    &__content {
      width: 100%;
      max-height: auto;
      padding: 16px;
      gap: 12px;
      position: static;
      opacity: 1;
    }

    &__full-btn {
      padding: 6px;
      width: 32px;
      height: 32px;
      top: 16px;
      right: 16px;
      opacity: 1;
    }

    &__name {
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }

    &__slider {
      .slick-active {
        .travel-guide-item-advantage {
          &__image {
            img {
              height: 300px;
            }
          }
        }
      }
    }

    &__after {
      height: 40px;
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-advantage {
    &__slider {
      .slick-track {
        .slick-slide {
          width: calc(100vw - 60px);
        }
      }
    }
  }
}
