.base-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &.space-between {
    justify-content: space-between;
  }
  &.reverse {
    flex-direction: row-reverse;
    width: 100%;
  }

  &__label {
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;

    &.dark {
      color: #2b4761;
    }
  }

  &__input {
    width: 24px;
    height: 24px;
    border: 1px solid #ebf1fa;

    &.active {
      background-color: #2b4761;
      border-color: #2b4761;
      svg {
        stroke: #ffffff;
      }
    }

    &.dark {
      border: 1.3px solid #3e465f;

      &.active {
        background-color: #db2947;
        border-color: #db2947;
      }
    }
  }

  $this: &;

  &.mobile {
    #{$this}__label {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.check-button {
    #{$this}__label {
      padding: 12px 16px;
      border: 1.5px solid #ebf1fa;

      &.active {
        border-color: #db2947;
      }
    }

    #{$this}__input {
      display: none;
    }
  }
}
