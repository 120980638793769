.relateBlog {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 4px solid #EBF1FA;
  border-top: none;
  position: relative;

  .lineHidden{
    height: 20px;
    width: 4px;
    background: #fff;
    position: absolute;
    left: -4px;
    top: 0;
  }

  .lineTop {
    height: 4px ;
    background:  #EBF1FA;
    transform: rotate(-4deg);
    transform-origin: top right;
    position: absolute;
    right: 0;
    top: 0px;
    width: calc(100% + 4px);
  }
  &__title{
    color: #DB2947;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    color: #2B4761;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    span{
      color: #94A5B4;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media (max-width: 768px) {
  .relateBlog {
    margin: 24px;

    .lineHidden {
      height: 25px;
    }
  }
}
