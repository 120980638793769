$dark-blue: #2b4761;
$blue: #2b4761;
$light-blue: #338fd1;
$light-blue2: #285b7d;
$red: #db2947;
$light: #e8e6d9;
$gray: #dedede;
$light-gray: #ebeff2;
$dark-gray: #b9b9b9;
$biege: #bdb5aa;

/*Новые переменные*/
$c-blue-dark: #1C2543;
$c-blue-main: #2C82D2;
$c-beige: #F7F6ED;
$c-blue-dark-border: #2E3652;
$c-blue-text: #2B4761;
$c-blue-tag: #6F83C3;
$c-blue: #3C5090;
$c-orange-secondary: #F89523;
$c-blue-light: #E8EFF6;
$c-blue-light-secondary: #EDEFF7;
$c-gray-40: #94A5B4;
$c-gray-30: #D9E0E5;
$c-gray-20: #EBF1FA;
$c-gray-10: #F5F8FA;
$c-green: #319B61;
$c-milk: #F7F6ED;
$c-red: #CF2945;
$c-orange: #F99F39;
$primary: #DB2947;
$overlay: rgba(39, 42, 46, 0.50);
$overlay-opacity-20: rgba(39, 42, 46, 0.20);
$breakpoints: (
        'xl-max': 1440px,
        'xl': 1440px,
        'lg-max': 1239px,
        'lg': 1240px,
        'md-max': 1023px,
        'md': 1024px,
        'sm-max': 767px,
        'sm': 768px,
        'xs-max': 519px,
        'xs': 520px
);
$xl: 1440px;
$shadow-menu: 0px -8px 20px -9px rgba(4, 30, 56, 0.10);
$shadow-head: 0px 8px 20px -9px rgba(4, 30, 56, 0.10);
$lg: 1240px;
$md: 1024px;
$sm: 768px;
$font-main: 'AkzidenzGroteskPro', sans-serif;
$font-title: 'CoFoPeshkaV0.6', sans-serif;
$gray10: #f5f8fa;
$gray30: #D9E0E5;
$gray40: #94a5b4;

$dark-blue: #2B4761;
$white: #fff;
