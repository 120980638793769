
.improve-popup__frame {
  min-height: 200px;
  max-width: 400px;
  height: fit-content;
  background: #f2f2f2;
  position: relative;
  padding: 23px 48px 31px 25px;
  display: grid;
  grid:
          'h' auto
          '.' 19px
          'd' auto
          '.' minmax(26px, 1fr)
          'btn' auto
          / auto;
  place-items: start;

  &::before {
    content: '';
    position: absolute;
    inset: 0 0 0 3.5%;
    z-index: -1;
    background-color: #db2947;
    rotate: -3.93deg;
  }
}

.improve-popup__close-btn {
  position: absolute;
  top: 27px;
  right: 21px;

  .close-icon {
    width: 25px;
    height: 25px;
    display: block;
    top: 12px;
    right: 8px;
    fill: #000000;
  }
}

.improve-popup__header {
  grid-area: h;
  font-family: 'CoFoPeshkaV0.5-260';
  font-weight: 900;
  font-size: 26px;
  color: #000000;
}

.improve-popup__description {
  grid-area: d;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.improve-popup__accept-btn {
  grid-area: btn;
  background: #DC2947;
  height: 38px;
  width: fit-content;
  padding: 0 10px 4px 10px;
  display: grid;
  place-items: center;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}
