.checkbox {
  div {
    color: #2b4761;
  }
}

.highlighted-text {
  color: #428BC2;
  text-decoration: underline;
}

.feedback-form {
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;

  &__input-group {
    width: 48%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 5px;
    position: relative;
  }

  &__textarea-group {
    width: 100%;
    position: relative;
  }

  &__input,
  &__textarea {
    border: none;
    border-radius: 2px;
    font-size: 22px;
    line-height: 36px;
    color: $blue;
  }

  &__input {
    padding: 34px 16px 14px 20px;
    width: 100%;
    box-shadow: 0 0 0 55px white inset;
    -webkit-text-fill-color: #2b4761;

    &:focus {
      & ~ .placeholder {
        top: 14px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__textarea {
    padding: 24px 16px 24px 20px;
    width: 100%;
    height: 280px;
    resize: none;
  }

  .placeholder {
    position: absolute;
    left: 20px;
    top: 24px;
    color: #94a5b4;
    font-size: 22px;
    line-height: 36px;
    transition: all 100ms ease-in-out;

    &_top {
      top: 14px;
      font-size: 14px;
      line-height: 20px;
    }

    &_opacity {
      opacity: 0;
    }
  }

  &__input-error {
    height: 25px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #db2947;
  }

  &__btn {
    width: 100%;
    padding: 24px 0;
    align-self: flex-start;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #d9e0e6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #2b4761;
    transition: all 200ms;

    &:hover {
      background-color: #2b4761;
      border: 2px solid #2b4761;
      color: #ffffff;
    }
  }
}

@media (max-width: 1123px) {
  .feedback-form {
    padding-bottom: 0;
    gap: 7px;

    &__input-group {
      width: 100%;
    }

    &__input,
    &__textarea {
      font-size: 18px;
      line-height: 28px;
    }

    &__input {
      padding: 24px 16px 8px;
      width: 100%;

      &:focus {
        & ~ .placeholder {
          top: 8px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &__textarea {
      padding: 16px;
      height: 220px;
    }

    .placeholder {
      left: 16px;
      top: 16px;
      font-size: 18px;
      line-height: 28px;

      &_top {
        top: 8px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__input-error {
      height: 15px;
      line-height: 15px;
      font-size: 11px;
    }

    &__btn {
      padding: 20px 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
