.designer-map-layers {
  padding: 16px 16px 24px 16px;
  max-width: 190px;
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  z-index: 10;
  background-color: #1c2543;
  color: #ffffff;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    padding: 8px;
    background-color: #2e3652;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &.active {
      cursor: auto;
    }

    &.active {
      background-color: #db2947;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #db2947;
      }
    }
  }

  &__image {
    width: 100%;
    height: 80px;
  }

  &__text {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__territory {
    display: flex;
  }
}

@media (max-width: 900px) {
  .designer-map-layers {
    padding: 0;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    transform: none;
    z-index: 20;
    background-color: #ffffff;
    color: #2b4761;

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 15;
      background-color: #272a2e;
      opacity: 0.5;
    }

    &__header {
      padding: 24px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__head {
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #2b4761;
    }

    &__close {
      width: 20px;
      height: 20px;
      fill: #2b4761;
      cursor: pointer;
    }

    &__content {
      padding: 0 12px 24px 16px;
      overflow-y: scroll;

      scrollbar-width: thin;
      scrollbar-color: #db2947 #ffffff;

      /* для Chrome/Edge/Safari */
      &::-webkit-scrollbar {
        height: 12px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #db2947;
        border-radius: 5px;
      }
    }

    &__title {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.56px;
      text-transform: none;
      color: #2b4761;
    }

    &__list {
      padding-bottom: 24px;
    }

    &__item {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: #ffffff;
      border: 1.5px solid #ffffff;

      &.active {
        background-color: #ffffff;
        border-color: #db2947;
      }
    }

    &__image {
      width: 104px;
      height: 72px;
      object-fit: cover;
    }

    &__text {
      margin-top: 0px;
      font-size: 16px;
      line-height: 24px;
      color: #2b4761;
    }
  }
}
