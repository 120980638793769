@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro-Light'), local('AkzidenzGroteskPro-Light'),
    url('../../assets/fonts/AkzidenzGroteskPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro Cn';
  src: local('AkzidenzGroteskPro-LightCn'), local('AkzidenzGroteskPro-LightCn'),
    url('../../assets/fonts/AkzidenzGroteskPro-LightCn.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro-Regular'), local('AkzidenzGroteskPro-Regular'),
    url('../../assets/fonts/AkzidenzGroteskPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro-Md'), local('AkzidenzGroteskPro-Md'),
    url('../../assets/fonts/AkzidenzGroteskPro-Md.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro-Bold'), local('AkzidenzGroteskPro-Bold'),
    url('../../assets/fonts/AkzidenzGroteskPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro-XBd'), local('AkzidenzGroteskPro-XBd'),
    url('../../assets/fonts/AkzidenzGroteskPro-XBd.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'AkzidenzGroteskPro Cn';
  src: local('AkzidenzGroteskPro-XBdCn'), local('AkzidenzGroteskPro-XBdCn'),
    url('../../assets/fonts/AkzidenzGroteskPro-XBdCn.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'CoFoPeshkaV0.5-260';
  src: local('CoFoPeshkaV0.5-260-Black'), local('CoFoPeshkaV0.5-260-Black'),
    url('../../assets/fonts/CoFoPeshkaV0.5-260-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CoFoPeshkaV0.6';
  src: url('../../assets/fonts/CoFo/CoFoPeshkaVariableV0.6.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@mixin a300 {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-weight: 300;
}
@mixin a300cn {
  font-family: 'AkzidenzGroteskPro Cn', sans-serif;
  font-weight: 300;
}
@mixin a400 {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-weight: 400;
}
@mixin a500 {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-weight: 500;
}
@mixin a700 {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-weight: 700;
}
@mixin a800 {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-weight: 800;
}
@mixin a800cn {
  font-family: 'AkzidenzGroteskPro Cn', sans-serif;
  font-weight: 800;
}
@mixin c900 {
  font-family: 'CoFoPeshkaV0.5-260', sans-serif;
  font-weight: 900;
}
@mixin cv900 {
  font-family: 'CoFoPeshkaV0.6', sans-serif;
  font-weight: 900;
}

@mixin cv1000 {
  font-family: 'CoFoPeshkaV0.6', sans-serif;
  font-weight: 1000;
}
