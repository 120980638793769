.trk {
  margin-bottom: 95px;
}
.trk__inner {
  &::before {
    top: 0;
    left: -100px;
  }
  &::after {
    top: 345px;
    left: 50%;
  }
}
.trk__title {
  margin-left: 0;
  font-size: 74px;
  font-weight: 900;
  color: $blue;
  letter-spacing: 0.2em;
}
.trk__subtitle {
  padding-top: 45px;
  margin-bottom: 25px;
  white-space: nowrap;
  font-size: 36px;
  font-weight: 800;
  color: $blue;
  & span {
    font-weight: 400;
  }
}
.trk__img {
  margin-left: 20px;
  max-width: 40%;
  width: 100%;
  float: right;
  text-align: center;
  font-size: 0;
}
.trk__content {
  font-size: 24px;
  color: $blue;
  p,
  ul {
    margin-top: 30px;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  ul,
  li {
    list-style: unset;
  }
}
.trk__map {
  padding-top: 45px;
  img {
    width: 100%;
    object-fit: contain;
  }
  &::before {
    top: 60%;
    left: -100px;
  }
  &::after {
    top: 30%;
    right: -100px;
  }
}
.trk__stat {
  display: flex;
  gap: 60px;

  .btn {
    margin-top: 60px;
    margin-left: 54px;
    display: block;
  }

  &::before {
    top: 50%;
    left: -100px;
  }
  &::after {
    top: 0;
    right: -150px;
  }
}
.trk__stat_graph {
  width: 60%;
  &-box {
    width: 100%;
    height: 395px;
    padding: 15px 25px 25px;
    background-color: #ffffff;
    box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.06);
  }
  &-title {
    padding-bottom: 17px;
    border-bottom: 1px solid #dedede;
    font-size: 28px;
    font-weight: 500;
  }
  &-label {
    margin: 70px auto 0;
    width: 90%;
    text-align: center;
    font-size: 24px;
    color: #2b4761;
  }
}
.trk__stat_text {
  font-size: 32px;
  & ul {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }
  & li {
    padding-left: 54px;
    position: relative;
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #db2947;
      position: absolute;
      left: 0;
      top: 14px;
    }
    & span {
      font-weight: 800;
      color: #2b4761;
    }
  }
}

@media (max-width: 1440px) {
  .trk__title {
    font-size: 63px;
  }
  .trk__subtitle {
    padding-top: 30px;
    margin-bottom: 22px;
    font-size: 30px;
  }
  .trk__content {
    font-size: 22px;
    p,
    ul {
      margin-top: 25px;
    }
  }
  .trk__map {
    padding-top: 40px;
  }
  .trk__stat {
    gap: 35px;

    .btn {
      margin-top: 50px;
      margin-left: 42px;
    }
  }
  .trk__stat_graph {
    width: 60%;
    &-box {
      height: 385px;
      padding: 15px 20px 25px;
    }
    &-title {
      font-size: 20px;
    }
    &-label {
      margin: 50px auto 0;
      width: 90%;
      font-size: 20px;
    }
  }
  .trk__stat_text {
    font-size: 28px;
    & ul {
      gap: 25px;
    }
    & li {
      padding-left: 42px;
      &::before {
        width: 12px;
        height: 12px;
        top: 13px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .trk {
    margin-bottom: 60px;
  }
  .trk__title {
    font-size: 47px;
  }
  .trk__subtitle {
    padding-top: 23px;
    margin-bottom: 15px;
    font-size: 23px;
  }
  .trk__content {
    font-size: 15px;
    img {
      width: 100%;
      height: auto;
      max-height: 400px;
      padding: 0 0 5px 0;
    }
    p,
    ul {
      margin-top: 19px;
    }
  }
  .trk__map {
    padding-top: 35px;
  }
  .trk__stat {
    gap: 25px;

    .btn {
      margin-top: 36px;
      margin-left: 33px;
    }
  }
  .trk__stat_graph {
    width: 60%;
    &-box {
      height: 295px;
      padding: 10px 15px 18px;
    }
    &-title {
      padding-bottom: 13px;
      font-size: 16px;
    }
    &-label {
      margin: 37px auto 0;
      width: 90%;
      font-size: 15px;
    }
  }
  .trk__stat_text {
    font-size: 21px;
    & ul {
      gap: 19px;
    }
    & li {
      padding-left: 33px;
      &::before {
        width: 10px;
        height: 10px;
        top: 9px;
      }
    }
  }
}
@media (max-width: 900px) {
  .trk__inner {
    &::before {
      top: 50px;
      left: 40%;
    }
    &::after {
      top: 400px;
      left: auto;
      right: 0%;
    }
  }
  .trk__title {
    padding-top: 36px;
  }
  .trk__subtitle {
    white-space: unset;
  }
  .trk__map {
    &::before {
      top: 0;
      left: -50px;
    }
    &::after {
      top: 30%;
      right: -10%;
    }
  }
  .trk__stat {
    flex-direction: column;

    .btn {
      margin: 22px auto 0;
    }

    &::before {
      top: -10%;
      left: -50px;
    }
    &::after {
      top: 50%;
      right: -10%;
    }
  }
  .trk__stat_graph {
    width: 100%;
    &-box {
      height: 310px;
      padding: 10px 15px 18px;
    }
    &-label {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .trk__title {
    padding-top: 36px;
    font-size: 33px;
  }
  .trk__subtitle {
    padding-top: 18px;
    margin-bottom: 7px;
    font-size: 20px;
  }
  .trk__content {
    font-size: 16px;
    p,
    ul {
      margin-top: 10px;
    }
  }
  .trk__map {
    padding-top: 25px;
  }
  .trk__stat {
    gap: 25px;

    .btn {
      margin: 33px auto 0;
      padding: 11px 0;
      width: 150px;
      font-size: 18px;
    }
  }
  .trk__stat_graph {
    &-box {
      height: 310px;
      padding: 10px 15px 18px;
    }
    &-title {
      padding-bottom: 13px;
      font-size: 20px;
    }
  }
  .trk__stat_text {
    font-size: 20px;
    & ul {
      gap: 14px;
    }
    & li {
      padding-left: 34px;
      &::before {
        width: 10px;
        height: 10px;
        top: 8px;
      }
    }
  }
}
@media (max-width: 500px) {
  .trk {
    margin-bottom: 30px;
  }
  .trk__title {
    padding-top: 9px;
    font-size: 23px;
  }
  .trk__subtitle {
    padding-top: 9px;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .trk__content {
    font-size: 11px;
    p,
    ul {
      margin-top: 7px;
    }
  }
  .trk__map {
    padding-top: 15px;
  }
  .trk__stat {
    padding-bottom: 15px;
    gap: 16px;

    .btn {
      margin: 16px auto 0;
      padding: 8px 0;
      width: 105px;
      font-size: 12px;
    }
  }
  .trk__stat_graph {
    &-box {
      height: 280px;
    }
    &-title {
      font-size: 14px;
    }
  }
  .trk__stat_text {
    font-size: 14px;
    & ul {
      gap: 10px;
    }
    & li {
      padding-left: 18px;
      &::before {
        width: 8px;
        height: 8px;
        top: 8px;
      }
    }
  }
}
