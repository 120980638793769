.publicField {
    border: 2px solid #ebebeb;
    border-radius: 20px;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px; 
}

.publicField-small {
    border: 2px solid #ebebeb;
    border-radius: 20px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 10px;
    display: inline-block;
}