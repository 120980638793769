.designer-location-item {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    padding-right: 16px;
  }

  &__main {
    padding-right: 12px;

    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #1c2543;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #1c2543;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 12px;
    fill: #ffffff;
    cursor: pointer;
  }

  &__title {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
  }

  &__in-order {
    padding: 16px 0;

    .in-order {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #db2947;
      cursor: pointer;

      &__icon {
        width: 20px;
        height: 20px;

        &.add {
          padding: 1px;
          border: 1.5px solid #db2947;
          border-radius: 50%;
          fill: #db2947;
          transform: rotate(45deg);
        }
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }

  &__preview {
    height: 150px;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__gallery {
    padding: 4px 6px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #ffffff;
    color: var(--Dark-blue, #2b4761);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  &__gallery-popup {
    padding: 20px;
    max-width: 100%;
    height: 100%;
  }

  &__parameters {
    padding: 4px 0;

    .location-parameters {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background-color: #2e3652;

      &__item {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 4px;
      }

      &__title,
      &__value {
        font-size: 12px;
        line-height: 16px;
      }

      &__title {
        color: #94a5b4;
      }

      &__value {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  &__in-tours,
  &__show-map {
    padding-top: 4px;

    .in-tours,
    .show-map {
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &__text {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

      &__icon {
        width: 20px;
        height: 20px;
        stroke: #db2947;
        border: 4px solid #ffffff;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }

    .show-map {
      width: 100%;
      border: none;
    }
  }

  &__to-blog {
    margin-top: 16px;

    .to-blog {
      padding: 12px 13px;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #2e3652;

      &__icon {
        flex-shrink: 0;
      }

      &__text {
        color: #d9e0e5;
        font-size: 14px;
        line-height: 20px;

        a {
          display: inline;
          color: #db2947;
        }
      }
    }
  }

  &__description {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #c6c8d0;
  }

  &__popular {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 1123px) {
  .designer-location-item {
    &__gallery-overlay {
      background-color: #18191b;
    }
  }
}

@media (max-width: 900px) {
  .designer-location-item {
    height: 100%;
    width: 100%;
    background-color: #1c2543;

    &__header {
      position: relative;
    }

    &__title {
      width: 90%;
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #ffffff;
    }

    &__in-order {
      padding: 24px 0 16px;

      .in-order {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &__icon {
          width: 20px;
          height: 20px;

          &.add {
            padding: 3px;
          }
        }
      }
    }

    &__preview {
      height: 200px;
    }

    &__parameters {
      .location-parameters {
        gap: 12px;

        &__item {
          display: grid;
          grid-template-columns: 100px 1fr;
          gap: 4px;
        }

        &__title,
        &__value {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__gallery-popup {
      padding: 20px 16px;
    }

    &__map {
      &__wrapper {
        width: 100%;
        height: 150px;
      }
    }

    &__popular {
      padding-top: 24px;
    }
  }
}

.backbtn {
  display: flex;
  position: absolute;
  right: -87px;
  top: 20px;
  width: 40px;
  height: 40px;
  background: #1c2543;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    stroke: #ffffff;
    transform: rotate(180deg);
  }
}
