.upcoming-tours {
  width: 100%;
  background-color: #e8eff6;
  text-align: center;
  justify-content: center;
  padding: 80px 120px;
  -webkit-clip-path: polygon(0% 3%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 3%, 100% 0%, 100% 100%, 0% 100%);
  margin-bottom: 60px;
  @media (max-width: 1123px) {
    -webkit-clip-path: polygon(0% 0%, 100% 0.5%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0.5%, 100% 100%, 0% 100%);
    padding: 44px 16px 120px 16px;
  }
  &__content {
    gap: 60px;
    display: flex;
    flex-direction: column;
    &__title-container {
      padding: 28.5px;
      -webkit-clip-path: polygon(1% 0%, 100% 25%, 100% 75%, 0% 100%);
      clip-path: polygon(1% 0%, 100% 25%, 100% 75%, 0% 100%);
      background-color: #d9e5f0;
      max-width: 886px;
      @media (max-width: 1123px) {
        -webkit-clip-path: polygon(1% 0%, 100% 25%, 100% 85%, 0% 100%);
        clip-path: polygon(1% 0%, 100% 25%, 100% 85%, 0% 100%);
      }
      &__title {
        @include cv900;
        font-size: 47px;
        line-height: 58px;
        letter-spacing: 0.12em;
        color: #2b4761;
        text-transform: uppercase;
        @media (max-width: 1123px) {
          font-size: 24px;
        }
        span {
          color: #db2947;
        }
      }
    }
    &__tours {
      display: block;
      min-height: 0px;
      min-width: 0px;
      &__slider {
        margin: 0 -24px;
        .slick-track {
          display: flex;
        }

        .slick-slide {
          flex-shrink: 0;
          padding: 0 12px;
          height: 500px;
          padding-top: 16px;
        }

        .slick-slide .tour-card {
            height: 470px;
        }


        .button-slider {
          display: block;
          position: absolute;
          top: 50%;
          &__left {
            transform: translateX(50%);
          }
          &__right {
            transform: translateX(-50%);
          }

          &__back {
            width: calc(100% + 60px);
            position: absolute;
            top: 50%;
          }

          &.slick-disabled {
            display: none;
          }
        }
      }
      &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px 25px;
        gap: 40px 25px;
        @media (max-width: 1123px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;
        }
        @media (max-width: 850px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    &__btn {
      padding: 24px 48px;
      border: 2px solid #d9e0e5;
      width: 100%;
      background-color: transparent;
      text-transform: uppercase;
      svg {
        transform: rotate(90deg);
      }
      & svg path {
        stroke: #2b4761;
      }
      &__active {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
