.login-form {
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  .password-field {
    position: relative;
    &_eye {
      position: absolute;
      fill: $blue;
      top: 14px;
      right: 10px;
      width: 20px;
      height: 20px;
      border: none;
    }
  }



  .login-input {
    position: relative;
    width: 100%;
    padding: 13px 15px 13px 15px;
    gap: 10px;
    font-size: 15px;
    color: $dark-blue;
    background-color: $gray10;
    border: none;
    &::placeholder {
      color: $gray40;
    }


  }

  .login-form-error {
    padding-bottom: 10px;
    font-size: 12px;
    color: $red;
  }

  .login-form-btn {
    padding: 15px 0;
    width: 100%;
  }

  @mixin login-footer-label($text-align: center) {
    font-size: 14px;
    color: $gray40;
    text-align: $text-align;
  }

  .login-footer {
    margin-top: 20px;

    .login-footer-row {
      @include login-footer-label(center);

      .register__link {
        color: $dark-blue;

        &:hover {
          color: $light-blue2;
        }
      }
    }

    .login-footer-row-mobile {
      color: $gray40;
      padding-top: 10px;
      text-align: center;
    }
  }

  .login-forgot-block {
    display: flex;
    justify-content: flex-end;

    .login-forgot-password {
      @include login-footer-label(end);
      padding-bottom: 20px;

      &:hover {
        color: $dark-blue;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-footer-row {
    display: none;
  }
}
