.wrapper {
  background: #F5F8FA;
  padding: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    display: block;
  }
}

.loading {
  padding-right: 3px;
}

.xIcon {
  margin-left: 3px;
  cursor: pointer;
}

.animation {
  transform: rotate(-45deg);
}