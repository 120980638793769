.tour-photos {
  padding-top: 80px;

  &__slider {
    &__item {
      height: 560px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .slick-slide {
      padding: 0 20px;
    }

    .button-slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.right {
        right: 8px;
      }

      &.left {
        left: 8px;
      }
    }

    &_portal {
      &.slick-slider {
        max-width: 1440px;
        margin: 0 auto;
      }

      &__item {
        height: 50px;
      }

      &__content {
        margin: 0 auto;
        width: fit-content;
        position: relative;
        padding-top: 81px;
      }

      img {
        //max-height: 70vh;
        width: 100%;
        display: inline-block;
        object-fit: cover;
        cursor: auto;
        height: 440px;
      }
    }
  }
}

@media (max-width: 1123px) {
  .tour-photos {
    padding-top: 32px;

    &__slider {
      &__item {
        height: 440px;
      }

      .slick-slide {
        padding: 0 8px;
      }

      &__item {
        //height: auto;
      }

      .button-slider {
        display: none;
      }
    }

    &__slider-portal {
      width: 100%;

      .slick-slide {
        padding: 0 8px;
      }

      .button-slider {
        display: none;
      }
    }
  }
}
