@import 'src/app/styles/vars';
.review {
  padding: 25px;
  max-width: 775px;
  margin-bottom: 32px;
  background: #ffffff;
  @media (max-width: $md) {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.top {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: $md) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  @media (max-width: $md) {
    margin-bottom: 16px;
  }
}

.time {
  color: $c-gray-40;
  text-align: right;
}

.separator {
  background: $c-gray-30;
  margin: 25px 0;
}

.subtitle {
  color: $c-gray-40;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
}

.btn {
  height: min-content;
}

.group {
  margin-bottom: 25px;
  @media (max-width: $md) {
    margin-bottom: 20px;
  }
}

.tags {
  display: flex;
  gap: 10px;
}