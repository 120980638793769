.home-find-tour {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 220px;
  position: relative;
  z-index: 1;

  &__background {
    display: none;
  }

  &__btn {
    padding: 24px 0;
    background-color: #db2947;
    border: none;
    outline: none;
    font-size: 22px;
    line-height: 36px;
    color: #ffffff;
    cursor: pointer;

    &,
    &:focus {
      border: none;
      outline: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: calc(100% + 50px);
    height: calc(100% + 50px);
    transform: translate(-50%, -50%);
    background-color: #2b4761;
    clip-path: polygon(0 30%, 95% 10%, 94% 90%, 1% 100%);
  }
}

@media (max-width: 1123px) {
  .home-find-tour {
    grid-template-columns: 1fr;
    position: static;

    &::after {
      content: none;
    }

    &__background {
      display: block;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -45px;
        left: 50%;
        z-index: -1;
        width: calc(100% + 20px);
        height: calc(240px + 90px);
        transform: translateX(-50%);
        background-color: #2b4761;
        clip-path: polygon(0 25%, 100% 0%, 99% 90%, 2% 100%);
      }
    }

    &__btn {
      padding: 16px 0;
      position: relative;
      z-index: 1;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
