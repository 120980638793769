.partners {
  &__container {
    max-width: 1200px;
    padding: 0 40px;
  }

  &__title {
    padding: 60px 0;
    @include cv900;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 0.12em;
    font-stretch: 300%;
    color: #2b4761;
  }

  &__list-wrapper {
    padding: 45px 0 120px;
  }

  .no-results-text {
    padding-top: 40px;
    padding-bottom: 120px;
    width: 100%;
    text-align: center;
    color: #2b4761;
  }
}

.partners-list {
  min-height: 0;
  min-width: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 25px;
}

@media (max-width: 1123px) {
  .partners {
    &__container {
      padding: 0 15px;
    }

    &__title {
      padding: 30px 0;
      font-size: 24px;
      line-height: 32px;
    }

    &__list-wrapper {
      padding-bottom: 60px;
    }
  }

  .partners-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 850px) {
  .partners-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
