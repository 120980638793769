.travel-guide-item {
  &__intro {
    padding: 95px 0 0;
    width: 100%;
    height: 710px;
    position: relative;
    color: #ffffff;
  }

  &__type {
    padding: 6px 12px;
    width: fit-content;
    height: 32px;
    position: relative;
    top: auto;
    left: auto;

    &::before {
      height: 100%;
    }

    &.location-tag {
      margin-top: 10px;
    }
  }

  &__title {
    padding-bottom: 25px;
    max-width: 870px;
    @include cv900;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.12em;
    font-stretch: 300%;
  }

  &__image {
    width: 100%;
    height: calc(100% + 120px);
    position: absolute;
    top: -120px;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;

    &::after,
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::before {
      height: calc(100% + 1px);
      clip-path: polygon(0 80%, 67% 100%, 100% 72%, 100% 100%, 0 100%);
    }

    &.city {
      &::before {
        background-color: #1c2543;
      }

      &::after {
        background-image: linear-gradient(179.81deg, rgba(11, 32, 64, 0.8) 18.3%, rgba(11, 32, 64, 0) 72.08%);
      }
    }

    &.flora {
      &::before {
        background-color: #26784b;
      }

      &::after {
        background-image: linear-gradient(179.81deg, rgba(19, 19, 19, 0.8) 18.3%, rgba(19, 19, 19, 0) 72.08%);
      }
    }
  }

  &__back {
    padding: 8px 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;
    z-index: 1;

    &__icon {
      width: 16px;
      height: 16px;
      transform: rotate(180deg);

      path {
        stroke: #2b4761;
      }
    }
  }

  &__tags-list {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
  }

  &__tags-inner{
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item {
    &__intro {
      padding: 75px 0 0;
      height: 485px;
    }

    &__type {
      padding: 2px 8px;
      height: 24px;
    }

    &__title {
      padding-bottom: 16px;
      font-size: 37px;
      line-height: 46px;
      font-stretch: 270%;
    }

    &__image {
      height: calc(100% + 75px);
      top: -75px;

      &::before {
        clip-path: polygon(0 100%, 100% calc(100% - 40px), 100% 100%);
      }
    }
  }
}
