.travel-guide-item-blog {
  padding: 120px 0 0;
  background-color: #e8eff6;

  &__title {
    padding: 0;
    position: relative;

    &::before {
      content: '';
      width: 520px;
      height: 105px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      clip-path: polygon(20px 0, 100% 0, calc(100% - 20px) 100%, 0 calc(100% - 5px));
      background-color: #d9e5f0;
    }
  }

  &__list {
    padding-top: 100px;
    min-height: 0;
    min-width: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    gap: 40px;
  }

  &__card-wrapper {
    height: 350px;
  }

  &__list.fauna &__card-wrapper {
    &:nth-child(3) {
      height: 740px;
      grid-column: 2;
      grid-row: 1 / span 2;
    }

    &:nth-child(6) {
      height: 740px;
      grid-column: 1;
      grid-row: 3 / span 2;
    }
  }

  &__list.flora &__card-wrapper {
    &:nth-child(3) {
      height: 740px;
      grid-column: 1;
      grid-row: 1 / span 2;
    }

    &:nth-child(6) {
      height: 740px;
      grid-column: 2;
      grid-row: 3 / span 2;
    }
  }

  &__card {
    height: 100%;

    &__background {
      height: 100%;
      object-fit: cover;
    }

    &__title {
      padding: 0;

      & .title-section__title {
        letter-spacing: 0.05em;
        white-space: normal;
        color: #ffffff;
        font-stretch: 160%;
        font-size: 70px;
        line-height: 64px;
      }
    }

    &__content {
      padding: 36px 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    &__btn {
      @include c900;
      padding: 10px 15px;
      position: relative;
      border: none;
      outline: none !important;
      background-color: transparent;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      font-size: 22px;
      color: #ffffff;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: calc(100% + 5px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: background-color 300ms;
      }

      &::before {
        background-color: #ffffff;
        clip-path: polygon(
          0 3px,
          100% 0,
          100% 2px,
          2px 5px,
          2px calc(100% - 2px),
          calc(100% - 7px) calc(100% - 7px),
          calc(100% - 2px) 2px,
          100% 2px,
          100% 0,
          calc(100% - 5px) calc(100% - 5px),
          0 100%
        );
      }

      &::after {
        background-color: transparent;
        clip-path: polygon(0 3px, 100% 0, calc(100% - 5px) calc(100% - 5px), 0 100%);
      }

      &:hover {
        &::before {
          background-color: transparent;
        }

        &::after {
          background-color: #db2947;
        }
      }
    }
  }

  &__show-more {
    display: none;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-blog {
    padding: 60px 0 10px;

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;

      &::before {
        content: '';
        width: 280px;
        height: 70px;
        clip-path: polygon(4px 0, 100% 0, calc(100% - 5px) calc(100% - 15px), 0 calc(100% - 10px));
      }
    }

    &__list {
      padding-top: 45px;
      gap: 16px;
    }

    &__card-wrapper {
      height: 350px;
    }

    &__list.flora &__card-wrapper,
    &__list.fauna &__card-wrapper {
      &:nth-child(3),
      &:nth-child(6) {
        height: 716px;
      }
    }

    &__card {
      &__title {
        padding: 0;

        & .title-section__title {
          letter-spacing: 0.05em;
          white-space: normal;
          color: #ffffff;
          font-stretch: 160%;
          font-size: 22px;
          line-height: 28px;
        }
      }

      &__content {
        padding: 25px 10px;
        gap: 16px;
      }

      &__btn {
        padding: 10px 15px;
        @include a500;
        font-size: 16px;
        text-transform: unset;
        letter-spacing: normal;

        &::before {
          opacity: 0.3;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .travel-guide-item-blog {
    &__list {
      display: flex;
      flex-direction: column;
    }

    &__list.fauna &__card-wrapper,
    &__list.flora &__card-wrapper {
      height: 440px;

      &:nth-child(3),
      &:nth-child(6) {
        height: 440px;
      }
    }

    &__card-wrapper.hidden {
      display: none;
    }

    &__show-more {
      display: block;
      margin-top: 25px;
      padding: 20px;
      width: 100%;
      border: 2px solid var(--background-blue-02, #d9e5f0);
      outline: none !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.56px;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      color: #2b4761;
      background-color: transparent;

      &.hidden {
        display: none;
      }
    }
  }
}
