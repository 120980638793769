@import 'src/app/styles/vars';
.iconText {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    flex-shrink: 0;

  }
  &.green {
    color: $c-green;
    svg {
      fill: $c-green;
    }
  }
  &.red {
    color: $c-red;
    svg {
      fill: $c-red;
    }
  }
}

