.params-filters-group {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2b4761;
  }

  &__list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
}

@media (max-width: 900px) {
  .params-filters-group {
    gap: 12px;

    &__name {
      font-size: 16px;
      line-height: 24px;
    }

    &__list {
      gap: 12px;
      flex-wrap: wrap;
    }
  }
}
