.popper {
  background: #FFF;
  box-shadow: 0px 5px 30px -9px rgba(4, 30, 56, 0.15);
  padding: 10px 15px;
  
  z-index: 99;
}

.full {
  margin-top: 40%;
}