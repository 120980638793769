.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.3);
  z-index: 2000;
}

@mixin slider {
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  background: transparent;
  overflow: hidden;

  & > .portal-close {
    display: none;
  }
}

.portal-wrapper {
  margin: 0 5px;
  width: calc(100% - 10px);
  position: fixed;
  top: 50%;
  left: calc(50% - 5px);
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px;
  z-index: 2001;

  &_slider {
    @include slider
  }
}


.portal-header{
  display: flex;
  justify-content: space-between;
}

.portal-title {
  @include c900;
  max-width: 500px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  color: $dark-blue;
}

.portal-close {
  width: 28px;
  height: 28px;
  background: url('../../assets/images/close-btn.svg') center / cover no-repeat;
  border: none;
  right: 0;

  &_slider {
    border-radius: 50%;
    background-color: rgba($color: #ffffff, $alpha: 0.7);
  }


}

.slick-slide[aria-hidden='true'] {
  & .portal-close_slider {
    display: none;
  }
}

@media (max-width: 768px) {
  .portal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    margin: 135px 0 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 2001;

    &_slider {
      @include slider
    }
  }
}
@media (max-width: 768px) {

}

