.travel-guide-item-tours {
  padding: 80px 0 60px;
  position: relative;

  &.city {
    padding: 80px 0 205px;
    background-color: #e8eff6;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85px;
    background-color: #ffffff;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }

  &__title {
    margin: 0 auto;
    width: fit-content;
    position: relative;
    z-index: 1;
    @include cv900;
    font-size: 47px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.12em;
    font-stretch: 270%;
  }

  &__list {
    padding-top: 60px;
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-tours {
    padding: 60px 0 30px;

    &.city {
      padding: 30px 0 100px;
    }

    &::after {
      height: 40px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;
    }

    &__list {
      padding-top: 30px;
    }
  }
}
