.newslist__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:nth-child(even) {
    &::before,
    &::after {
      content: none;
    }
  }
  &:nth-child(3n + 3) {
    &::before {
      left: 30%;
    }
  }
  &::before {
    top: -20%;
    left: -13%;
  }
  &::after {
    right: 0;
    bottom: -100%;
  }
}
.newslist__item-img {
  margin-right: 50px;
  height: 100%;
  flex: 1;
  font-size: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.newslist__item-content {
  flex: 1.7;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.newslist__item-title {
  font-size: 36px;
  color: $blue;
  margin-bottom: 20px;
  font-weight: 400;
}
.newslist__item-date {
  font-size: 24px;
  margin-bottom: 25px;
  color: $dark-blue;
  &_mobile {
    display: none;
  }
}

.newslist__item-desc {
  font-size: 24px;
  color: $blue;
}
.newslist__item-btn_mobile {
  display: none;
}

@media (max-width: 1440px) {
  .newslist__item-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .newslist__item-date {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .newslist__item-desc {
    font-size: 20px;
  }
  .newslist__item-img {
    margin-right: 35px;
  }
}
@media (max-width: 1280px) {
  .newslist__item-title {
    font-size: 24px;
  }
  .newslist__item-date {
    font-size: 16px;
  }
  .newslist__item-desc {
    font-size: 17px;
  }
  .newslist__item-img {
    margin-right: 20px;
  }
}
@media (max-width: 1024px) {
  .newslist__item-title {
    margin-bottom: 8px;
    font-size: 23px;
  }
  .newslist__item-date {
    margin-bottom: 12px;
    font-size: 15px;
  }
  .newslist__item-desc {
    font-size: 15px;
  }
  .newslist__item-img {
    margin-right: 25px;
  }
}
@media (max-width: 768px) {
  .newslist__item-img {
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .newslist__item-title {
    margin-bottom: 12px;
    font-size: 16px;
  }
  .newslist__item-date {
    display: none;
    &_mobile {
      margin: 9px;
      display: block;
      text-align: center;
      font-size: 14px;
    }
  }
  .newslist__item-desc {
    font-size: 16px;
  }
  .newslist__item-content {
    .news__footer_mobile {
      justify-content: flex-start;
      gap: 15px;
    }
  }
  .newslist__item-btn {
    &_mobile {
      display: block;
    }
  }
}
@media (max-width: 500px) {
  .newslist__item-img {
    margin-right: 7px;
    max-width: 120px;
    img {
      height: 78%;
    }
  }
  .newslist__item-title {
    margin-bottom: 6px;
    font-size: 12px;
  }
  .newslist__item-date {
    &_mobile {
      margin: 10px 0 0px;
      height: 23px;
      font-size: 10px;
    }
  }
  .newslist__item-desc {
    font-size: 11px;
  }
  .newslist__item-content {
    .news__footer_mobile {
      gap: 10px;
    }
  }
}
