@import 'src/app/styles/vars';
.textarea {
  height: 140px;
}

.symbs {
  font-size: 12px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 1;
  color: $c-gray-40;
}

.group {
  margin-bottom: 25px;
  @media (max-width: $md) {
    margin-bottom: 20px;
  }
}

.flex {
  display: flex;
  gap: 10px;
}

.btns {
  margin-bottom: 25px;
  display: flex;
  gap: 12px;
  @media (max-width: $md) {
    margin-bottom: 20px;
  }
}

.error {
  color: $c-red;
  font-size: 14px;
  margin-top: 4px;
}