.tourist-info {
  padding-bottom: 60px;

  &__head {
    margin-bottom: 30px;
    display: flex;
    gap: 15px;
  }

  &__icon {
    margin-top: 4px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 36px;
    color: $blue;
    word-break: break-word;
  }

  &__close {
    display: none;
  }

  &__content {
    font-size: 24px;
    color: $blue;
    p + p {
      margin-top: 30px;
    }
    blockquote {
      position: relative;
      padding-left: 25px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: $red;
      }
    }
    ul,
    ol {
      padding-left: 20px;
    }
    ul,
    li {
      list-style: unset;
    }
    hr {
      height: 2px;
      border: none;
      margin: 20px 0;
      background-color: #db2947;
    }
    img {
      margin: 30px 10px 20px;
      max-width: calc(50% - 20px);
      height: auto;
      object-fit: contain;
    }
  }
}

@media (max-width: 1440px) {
  .tourist-info {
    padding-bottom: 60px;

    &__head {
      margin-bottom: 25px;
      gap: 10px;
    }

    &__icon {
      margin-top: 3px;
      width: 33px;
      height: 33px;
    }

    &__title {
      font-size: 30px;
    }
    &__content {
      font-size: 20px;
      p + p {
        margin-top: 25px;
      }
      img {
        margin: 25px 10px 18px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .tourist-info {
    padding-bottom: 45px;

    &__head {
      margin-bottom: 20px;
      gap: 7px;
    }

    &__icon {
      margin-top: 2px;
      width: 25px;
      height: 25px;
    }

    &__title {
      font-size: 23px;
    }
    &__content {
      font-size: 15px;
      p + p {
        margin-top: 20px;
      }
      img {
        margin: 20px 10px 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .tourist-info {
    padding-bottom: 30px;

    &__head {
      margin-bottom: 10px;
      gap: 7px;
    }

    &__icon {
      margin-top: 0px;
      width: 25px;
      height: 25px;
    }

    &__title {
      font-size: 20px;
    }
    &__content {
      font-size: 16px;
      p + p {
        margin-top: 10px;
      }
      img {
        margin: 10px 10px 7px;
      }
      blockquote {
        padding-left: 14px;
      }
    }
  }
}

@media (max-width: 500px) {
  .container__tourist-info {
    padding-top: 10px;
    padding-bottom: 70px;
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .tourist-info {
    padding-bottom: 10px;

    &__head {
      padding-bottom: 8px;
      gap: 7px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bdb5aa;
    }

    &__icon {
      display: none;
    }

    &__close {
      display: block;
      min-width: 11px;
      height: 22px;
      border: none;
      background: url('../../assets/images/map/arrow.svg') center/cover no-repeat;
    }

    &__title {
      font-size: 12px;
    }
    &__content {
      font-size: 11px;
    }
  }
}
