.listdoplink {

  li {
    position: relative;
    list-style: none;
    padding: 0;
    color: #2B4761;
    font-family: 'AkzidenzGroteskPro', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 30px;

    span, b, strong {
      font-weight: 700;
    }

    a {
      color: #DB2947;
    }
  }

  li::before {
    position: absolute;
    left: 0;
    content: ' ';
    display: block;
    background: url("./sq.svg");
    background-position: center;

    width: 24px;
    height: 24px;
  }
}
@media (max-width: 768px) {
  .listdoplink {
    li{
      font-size: 16px;
    }
  }
}
