.home-banner {
  padding-bottom: 120px;
  width: 100%;
  background-color: #e8eff6;

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1123px) {
  .home-banner {
    padding-bottom: 60px;

    &__container {
      padding: 0 15px;
    }
  }
}
