.tourist {
  padding: 60px 0 0;
}
.tourist__inner {
  &.ornament {
    &::after {
      left: 50%;
      top: 50%;
    }
  }
}
.where-tourism {
  font-weight: bold;
  font-size: 24px;
}
.tourist__box {
  margin-top: 30px;
  justify-content: space-between;
}
.tourist__content {
  width: 100%;
}
.tourist__text {
  width: 60%;
}
.tourist__img-wrapper {
  max-width: 35%;
  width: 100%;
  float: right;
  position: relative;
  right: 30px;
  top: -40px;
}
.tourist__img {
  width: 100%;
  animation: soaring 10s ease-in-out infinite;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.tourist__btns {
  margin-top: 40px;
  display: flex;
  column-gap: 30px;
  position: relative;
  z-index: 1;
  button {
    padding: 3px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    border: 1px solid $dark-gray;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    color: $blue;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &.active {
      border-color: $red;
    }
  }
}

.tourist__btn {
  &_icon {
    width: 40px;
    &.home,
    &.food {
      fill: #db2947;
    }
    &.museum {
      fill: #338fd2;
    }
  }
}

.tourist__city-select {
  padding-left: 10px;
  align-self: center;
  border: none;
  font-size: 24px;
  color: $dark-blue;
  cursor: pointer;
  option {
    font-size: 16px;
  }
}

.tourist-mobile-menu {
  display: none;
}

@media (max-width: 1440px) {
  .tourist__inner {
    &.ornament {
      &::before {
        top: 20px;
      }
    }
  }
  .where-tourism {
    font-size: 20px;
  }
  .tourist__city-select {
    font-size: 20px;
    option {
      font-size: 17px;
    }
  }
  .tourist__btns button {
    padding: 3px 8px;
    display: flex;
  }
  .tourist__btn {
    &_icon {
      width: 30px;
    }
  }
}
@media (max-width: 1280px) {
  .tourist__text {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .tourist {
    padding: 30px 0 0;
  }
  .where-tourism {
    font-size: 15px;
  }
  .tourist__city-select {
    font-size: 15px;
    option {
      font-size: 13px;
    }
  }
  .tourist__img-wrapper {
    top: -30px;
  }
  .tourist__text {
    font-size: 15px;
  }
  .tourist__btns {
    gap: 22px;
    button {
      padding: 5px 12px;
      display: flex;
      gap: 12px;
      font-size: 11px;
    }
  }
  .tourist__btn {
    &_icon {
      width: 22px;
    }
  }
}

@media (max-width: 768px) {
  .tourist {
    padding-bottom: 0;
  }
  .tourist__inner {
    &.ornament {
      &::before {
        left: 50%;
      }
    }
  }
  .tourist__box {
    margin-top: 15px;
    padding: 10px 16px 16px;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .where-tourism {
    padding-left: 15px;
    font-size: 16px;
  }
  .tourist__city-select {
    padding-left: 8px;
    font-size: 16px;
    option {
      font-size: 15px;
    }
  }
  .tourist__content {
    overflow: hidden;
  }
  .tourist__text {
    width: 100%;
    font-size: 16px;
  }
  .tourist__btns {
    margin-top: 20px;
    button {
      font-size: 14px;
      padding: 4px 7px;
      column-gap: 8px;
    }
  }
  .tourist__img-wrapper {
    margin-left: 10px;
    margin-bottom: 10px;
    min-width: 175px;
    top: 15px;
    right: 0;
  }
}
@media (max-width: 500px) {
  .tourist-page {
    & ~ .footer,
    & .header,
    & .tourist .container {
      display: none;
    }
    & .tourist {
      padding: 8px 8px 0;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .tourist-mobile-menu {
    padding: 7px;
    width: 100%;
    display: block;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: #ffffff;
  }
  .where-tourism {
    padding-left: 0;
    text-align: center;
    font-weight: 500;
  }
  .tourist__btns {
    margin-top: 10px;
    justify-content: center;
    gap: 15px;
    & button {
      flex-direction: column;
      gap: 7px;
      border: none;
      font-size: 10px;
      &:hover,
      &:focus {
        box-shadow: none;
      }
      &.active {
        color: #338fd2;
      }
    }
  }
  .tourist__btn_icon-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #285b7d;
  }
  .tourist__btn {
    &_icon {
      width: 24px;
      &.home,
      &.food,
      &.museum {
        fill: #ffffff;
      }
      &.museum {
        width: 22px;
      }
    }
  }
}
