.single-blog {

}

.blogTitle {
  width: 100%;
  position: relative;
  height: 720px;
  margin-top: -120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 216px;
  overflow: hidden;

  &__backbtn{
    border: none;
    background: rgba(255, 255, 255, 0.80);
    display: inline-flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    color: #2B4761;
    font-family: 'AkzidenzGroteskPro', sans-serif;;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .whiteTrinagle {
    position: absolute;
    width: 100%;
    height: 50px;
    display: block;
    bottom: 0;
    right: 0;
    background: #fff;
    transform-origin: right top;
    transform: rotate(-2deg)
  }

  &__wrap {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }

  &__dopinfo {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-wrap: wrap;
  }
  .tagList {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__title {
    color: $white;
    font-size: 64px;
    font-style: normal;
    font-weight: 1000;
    line-height: 78px; /* 121.875% */
    letter-spacing: 7.68px;
    white-space: break-spaces;
    max-width: 871px;
    margin: 0;
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 36px;
      line-height: 46px;
    }
  }
}

@media (max-width: 768px) {
  .blogTitle {
    height: 560px;
  }
}


.commonTag {
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  color: $white;
  font-family: 'CoFoPeshkaV0.5-260', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1.44px;
  background-image: url("../../assets/images/blog/bgRed.svg");
  background-position: center;
  background-size: cover;
  align-items: center;
  background-repeat: no-repeat;
}

.commonTime {
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  color: $dark-blue;
  text-align: center;
  font-family: 'CoFoPeshkaV0.5-260', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1.44px;
  background-image: url("../../assets/images/blog/whiteBg.svg");
  background-position: center;
  background-size: cover;
  align-items: center;
  background-repeat: no-repeat;
}

.container-blog {
  max-width: 1200px;
}

.blogText {
  .htmlText {
    img{
      max-width: 100%;
    }
  }
  .linkBack {
    color: #2b4761;
    text-decoration: underline;
  }

  display: flex;
  padding: 60px 0px 80px 0px;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  color: #2B4761;
  font-family: 'AkzidenzGroteskPro', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  h3 {
    font-family: 'CoFoPeshkaV0.5-260', sans-serif;
    margin: 0;
    padding: 0;
    color: #94A5B4;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 34px; /* 154.545% */
    letter-spacing: 2.64px;
  }

  .leftBlock {
    display: flex;
    width: calc(100% - 289px - 40px);
    flex-direction: column;
  }

  .rightBlock {
    display: flex;
    width: 289px;
    flex-direction: column;
  }

  .simpleImgBG {
    max-width: 100%;
    height: 550px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .simpleImg {
    max-width: 100%;
    height: 550px;
    width: auto;
    //height: auto;
  }
  .wrapSipleImg {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    //width: fit-content;
    max-width: 100%;
  }
  .dopImgAuthor {
    display: flex;
    padding: 5px 8px 5px 7px;
    align-items: flex-start;
    gap: 10px;
    background: #FFF;
    color: #2B4761;
    font-family: 'AkzidenzGroteskPro', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    position: absolute;
    bottom: 16px;
    right: 16px;
    box-shadow: 1px 1px 20px 0px #0000001c;
}
}

@media (max-width: 768px) {
  .blogTitle{
    &__backbtn{
      margin-top: 0px;
    }
    .whiteTrinagle {
      width: calc(100% + 5px);
    }
  }

  .blogText {
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 0px;
  }
  .leftBlock {
    width: 100% !important;
  }
  .rightBlock {
    width: 100% !important;
  }
  .simpleImg {
    //height: 207px !important;
    //width: 100%;
    width: auto;
    height: auto;
  }
  .wrapSipleImg {
    width:100% !important;
  }

}


.guideBlock {
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  align-self: stretch;

  h4 {
    color: #253E54;
    text-align: center;
    font-family: 'CoFoPeshkaV0.5-260', sans-serif;
    font-size: 47px;
    font-style: normal;
    font-weight: 900;
    line-height: 58px; /* 123.404% */
    letter-spacing: 5.64px;
  }


  .travellistblog {
    .opacity-content-card {
      max-width: 50%;
      width: calc(50% - 20px);
    }

    .opacity-content-card {
      height: 300px;
    }


    .travel-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-height: 1600px;
      width: 100%;
    }
  }

}

.travellistblog {
  width: 100%;
}

.blogTour {
  h4 {
    color: #253E54;
    text-align: center;
    font-family: 'CoFoPeshkaV0.5-260', sans-serif;
    font-size: 47px;
    font-style: normal;
    font-weight: 900;
    line-height: 58px; /* 123.404% */
    letter-spacing: 5.64px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .travellistblog {
    .travel-list {
      max-height: inherit !important;
      //flex-wrap: nowrap !important;
      //max-height: inherit !important;
    }

    .opacity-content-card {
      width: 100% !important;
      max-width: 100% !important;
    }

    .opacity-content-card {
      height: 440px !important;
    }
  }
  .guideBlock,
  .blogTour {
    h4 {
      font-size: 24px;
    }
  }
}

.nextBlog {
  display: flex;
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .wrapNextBlog {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    box-shadow: 0px 4px 20px -9px rgba(4, 30, 56, 0.2);
    background-image: url("./NextBlog/wrapBgNextBlog.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 999999;
    background-position: center;

    .redbtn {
      display: flex;
      padding: 12px 16px;
      align-items: flex-start;
      gap: 10px;

      font-family: 'AkzidenzGroteskPro', sans-serif;;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.64px;
      text-transform: uppercase;
      border: none;
      color: #2b4761;

      &:hover {
        color: #FFF;
        background: #DB2947;

      }
    }
  }
}

.nextBlogStatic{
  position: relative;
}

.placeOnMap{
  padding: 0;
}

@media (max-width: 768px) {
  .nextBlog {
    .wrapNextBlog {
      width: 300px;
      padding: 10px;
      box-shadow: none;
      .redbtn {
        color: #FFF;
        //background: #DB2947;
        //padding: 0;
        font-size: 14px;
        background-image: url("./NextBlog/bg_next_blog.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
      }
    }
  }
}

.hiddenBtnNext {
  visibility: hidden;
}
