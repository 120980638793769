.designer-filters {
  width: calc(100% - 16px);
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 10;
  background-color: #1c2543;
  overflow: hidden;

  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 12px;
    fill: #ffffff;
    cursor: pointer;
  }

  &__title {
    width: 90%;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
  }

  &__clear {
    margin-top: 4px;
    color: #c6c8d0;
    font-size: 14px;
    line-height: 20px;

    span {
      cursor: pointer;
    }
  }

  &__list {
    margin-top: 16px;
    // height: calc(100% - 62px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    overflow-y: scroll;

    scrollbar-width: thin;
    scrollbar-color: #db2947 #1c2543;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #1c2543;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  .designer-filters-group {
    &__title {
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      color: #fff;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &.row {
        flex-direction: row;
        gap: 12px 32px;
        flex-wrap: wrap;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      padding-right: 10px;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .icon-m-sm{
    margin-right: 10px;
  }
  .designer-filters {
    padding: 24px 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #ffffff;
    overflow: hidden;
    color: #2b4761;

    &__close {
      fill: #2b4761;
    }

    &__header {
      padding: 0 16px 24px;
      position: relative;

      &.fixed {
        box-shadow: 0px 8px 20px -9px rgba(4, 30, 56, 0.1);
      }
    }

    &__title {
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #2b4761;
    }

    &__clear {
      display: none;
    }

    &__list {
      margin-top: 0;
      padding: 0 12px 24px 16px;
      gap: 24px;

      scrollbar-color: #db2947 #ffffff;

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }
    }

    .designer-filters-group {
      &__title {
        margin-bottom: 20px;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 24px;
        color: #2b4761;
      }

      &__item {
        display: flex;
        justify-content:unset;
        justify-items: center;
        width: inherit;
      }

      &__list {
        gap: 24px;

        &.row {
          flex-direction: row;
          gap: 12px;
          flex-wrap: wrap;
        }
      }
    }
  }
}
