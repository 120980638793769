.portal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $overlay;
  z-index: 2000;

  &.opacity-20 {
    background: $overlay-opacity-20;
  }
  &.transparent {
    background: transparent;
  }
  &.no-pointer-events {
    pointer-events: none;

    .portal__container * {
      pointer-events: auto;
    }
  }
}
.portal__container {
  padding: 50px 40px;
  width: 100%;
  max-width: 1280px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 2002;

  &.align-center {
    inset: 0;
    transform: none;
    display: grid;
    place-items: center;
  }
  &.align-bottom {
    top: auto;
    bottom: 0;
    transform: translateX(-50%);
  }
  &.align-bottom-right {
    width: fit-content;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    transform: none;
  }
}

@media (max-width: 1123px) {
  .portal__container {
    padding: 15px;
  }
}

@media (max-width: 900px) {
  .portal__container {
    padding: 0;
  }
}
