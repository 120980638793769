.chat-filesharing {
  height: calc(100% - 59px);
  width: 100%;
  max-width: 320px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #ffffff;
  border-left: 10px solid #eaecef;
  border-right: none;
  box-shadow: -6px 0px 18px rgba(0, 0, 0, 0.06);
  transition: right 0.5s;

  &__head {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
  }

  &__title {
    padding-left: 40px;
    position: relative;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    text-align: left;
    & span {
      color: #338fd2;
    }

    &::before {
      content: '';
      width: 26px;
      height: 26px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background: url('../../assets/images/attach_document_icon.svg');
    }
  }

  &__close {
    width: 26px;
    height: 26px;
    background: url('../../assets/images/Arrow.svg') no-repeat center;
    background-position-y: bottom;
    background-size: contain;
    transform: rotate(-90deg);
    cursor: pointer;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    overflow-y: auto;
  }

  &__upload {
    width: 90%;
    position: absolute;
    padding-bottom: 30px;
    background: #ffffff;
    z-index: 10;
    text-align: end;
  }

  &__download {
    margin-top: 175px;
    margin-bottom: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__uploaded {
    margin: 15px auto 2px;
    position: relative;
  }

  &__file-name {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: $blue;
    padding: 2px 20px 0 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid #dedede;
    overflow: hidden;
  }

  &__error {
    font-size: 12px;
    height: 15px;
    color: red;
    text-align: left;
  }

  &__cancel {
    position: absolute;
    top: 5px;
    right: 0;
    width: 20px;
    height: 20px;
    background: url('../../assets/images/map/close-btn.svg') center / cover no-repeat;
    border: none;
    cursor: pointer;
  }

  &__message {
    margin-bottom: 0;
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }

  &__footer {
    width: 90%;
    height: 20px;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  &__toggle {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
    background-color: #eaecef;
    background-image: url('../../assets/images/attach_icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    &_mobile {
      display: none;
    }
  }

  &__btn {
    padding: 0.5em 1.8em 0.6em;
    width: auto;
    cursor: pointer;
    font-size: 12px;

    &.download {
      margin-top: 5px;
    }
  }
}

.chat-filesharing__hide {
  box-shadow: none;
  right: -320px;
}

@media (max-width: 768px) {
  .chat-filesharing {
    &__toggle {
      top: 5px;
      right: 5px;
      background-color: transparent;
      display: none;
      &_mobile {
        display: block;
      }
    }
  }
}

@media (max-width: 500px) {
  .chat-filesharing {
    height: calc(100% - 48px);
    &__title {
      font-size: 11px;
    }
    &__file-name {
      font-size: 11px;
    }
    &__error {
      font-size: 10px;
      height: 15px;
    }
    &__upload {
      padding-bottom: 15px;
    }
    &__uploaded {
      margin: 10px auto 2px;
    }
    &__btn {
      padding: 5px 0;
      width: 75px;
      font-size: 10px;
      display: inline-block;
    }
    &__download {
      margin-top: 158px;
      margin-bottom: 10px;
      gap: 5px;
    }
    &__toggle {
      width: 16px;
      height: 16px;
      background-size: 16px;
      top: 10px;
      right: 10px;
    }
    &__footer {
      height: 10px;
    }
  }
}
