.select__label {
  font-size: 14px;
  @include a500;
  &.open {
    .select__value::after {
      transform: translateY(-50%);
    }
    .select__dropdown {
      display: block;
    }
  }
}
.select__value {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $gray;
  padding: 7px 0;
  text-align: left;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: $blue;
  cursor: pointer;
  z-index: 2;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(180deg);
    width: 9px;
    height: 5px;
    background: url('../../assets/images/select-arrow.svg') center / cover no-repeat;
    z-index: 2;
  }
  &:focus {
    border-bottom: 1px solid $red;
    // outline: 1px solid #000;
  }
}
.select__dropdown {
  border: 1px solid $gray;
  border-radius: 2px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  display: none;
  position: absolute;
  z-index: 5;
  width: 100%;
}
.select__dropdown-item button {
  font-size: 14px;
  color: $blue;
  padding: 7px 10px;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  background-color: #fff;
  border: none;
  text-align: left;
  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
}

@media (max-width: 1280px) {
  .select__label,
  .select__value {
    font-size: 12px;
  }
}
