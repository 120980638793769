.home-feedback {
  background-color: #e8eff6;

  &__container {
    padding: 0 40px;
    max-width: 1200px;
  }

  &__bottom {
    height: 170px;
    width: 100%;
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: 30%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;
      clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
    }

    &:after {
      content: '';
      height: 90%;
      width: 70%;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #ffffff;
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

@media (max-width: 1123px) {
  .home-feedback {
    &__container {
      padding: 0 15px;
    }

    &__bottom {
      height: 55px;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        height: 90px;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #e8eff6;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 60%);
      }

      &:after {
        content: none;
      }
    }
  }
}
