@import 'src/app/styles/vars';
.pageHead {
  margin-bottom: 30px;
  z-index: 20;
  @media (max-width:$md) {
    margin-bottom: 24px;
  }
}

.isFixed {
  left: 0;
  right: 0;
  top: 0;
  @media (max-width: $md) {
    background: white;
    padding: 12px 16px;
    position: fixed;
    box-shadow: 0px 8px 20px -9px rgba(4, 30, 56, 0.10);
  }
}

.isNotFixed {
  margin-bottom: 20px;
  padding-top: 12px;
}

.margin {
  @media (max-width: $md) {
    padding-bottom: 42px;
  }
}

.flexWrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}

.back {
  display: flex;
  border: 1px solid $c-gray-30;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
}


