.balloon {
  width: 220px;
  position: relative;
  left: 0;
  display: none;
  z-index: 15;
  border-radius: 4px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
}
.ol-overlay-container {
  .balloon {
    display: block;
  }
}
.balloon-content {
  padding: 10px;
  border-radius: 4px 0 4px 4px;
  background-color: #ffffff;
}
.balloon-arrow {
  width: 45px;
  height: 65px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(8px 8px 2px rgba(0, 0, 0, 0.25));

  &.reverse {
    top: -65px;
    transform: rotate(180deg) translateX(50%);
  }
}
.balloon-content {
  img {
    width: 100%;
    object-fit: cover;
    padding-bottom: 10px;
  }
}
.balloon-preview {
  height: 120px;
  border-bottom: 1px solid #338fd1;
}
.balloon-title {
  @include a500;
  font-size: 14px;
  color: $blue;
  margin-bottom: 10px;
}
.balloon-text {
  font-size: 13px;
}
.balloon-adress {
  padding-bottom: 5px;
  @include a400;
  font-size: 13px;
  color: $dark-blue;
}
.balloon-contact {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 15px;
  &-icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    fill: rgb(219, 41, 71);
    opacity: 0.9;
    img {
      width: 100%;
    }
  }
  &-value {
    font-size: 13px;
    color: $dark_blue;
  }
}
.balloon-controlls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 15px;
  &-btn {
    cursor: pointer;
    background: $light-blue2;
    text-align: center;
    font-size: 12px;
    color: #fff;
    padding: 5px 0px;
    border: none;
    font-weight: bold;
    border-radius: 4px;
    &.red {
      background: $red;
    }
  }
}
.balloon__close {
  width: 30px;
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  border-left: none;
  border-radius: 4px 4px 0 0;
}
.balloon__close-button {
  width: 30px;
  height: 30px;
  background: url('../../assets/images/map/close.svg') center no-repeat !important;
  cursor: pointer;
}

.balloon-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.balloon-btn {
  font-size: 12px;
  padding: 0.5em 1.8em 0.6em;
  &.blue {
    background-color: $light-blue2;
    &:hover {
      background-color: darken($light-blue2, 5%);
    }
  }
}

@media (max-width: 768px) {
  .balloon {
    width: 250px;
  }
  .balloon-title {
    font-size: 17px;
  }
  .balloon-adress,
  .balloon-contact-value {
    font-size: 16px;
  }
  .balloon-controlls {
    &-btn {
      font-size: 14px;
      padding: 8px 0px;
    }
  }
}

@media (max-width: 500px) {
  .ol-overlay-container {
    top: 15px;
  }
  .balloon {
    width: 220px;
  }
  .balloon-title {
    font-size: 12px;
  }
  .balloon-text {
    font: 11px;
  }
  .balloon-preview {
    height: 105px;
    border-color: #db2947;
  }
  .balloon-adress {
    font-size: 13px;
  }
  .balloon-contact {
    margin-top: 8px;
    gap: 8px;
    &-icon {
      width: 15px;
      height: 15px;
    }
    &-value {
      font-size: 11px;
    }
  }
  .balloon-controlls {
    margin-bottom: 5px;
    gap: 14px;
    &-btn {
      font-size: 10px;
      padding: 5px 0px;
    }
  }
  .balloon-arrow {
    width: 35px;
    height: 50px;
  }
}
