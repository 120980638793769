.buttons {
  display: grid;
  gap: 12px;
  margin-top: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.delete {
  @media (min-width: 1024px) {
    max-width: 500px;
    
  }
}