.designer-favourites {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #c6c8d0;
  }
}
