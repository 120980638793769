@import 'src/app/styles/vars';

.noReq {
  position: relative;
}

.container {
  padding-top: 20px;
  min-height: calc(100vh - 190px);
}
.full {
  padding-top: 25vh;
}
