.designer-map {
  width: 100%;
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;

    &.hidden {
      z-index: 0;
    }
  }

  .ol-control:not(.ol-attribution):not(.ol-custom-overviewmap) {
    padding: 10px 10px 110px;
    left: auto;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: unset;
    background-color: #1c2543;

    .ol-zoom-in {
      margin-bottom: 4px;
    }
  }

  &-controls {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    right: 20px;
    top: calc(50% - 8px);
    z-index: 10;
    background-color: #1c2543;
  }

  .ol-control:not(.ol-attribution):not(.ol-custom-overviewmap),
  &-controls {
    button {
      margin: 0;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2e3652;
      color: #ffffff;
      font-weight: 200;
      font-size: 34px;
      border-radius: unset;
      border: none;
      transition: background-color 0.2s ease-in-out;

      &:hover,
      &.active,
      &.active:focus {
        background-color: #db2947;
        border: none;
        outline: none;
      }

      &:focus {
        outline: 1px solid #666666;
      }
    }
  }

  .ol-custom-overviewmap {
    padding: 10px;
    width: 160px;
    height: 55px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: auto;
    right: 20px;
    top: auto;
    transform: none;
    border-radius: unset;
    background-color: #1c2543;

    button {
      padding: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: unset;
      background-color: #1c2543;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;

      &::before {
        content: 'Мини-карта';
      }

      &::after {
        content: '';
        width: 40px;
        height: 25px;
        display: block;
        background-color: #ffffff;
      }
    }

    &.ol-collapsed button::after {
      border: 5px solid #2e3652;
      border-right: 25px solid #2e3652;
    }

    &:not(.ol-collapsed) button {
      position: static;

      &::after {
        border: 5px solid #db2947;
        border-left: 25px solid #db2947;
      }
    }

    .ol-overviewmap-map {
      width: 250px;
      height: 140px;
      border: 10px solid #1c2543;
      position: absolute;
      top: -10px;
      right: 0;
      transform: translateY(-100%);
      background-color: #ffffff;

      .ol-overviewmap-box {
        position: relative;
        border: 2px solid #db2947;
        box-shadow: 0px 0px 0px 250px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__acive-map-action {
    position: absolute;
    bottom: -90px;
    left: 50%;
    right: 50%;
    z-index: 1;
  }
}

@media (max-width: 900px) {
  .designer-map {
    &__acive-map-action {
      top: 62%;
      bottom: 50%;
      left: 50%;
      right: 50%;
    }

    .ol-control:not(.ol-attribution):not(.ol-custom-overviewmap) {
      padding: 5px 5px 90px;
      right: 16px;
    }

    &-controls {
      padding: 5px;
      gap: 4px;
      right: 16px;
      top: 50%;
    }

    .ol-control:not(.ol-attribution):not(.ol-custom-overviewmap),
    &-controls {
      button {
        padding: 6.56px;
        width: 35px;
        height: 35px;
        font-weight: 200;
        font-size: 27px;

        &:hover,
        &.active,
        &.active:focus {
          background-color: #db2947;
          border: none;
          outline: none;
        }

        &:focus {
          outline: 1px solid #666666;
        }
      }
    }

    .ol-custom-overviewmap {
      display: none;
    }
  }
}
