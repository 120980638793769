.news-card {
  width: 100%;
  height: 100%;
  min-width: 0;
  position: relative;
  z-index: 1;
  h5 {
    font-weight: 500;
    font-size: 26px;
  }
  &::before {
    content: '';
    display: block;
    width: 103%;
    height: 109%;
    max-width: calc(100% + 40px);
    max-height: calc(100% + 30px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: background-color 300ms ease-in-out;
    clip-path: polygon(0 5%, 98% 0, 100% 100%, 0% 100%);
  }

  &__content {
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:hover {
      color: white;
    }

    &__mobile{
      position: absolute;
      bottom: 20px;
      color: white;
      padding-left: 12px;

      &__title{
        font-size: 22px;
        font-family: 'CoFoPeshkaV0.5-260', sans-serif;
      }
    }

    &_date{
      @include a400;
      font-size: 14px;
      color: $gray;
    }
  }

  &__preview {
    width: 100%;
    height: 380px;
    position: relative;
    padding-right: 4px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__type {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 12px;
    z-index: 1;

    &__wrapper{
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__header_mobile {
    padding: 10px;
    position: absolute;
    display: flex;
    top: 0;
    left: 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    z-index: 1;
    width: calc(100% - 20px);
    &__wrapper{
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__eyes{
      color: $red
    }

    p{
      color: white;
    }
  }

  &__description {
    padding: 0 10px;

    &__name {
      @include a500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 26px;
      line-height: 32px;
    }

    &__views {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
    }
  }

  &__text {
    @include a400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
    padding-bottom: 4px;
    padding-right: 10px;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        background-color: #db2947;
      }
    }
  }
}

@media (max-width: 1123px) {
  .news-card {
    &__text{
      font-size: 16px;
    }
    &__content {
      &_date{
        @include a500;
        font-size: 15px;
        color: $gray;
        text-transform: uppercase;
      }
    }
  }



  .tour-card {
    &__content {
      padding-bottom: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      z-index: 1;
      background: #f5f8fa;
      cursor: pointer;
    }

    &__preview {
      height: 200px;
    }

    &__description {
      padding: 0 12px;
    }


    &__name {
      padding-bottom: 12px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.tagBlock {
  //width: calc(100% - 70px);
}
.viewBlock {

}
