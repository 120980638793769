@import 'src/app/styles/vars';
.shrink-0 {
  flex-shrink: 0;
}

.relative {
  position: relative;
}

.justify-between-flex {
  display: flex;
  justify-content: space-between;
}

.grow {
  flex-grow: 1;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.bg-c-blue-light {
  background: $c-blue-light;

}

.cursor-pointer {
  cursor: pointer;
}