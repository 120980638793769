.home-trk {
  &__cards-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  &__card-item {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    border: 2px solid #d9e0e5;
    color: #2b4761;

    .card-item {
      &__content {
        text-align: center;
      }

      &__value {
        padding: 0;

        .title-section__title {
          line-height: 58px;
          font-stretch: 270%;

          span {
            letter-spacing: 0;
          }
        }
      }

      &__label {
        line-height: 36px;
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 1123px) {
  .home-trk {
    &__cards-list {
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    &__card-item {
      height: auto;
      padding: 24px 0 15px;
      display: block;
      text-align: center;

      .card-item {
        &__icon {
          height: 24px;

          svg {
            height: 24px;
            width: 24px;
            display: inline-block;
          }
        }

        &__content {
          padding-top: 15px;
        }

        &__value {
          padding: 0;

          .title-section__title {
            font-size: 20px;
            line-height: 26px;

            span {
              letter-spacing: 0;
            }
          }
        }

        &__label {
          padding-top: 4px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
