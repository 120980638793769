@import 'vars';

button {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
}

body {
  font-size: 16px;
}

.active {
  border-color: #db2947 !important;
  @media (max-width: 1023px) {
    color: #db2947 !important;
    & > svg {
      fill: #db2947 !important;
    }
  }
}

.subtitle::before {
  display: none !important;
  padding-left: 0 !important;
}