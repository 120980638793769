@import 'src/app/styles/vars';
.profile {
  display: flex;
  gap: 12px;
  margin: 0 16px;
  align-items: center;
}

.name {
  max-width: 130px;
  color: white;
  font-size: 14px;

}

.settings {
  cursor: pointer;
}

