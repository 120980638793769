.switcher {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__input {
    display: none;
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;

    &::after {
      content: '';
      width: 40px;
      height: 25px;
      display: inline-block;
      flex-shrink: 0;
      position: relative;
      background-color: #ffffff;
      border: 5px solid;
      border-right: 25px solid;
    }

    &.dark::after {
      border-color: #2e3652;
    }

    &.light::after {
      border-color: #d9e0e5;
    }
  }

  &__input:checked + &__label::after {
    border: 5px solid #db2947;
    border-left: 25px solid #db2947;
  }
}
