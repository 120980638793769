@import 'src/app/styles/vars';
.sidebar {
  padding: 20px 0;
  position: sticky;
  height: 100vh;
  left: 0;
  top: 0;
  width: 250px;
  bottom: 0;
  display: grid;
  grid-template-rows: auto auto 1fr;
  color: white;
  background: $c-blue-dark;
  @media (max-width: $md) {
    display: none;
  }
}

.menu {
  display: grid;
  grid-template-rows: 1fr auto;
}

.separator {
  margin: 20px 16px;
  background: $c-blue-dark-border;
}

.mobileMenu {
  display: none;
  position: fixed;
  box-shadow: $shadow-menu;
  background: white;
  padding: 4px 10px 10px;
  bottom: 0;
  width: 100%;
  z-index: 40;
  right: 0;
  left: 0;
  justify-content: space-between;
  @media (max-width: $md) {
    display: flex;
  }
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.wrapperHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconLogout {
  fill: #94A5B4;
  position: relative;
  display: none;
  top: 2px;
  @media (max-width: 1023px) {
    display: block;
  }
}

.popup {
  padding: 24px 0 16px 0;
}
.titlePopup {
  font-size: 20px;
  padding-bottom: 24px;
  padding-left: 16px;
  border-bottom: 1px solid #EBF1FA;
  font-family: 'CoFoPeshkaV0.6';
}

.buttons {
  padding: 16px;
  display: grid;
  gap: 12px;
}