.title-section {
  padding-bottom: 60px;
  text-align: center;
  color: #2b4761;

  &__title {
    position: relative;
    @include cv900;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.12em;
    color: #2b4761;
    font-stretch: 300%;
    text-transform: uppercase;

    &.small {
      line-height: 58px;
      font-size: 47px;
      font-stretch: 270%;
    }

    .red {
      color: #db2947;
    }
  }

  &__subtitle {
    max-width: 740px;
    margin: 25px auto 0;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (max-width: 1123px) {
  .title-section {
    padding-bottom: 23px;

    &__title {
      font-size: 24px;
      line-height: 32px;
      white-space: normal;

      &.small {
        font-size: 24px;
        line-height: 32px;
        font-stretch: 300%;
      }
    }

    &__subtitle {
      margin: 25px auto 0;
      font-size: 22px;
      line-height: 36px;
    }
  }
}
