.recommendations-tour-list {
  margin-bottom: -15px;
  padding-top: 65px;

  .recommendations-tour-slider {
    .slick-list {
      padding: 15px 0;
    }

    .slick-track {
      min-width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
    }

    .slick-slide {
      padding-left: 12px;
      padding-right: 12px;

      & > div {
        height: 100%;
      }
    }

    .button-slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.right {
        right: Max(calc((100% - 100vw + 40px) / 2), -68px);
      }

      &.left {
        left: Max(calc((100% - 100vw + 40px) / 2), -68px);
      }
    }
  }
}

@media (max-width: 1123px) {
  .recommendations-tour-list {
    margin-bottom: 0;
    padding-top: 30px;
  }
}
