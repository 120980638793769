.input-group {
  position: relative;

  &__input {
    padding: 16px 16px 16px 20px;
    width: 100%;
    border: 2px solid #ebf1fa;
    font-size: 18px;
    line-height: 24px;
    color: #2b4761;

    &::placeholder {
      color: #94a5b4;
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #db2947;
  }
}

@media (max-width: 900px) {
  .input-group {
    &__input {
      padding: 16px 12px 16px 16px;
      border: 1px solid #ebf1fa;
    }
  }
}
