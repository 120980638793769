.footer {
  //padding: 110px 0 60px;
  overflow: hidden;

  &__box {
    display: flex;
    gap: 70px;
  }

  &__container {
    padding: 0 40px;
    max-width: 1200px;
    position: relative;
  }

  &__inner {
    padding: 120px 0 55px;
    gap: 80px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -80px;
      z-index: -5;
      width: 1350px;
      height: 100%;
      background-color: #f5f8fa;
      clip-path: polygon(0 0, 90% 30%, 100% 95%, 5% 100%);
    }
  }

  &__logo {
    width: 260px;
    height: 85px;
    img {
      width: 100%;
      object-fit: contain;
    }
    margin-bottom: 60px;
  }

  &__links {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__links-item {
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
  }

  &__link {
    position: relative;
    color: #2b4761;

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 2px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      width: 100%;
      background-color: $red;
    }
  }

  .footer__social {
    &-link {
      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__social-item {
    display: inline-block;
    & + .footer__social-item {
      margin-left: 20px;
    }
  }

  &__bg-img {
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__info-rect {
    bottom: 20px;
    background-size: auto;
    //width: 80%;
    z-index: -1;
    height: 262px;
    background-image: url('../../../assets/images/footer/footer-info-rect.png');
    background-position: center;
  }

  &__mountain-back {
    bottom: 0;
    height: 650px;
    z-index: -3;
    background-image: url('../../../assets/images/footer/mount-back.png');
  }

  &__mountain-front {
    bottom: 0;
    height: 650px;
    z-index: -2;
    background-image: url('../../../assets/images/footer/mount-front.png');
  }

  &__rectangle {
    width: 650px;
    height: 90%;
    position: absolute;
    top: 50px;
    right: calc(100% - 1315px - 29px);
    z-index: -10;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: calc(100% + 80px);
      position: absolute;
      top: -25px;
      left: 60px;
    }

    &::before {
      background-color: #e8eaed;
      clip-path: polygon(0 0, 97% 26%, 100% 91%, 12% 100%);
    }

    &::after {
      background-color: #ffffff;
      clip-path: polygon(0.5% 0.5%, 96.5% 26.5%, 99.5% 90.5%, 12.5% 99.5%);
    }
  }

  &__bottom {
    padding: 30px 0 40px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
  }

  &__copyright {
    font-weight: 500;
  }

  @media (max-width: 925px) {
    &__copyright {
      color: #1c2543;
    }
  }
  @media (max-width: 925px) {
    &__info {
      .info__text {
        width: 100%;
        color: #1c2543;
        background: #ffffffa8;
        padding: 10px;
      }
    }
  }


  &__info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .info__text {
      width: 770px;
    }

    .info__policy-links {
      margin-top: 20px;
      display: flex;
      gap: 20px;

      a {
        color: #FFFFFF;
      }
    }

    .info__designer {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-end;

      .designer__label::after {
        content: ' от студии:';
      }
    }
  }
}

@media (max-width: 1123px) {
  .footer {
    padding: 140px 0 50px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -5;
      background-color: #f5f8fa;
      clip-path: polygon(0 0, 100% 170px, 100% 100%, 0 100%);
    }

    &__container {
      padding: 0 15px;
    }

    &__inner {
      padding: 0;
      flex-direction: column;
      gap: 50px;

      &::before {
        content: none;
      }
    }

    &__deer {
      display: none;
    }

    &__rectangle {
      display: none;
    }

    &__logo {
      width: 220px;
      height: 70px;
    }

    &__links {
      padding-bottom: 30px;
      gap: 12px;
    }

    &__links-item {
      font-size: 18px;
      line-height: 24px;
    }

    &__bottom {
      padding-top: 35px;
      font-size: 16px;
      line-height: 24px;
    }

    &__copyright {
      font-weight: 500;
    }

    &__info {
      margin-top: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      .info__text {
        width: 100%;
      }

      .info__policy-links {
        margin-top: 15px;
        flex-direction: column;
        gap: 5px;
      }

      .info__designer {
        gap: 12px;
        width: 320px;
        height: 40px;

        .designer__label::after {
          content: ':';
        }
      }
      .designer__link {
        width: 150px;
        height: 30px;
      }
      .designer__image {
        width: 150px;
        height: 30px;
      }
    }
  }
}
