.base-accordion {
  padding: 32px 0;
  border-bottom: 1.5px solid #d9e0e6;
  color: #2b4761;
  cursor: pointer;
  transition: color 200ms;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #db2947;
    }

    &:hover &__icon {
      fill: #db2947;
    }
  }

  &__head {
    font-weight: 500;
    line-height: 36px;
  }

  &__question {
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 22px;
    font-weight: 500;
    transition: padding-bottom 200ms;
  }

  &__icon {
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
    fill: #2b4761;
    transition: transform 100ms, fill 200ms;
  }

  &__answer {
    height: 0;
    width: 90%;
    overflow: hidden;
    font-size: 18px;
    transition: height 200ms;
    color: #2b4761;
  }

  .open & {
    &__question {
      padding-bottom: 12px;
    }

    &__icon {
      transform: rotate(0);
    }

    &__answer {
      height: auto;
    }
  }
}

@media (max-width: 1123px) {
  .base-accordion {
    padding: 20px 0;

    &__head {
      max-width: 84%;
      font-size: 18px;
      line-height: 24px;
    }

    &__question {
      align-items: flex-start;
      gap: 20px;
      flex-wrap: wrap;
      font-size: 22px;
    }

    &__icon {
      margin-top: 2px;
      margin-right: 10px;
      width: 15px;
      height: 24px;
    }

    &__answer {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }

    .open & {
      &__question {
        padding-bottom: 8px;
      }
    }
  }
}
