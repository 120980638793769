.designer-order-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: #2e3652;

  &__main {
    padding: 8px 6px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    background-color: #2e3652;

    &:hover {
      background-color: #db2947;
    }

    &_city {
      padding: 7px 6px 7px 32px;
      min-height: 44px;
    }
  }

  &__city {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__index {
    margin-right: 2px;
    min-width: 24px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #c6c8d0;
    flex-shrink: 0;
  }

  &__name {
    padding: 0 18px;
    font-size: 13px;
    line-height: normal;
    color: #fff;
    flex-grow: 1;
  }

  &__city &__name {
    font-size: 12px;
  }

  &__delete {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .item-delete {
      height: 20px;
      width: 20px;
      border: 1px solid #c6c8d0;
      border-radius: 50%;

      &__icon {
        height: 18px;
        width: 18px;
        fill: #c6c8d0;
        cursor: pointer;
      }
    }
  }
}
