.point_count_icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;

}

.designer-map-legend {
  padding: 16px;
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  z-index: 10;
  background-color: #1c2543;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
  }
}

@media (max-width: 900px) {
  .designer-map-legend {
    padding: 0;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    transform: none;
    z-index: 20;
    background-color: #ffffff;
    color: #2b4761;

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 15;
      background-color: #272a2e;
      opacity: 0.5;
    }

    &__header {
      padding: 24px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__head {
      @include cv900;
      font-size: 20px;
      line-height: 26px;
      font-stretch: 270%;
      letter-spacing: 2.4px;
      color: #2b4761;
    }

    &__close {
      width: 20px;
      height: 20px;
      fill: #2b4761;
      cursor: pointer;
    }

    &__list {
      padding: 0 12px 0 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: scroll;

      scrollbar-width: thin;
      scrollbar-color: #db2947 #ffffff;

      /* для Chrome/Edge/Safari */
      &::-webkit-scrollbar {
        height: 12px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #db2947;
        border-radius: 5px;
      }
    }

    &__item {
      padding: 8px 0 20px;
      display: flex;
      gap: 12px;
      align-items: center;
      border-bottom: 1px solid #ebf1fa;
    }

    & &__icon {
      width: 30px;
      height: 30px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      color: #2b4761;
    }
  }
}
