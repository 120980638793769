.tours-map {
  width: 100%;

  &__close-box {
    display: none;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    background: url('../../assets/images/map/close-btn.svg') center/cover no-repeat;
    z-index: 1;
    border: none;
  }
}
.tours__content {
  display: flex;
  height: calc(100vh - 60px);
}
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.tours-map__box {
  padding: 0 0 15px 15px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 15;
  background-color: #fff;
  border-radius: 2px;
  border: 10px solid #eaecef;
  border-right: none;
  box-shadow: -6px 0px 18px rgba(0, 0, 0, 0.06);
  max-width: 320px;
  width: 100%;
  display: none;
  &.open {
    display: block;
  }
}

.tours-map__header {
  padding: 15px 15px 15px 40px;
  border-bottom: 1px solid $gray;
  display: flex;
  align-items: center;
  position: relative;

  &.mobile {
    display: none;
  }
}
.tours-map__header-title {
  font-size: 14px;
  font-weight: 500;
  span {
    color: $light-blue;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 26px;
    height: 26px;
    border: none;
  }
  &.osm::before {
    background: url('../../assets/images/city_icon.svg') center / cover no-repeat;
  }
  &.brend::before {
    background: url('../../assets/images/path-icon.svg') center / cover no-repeat;
  }
}
.tours-map__header-btn {
  margin-right: 0;
  margin-left: auto;
}
.tours-map__header-btn,
.tour-description__header-btn {
  flex-shrink: 0;
  width: 13px;
  height: 26px;
  border: none;
  background: url('../../assets/images/map/arrow.svg') center / cover no-repeat;
  &:focus {
    outline: 1px solid #000;
  }
}
.tours-map__list {
  padding-right: 15px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  display: none;
  &.open {
    display: block;
  }
}
.designer-tours {
  .tours-map__list {
    max-height: calc(100vh - 307px);
  }
}
.tour-description {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fff;
  border-radius: 2px;
  z-index: 15;
  max-width: 480px;
  width: 100%;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  border-left: 10px solid #eaecef;
  border-bottom: 10px solid #eaecef;
  border-top: 10px solid #eaecef;

  &.open {
    display: block;
  }
}

.tour-description__swiper {
  display: none;
  background-color: #2b4761;
  padding: 15px;
  height: 80px;
  width: 80px;
  border-radius: 20%;
  position: fixed;
  bottom: 135px;
  right: 35px;
  z-index: 39;
  cursor: pointer;
  border: none;

  .backpack-icon {
    fill: #ffffff;
  }

  &__label {
    width: 35px;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-25%, -25%);
    border-radius: 50%;
    background-color: #db2947;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.tour-description__header {
  padding: 15px;
  border-bottom: 1px solid $biege;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.mobile {
    display: none;
  }
}
.tour-description__title {
  max-width: 360px;
  font-size: 14px;
  font-weight: 500;
}
.tour-description__box {
  padding: 15px;
}
.tour-description__box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5px;
}
.box-header__title {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 500;
  color: #2b4761;
}
.tour-description__box-btn {
  flex-shrink: 0;
  width: 11px;
  height: 20px;
  border: none;
  background: url('../../assets/images/map/arrow.svg') center/cover no-repeat;
}
.tour-description__box-text {
  font-size: 14px;
  color: $blue;
  div,
  span,
  p {
    font-size: 14px !important;
    line-height: 1.4 !important;
    color: $blue !important;
    font-family: 'AkzidenzGroteskPro', sans-serif !important;
    font-weight: 400 !important;
    margin: 0 auto !important;
    background-color: #eaeaef !important;
  }
  &.bold {
    font-weight: 500;
    margin-bottom: 5px;
  }
  &.price {
    font-weight: 500;
    color: $red;
  }
}
.tour-description__box-title {
  font-size: 14px;
  font-weight: 500;
  color: $blue;
  margin: 15px 0 10px;
  &.up {
    text-transform: uppercase;
  }
  span {
    color: $red;
  }
}
.tour-description__box-list__item {
  font-size: 13px;
  color: $blue;
  list-style: disc;
  margin-left: 20px;
}
.tour-description__box-imgs,
.tour-description__box-img {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  margin-top: 10px;
  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
}
.tour-description__box-img {
  grid-template-columns: 1fr;
}
.tour-description__box-line {
  width: 100%;
  height: 1px;
  background-color: $red;
  margin: 20px 0 10px;
}
.tour-description__box-slider {
  margin-top: 15px;
  &.dots {
    padding-bottom: 40px;
  }
  .slick-dots {
    bottom: -30px;
  }
  .slick-arrow {
    top: 100%;
    z-index: 1;
    &.slick-next {
      right: 0;
      transform: translate(0, 30%);
    }
    &.slick-prev {
      left: 0;
      transform: translate(0, 30%);
    }
    svg {
      height: 25px;
    }
  }
  .slick-track {
    display: flex;
    column-gap: 15px;
  }
}
.tour-description__box-slider__item {
  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
}
.tour-description__icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 15px;
  img {
    max-width: 50%;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
}
.once-marker {
  width: 43px;
  height: 62px;
  background-size: 43px 62px;
  background-image: url('../../assets/images/map/object.svg');
  position: relative;
  left: -21px;
  top: -55px;
  z-index: 10;
  cursor: pointer;
}
.group-marker {
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 5px solid #ee4643;
  border-radius: 50%;
  font: 13px Arial, sans-serif;
  position: relative;
  left: -20px;
  top: -20px;
  cursor: pointer;
  z-index: 10;
}

.osm-back-btn {
  font-size: 12px;
  padding: 0.5em 0;
  width: 95px;
  position: absolute;
  top: 10px;
  left: 55px;
}

.mobileDesigner-order,
.toursMap-mobileText {
  display: none;
}

.tour-order,
.designer-order {
  width: 100%;
  padding: 15px;
  top: 0;
  left: 0;
  background-color: #fff;
  display: none;
  position: relative;
  &.open {
    display: block;
  }
}

.tour-order__info {
  display: flex;
  gap: 20px;

  &_section {
    gap: 15px;
    display: flex;
    flex-direction: column;
  }
}

.tour-order__title {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  margin-bottom: 5px;
  span {
    color: $red;
  }
}
.tour-order__text {
  font-size: 13px;
  color: $blue;
  span {
    font-weight: 500;
    color: $red;
  }
}
.tour-order__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 13px;
  height: 26px;
  border: none;
  background: url('../../assets/images/map/arrow.svg') center / cover no-repeat;
  &:focus {
    outline: 1px solid #000;
  }
}
.tour-order__order {
  display: block;
  font-size: 12px;
  padding: 0.5em 0;
  width: 95px;
  margin-right: auto;
  margin-top: 15px;
  &.designer {
    width: auto;
    padding: 0.5em 1em;
  }
}

.tour-order {
  border-bottom: 10px solid #eaecef;
}

.designer-order {
  right: 350px;
  display: block;
}

.toursMap-popup__text {
  font-size: 14px;
  color: $blue;
  margin-bottom: 20px;
}
.toursMap-form__line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.toursMap-form__label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: $dark-blue;
}
.toursMap-form__input {
  margin-top: 15px;
  background-color: #eaecef;
  border: none;
  border-radius: 2px;
  font-size: 13px;
  color: $blue;
  padding: 5px 10px 7px;
  &::placeholder {
    opacity: 0.8;
  }
}
.toursMap-form__textarea {
  margin-bottom: 25px;
  width: 100%;
  height: 105px;
  background-color: #eaecef;
  border: none;
  border-radius: 2px;
  font-size: 13px;
  color: $blue;
  padding: 5px 10px 7px;
  resize: none;
  &::placeholder {
    opacity: 0.8;
  }
}
.toursMap-btn {
  font-size: 12px;
  &.blue {
    margin-left: 20px;
    background-color: $blue;
  }
}
.tour-order__input {
  border: none;
  border-radius: 2px;
  background-color: #fff;
  padding: 5px 0;
  font-size: 14px;
  color: $blue;
}
.size-group__input {
  padding: 0;
  display: block;
  font-weight: 500;
  color: $red;
}

@media (max-width: 900px) {
  .tours-map__close-box {
    display: block;
  }
  .tour-info__close {
    display: none;
  }
  .tours__content {
    height: calc(100vh - 105px);
  }
  .tours-map__box,
  .tour-description {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin: 0 auto;
    bottom: 104px;
    border-right: 10px solid #eaecef;
    padding: 0;
    box-shadow: none;
    max-width: 100%;
    transition: top 0.2s;
    &.bottom {
      top: calc(100% + 100px);
    }
  }

  .tour-description__swiper {
    display: block;
  }
  .tours-map__header,
  .tour-description__header {
    display: none;

    &.mobile {
      display: flex;
      padding-top: 0px;
    }
  }
  .tours-map__list {
    display: block;
    max-height: calc(100% - 60px);
  }
  .designer-tours {
    .tours-map__list {
      max-height: calc(100vh - 275px);
    }
  }
  .tour-order {
    border-bottom: 1px solid #db2947;
  }
  .tour-desc__order {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 15px;
  }
  .tour-description__title {
    font-size: 17px;
  }
  .tour-order__title,
  .tour-order__text,
  .datepicker-input,
  .tour-order__input,
  .tour-description__box-text,
  .tour-description__box-list__item,
  .tour-description__box-title {
    font-size: 16px;
  }
  .tour-order__order {
    width: 120px;
    font-size: 14px;
  }
  .tours-map__list {
    padding: 0 15px;
  }
  .toursMap-mobileText {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
  }
  .designer-order {
    display: none;
  }
  .mobileDesigner-order {
    max-width: 626px;
    padding: 0 15px;
    width: 100%;
    margin: 15px auto;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    column-gap: 15px;
    background-color: #ffffff;
  }
  .osm-back-btn {
    top: 150px;
  }
  .box-header__title {
    font-size: 17px;
  }
  .tour-description__box-imgs,
  .tour-description__box-img,
  .tour-description__box-slider__item {
    img {
      height: 180px;
    }
  }
}

@media (max-width: 768px) {
  .tour-description__box-slider .slick-dots {
    display: none;
  }
  .tour-description__box-slider.dots {
    padding-bottom: 0;
  }
}

@media (max-width: 600px) {
  .mobileDesigner-order {
    padding: 0 5px;
    margin-top: 20px;
  }
  .toursMap-form__line {
    grid-template-columns: 1fr;
  }
  .toursMap-form__label {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .toursMap-form__input {
    margin-top: 5px;
    font-size: 12px;
  }
  .toursMap-popup__text {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .toursMap-btn {
    font-size: 11px;
    &.blue {
      margin-left: 5px;
    }
  }
  .tours-map__close-box {
    top: 5px;
    right: 5px;
  }
}
@media (max-width: 500px) {
  .tour-description__swiper {
    bottom: 95px;
    right: 20px;
  }

  .mobileDesigner-order {
    margin-top: 25px;
  }
  .tour-description {
    top: 0;
    &.bottom {
      top: calc(100% - 118px);
    }
  }
  .designer-tours {
    .tours-map__list {
      padding: 0 5px;
      max-height: calc(100vh - 225px);
    }
  }
  .tour-description__title {
    font-size: 12px;
  }
  .tour-order__title,
  .tour-order__text,
  .datepicker-input,
  .tour-order__input,
  .tour-description__box-text,
  .tour-description__box-list__item,
  .tour-description__box-title,
  .box-header__title {
    font-size: 11px;
  }
  .tour-order__order {
    width: 80px;
    font-size: 10px;
  }
  .tours__content {
    height: calc(100vh - 75px);
  }
  .tours-map__box,
  .tour-description {
    bottom: 69px;
  }
  .tour-desc__order {
    grid-template-columns: 1fr 1.5fr;
    column-gap: 0;
    row-gap: 10px;
  }
  .tour-order__order {
    order: 2;
    align-self: end;
  }
  .tour-description__box-imgs,
  .tour-description__box-img,
  .tour-description__box-slider__item {
    img {
      height: 125px;
    }
  }
  .toursMap-btn {
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .mobileDesigner-order {
    margin-top: 10px;
    grid-template-columns: 0.9fr;
    gap: 5px;
  }

  .designer-tours .tours-map__list {
    max-height: calc(100vh - 240px);
  }

  .tour-order__block {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .tour-order__title {
    margin-bottom: 0;
  }
}
