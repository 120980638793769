.section__top {
  margin-bottom: 50px;
  position: relative;

  &-title {
    padding-bottom: 10px;
    font-size: 74px;
  }

  & .subtitle {
    padding-left: 38px;
    width: 38px;
    height: 38px;

    &::before {
      width: 38px;
      height: 38px;
    }
  }

  &-subtitle {
    &__head {
      white-space: nowrap;
      font-size: 36px;
    }

    &__text {
      position: absolute;
      max-width: 330px;
      font-size: 24px;
      color: #2b4761;
    }
  }
}

@media (max-width: 1440px) {
  .section__top {
    margin-bottom: 30px;

    &-title {
      font-size: 63px;
    }

    & .subtitle {
      padding-left: 33px;
      width: 33px;
      height: 33px;

      &::before {
        width: 33px;
        height: 33px;
      }
    }

    &-subtitle {
      &__head {
        font-size: 30px;
      }

      &__text {
        max-width: 280px;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1124px) {
  .section__top {
    margin-bottom: 22px;

    &-title {
      margin-left: 16px;
      font-size: 47px;
    }

    & .subtitle {
      padding-left: 25px;
      width: 25px;
      height: 25px;

      &::before {
        width: 25px;
        height: 25px;
      }
    }

    &-subtitle {
      &__head {
        font-size: 23px;
      }

      &__text {
        max-width: 215px;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 850px) {
  .section__top {
    margin-bottom: 22px;

    &-title {
      margin-left: 16px;
      padding-bottom: 7px;
      font-size: 33px;
    }

    &-subtitle {
      &__head {
        font-size: 20px;
      }

      &__text {
        max-width: 185px;
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 600px) {
  .section__top {
    &-subtitle {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .section__top {
    margin-bottom: 13px;
    gap: 5px;

    & .subtitle {
      padding-left: 0;
      width: 17px;
      height: 17px;

      &::before {
        width: 17px;
        height: 17px;
      }
    }

    &-title {
      margin-left: 7px;
      padding-bottom: 5px;
      font-size: 23px;
    }

    &-subtitle {
      display: none;
    }
  }
}
