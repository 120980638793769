.Aaside-mobile__links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.profile-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &.active .profile-link__text {
    color: $light-blue;
  }
}
.profile-link__icon {
  width: 40px;
  height: 40px;
  background-color: #285B7D;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 7px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.profile-link__text {
  font-size: 14px;
  font-weight: 500;
  color: $blue;
  text-align: center;
}

@media (max-width: 500px) {
  .profile-link__icon {
    width: 30px;
    height: 30px;
    padding: 3px;
  }
  .profile-link__text {
    font-size: 11px;
  }
}