.loading {
  margin: auto;
  width: 100%;
  display: block;
  text-align: center;
  &_main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &__container {
    width: 525px;
    position: relative;
    box-sizing: border-box;
  }
  &__logo {
    img {
      width: 860px;
    }
  }
  &__deer {
    margin: 0 auto;
    width: 205px;
  }
  &__bar {
    width: 100%;
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}

@media (max-width: 1440px) {
  .loading {
    &__container {
      width: 446px;
    }
    &__logo {
      img {
        width: 740px;
      }
    }
    &__deer {
      width: 175px;
    }
    &__bar {
      bottom: -15px;
    }
  }
}

@media (max-width: 1024px) {
  .loading {
    &__container {
      width: 365px;
    }
    &__logo {
      img {
        width: 590px;
      }
    }
    &__deer {
      width: 140px;
    }
  }
}

@media (max-width: 768px) {
  .loading {
    &__container {
      width: 380px;
    }
    &__logo {
      img {
        width: 400px;
      }
    }
    &__deer {
      width: 135px;
    }
  }
}

@media (max-width: 500px) {
  .loading {
    &__container {
      width: 210px;
    }
    &__logo {
      img {
        width: 270px;
      }
    }
    &__deer {
      width: 73px;
    }
    &__bar {
      bottom: -7px;
    }
  }
}
