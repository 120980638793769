.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transition: all 0.3s ease-in-out;
  &.slick-prev {
    left: 0;
    transform: translate(-200%, -50%);
  }
  &.slick-next {
    right: 0;
    transform: translate(200%, -50%);
  }
  svg path {
    transition: all 0.3s ease-in-out;
  }
  &.slick-disabled {
    opacity: 0;
    cursor: auto;
  }
  &:hover {
    svg path {
      stroke: $red;
      fill: $red;
    }
  }
}
