@import 'src/app/styles/vars';
.input {
  background: $c-gray-10;
  padding: 13px 15px;
  outline: none;
  resize: none;
  text-align: left;
  width: 100%;
  position: relative;
  font-size: 15px;
  &::placeholder {
    color: $c-gray-40;
  }

  &.isPlaceholder {
    color: $c-gray-40;

  }

  &.hasError {
    border: $c-red;
  }
  &.hasntError {
    border: $c-gray-10;
  }

}
.disabled {
  opacity: .5;
}
.label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  &.required:after {
    content: "*";
  }
}

