.travel-guide-item-history {
  padding: 60px 0;
  background-color: #1c2543;
  overflow: hidden;

  &__title {
    padding-bottom: 30px;
    @include cv900;
    font-size: 37px;
    line-height: 46px;
    letter-spacing: 0.12em;
    font-stretch: 270%;
    color: #ffffff;
  }

  &__slider {
    margin-left: -30px;

    .slick-list {
      overflow: unset;
    }

    .slick-track {
      margin: 0;

      .slick-slide {
        padding: 0 30px;
        width: 360px;
      }
    }

    .button-slider {
      &.right {
        top: -35px;
        right: 0;
        transform: translateY(-100%);
      }

      &.left {
        top: -25px;
        right: 10px;
        transform: translate(-100%, -100%);
      }
    }

    .button-slider__dark {
      background-color: #ffffff;

      &::before {
        background-color: #1c2543;
      }
    }

    .slick-disabled .button-slider__dark,
    .slick-disabled .button-slider__dark:hover {
      background-color: #555c72;

      &::before {
        background-color: #1c2543;
      }

      & svg path {
        stroke: #555c72;
      }
    }
  }

  &__group {
    cursor: pointer;
  }

  &__group:hover {
    .travel-guide-item-history {
      &__date {
        color: #db2947;
      }
      &__description {
        color: #ffffff;
      }
    }
  }

  &__date {
    @include cv900;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: 0.12em;
    color: #8a8e9b;
    font-stretch: 300%;
    transition: color 0.3s ease-in-out;
  }

  &__description {
    padding-top: 16px;
    font-size: 18px;
    line-height: 24px;
    color: #8a8e9b;
    transition: color 0.3s ease-in-out;
  }
}

@media (max-width: 1200px) {
  .travel-guide-item-history {
    &__slider {
      .slick-track {
        .slick-slide {
          width: 330px;
        }
      }
    }
  }
}

@media (max-width: 1123px) {
  .travel-guide-item-history {
    padding: 30px 0;

    &__title {
      padding-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
      font-stretch: 300%;
    }

    &__slider {
      margin-left: -15px;
      .slick-track {
        .slick-slide {
          padding: 0 15px;
          width: 28vw;
        }
      }

      .button-slider {
        display: none;
      }

      .button-slider__dark {
        background-color: #ffffff;

        &::before {
          background-color: #1c2543;
        }
      }

      .slick-disabled .button-slider__dark,
      .slick-disabled .button-slider__dark:hover {
        background-color: #555c72;

        &::before {
          background-color: #1c2543;
        }

        & svg path {
          stroke: #555c72;
        }
      }
    }

    &__date {
      font-size: 47px;
      line-height: 58px;
    }

    &__description {
      padding-top: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #8a8e9b;
      transition: color 0.3s ease-in-out;
    }
  }
}

@media (max-width: 901px) {
  .travel-guide-item-history {
    &__slider {
      .slick-track {
        .slick-slide {
          width: 80vw;
        }
      }
    }
  }
}
