@import 'src/app/styles/vars';
.layout {
  display: flex;
  background: $c-blue-light;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 30px;
  @media (max-width: $md) {
    padding-bottom: 0;
  }
}
.check {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.set {
  margin-left: auto;
  flex-shrink: 0;
}

.statuses {
  margin-bottom: 16px;
  display: flex;
  margin-top: 5px;
  gap: 5px;
  padding-bottom: 24px;
  border-bottom: 1px solid #D9E0E5;
}

.bb {
  border-bottom: 1px solid #D9E0E5;

}
.hr {
  margin: 8 -16px;
  width: 100%;
  background: #D9E0E5;
}
.null {
  padding: 0;
}

.popupContainer {
  padding: 16px;
}