@import "/src/app/styles/vars";


.popper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 180px;
  line-height: 24px;
  font-size: 14px;
  font-family: $font-main;
  button, a {
    cursor: pointer;
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
}