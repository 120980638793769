@import 'src/app/styles/vars';
.font {
  background:white;
  align-items: center;
  font-weight: 500;
  padding: 0 !important;
}

.message {
  padding: 15px;
  max-width: 550px;
  display: flex;
  gap: 25px;

}

.green {
  color: $c-green;
  background: none !important;
}

.red {
  color: $c-red;
  background: none !important;
}

.orange {
  color: $c-orange;
  background: none !important;
}


.isUserMessage {
  background: $c-beige;
  margin-left: auto;

}

.full {
  width: 100%;
  max-width: 100%;
  margin: 0;
  min-width: 100%;
}

.isntUserMessage {
  background: $c-gray-20;
  margin-right: auto;
}

.isUserFile {
  margin-left: auto;
  margin-bottom: 10px;
}
.isntUserFile {
  margin-right: auto;
  margin-bottom: 10px;
}

.time {
  font-size: 12px;
  font-weight: 400;
  color: $c-gray-40;
}