@import 'src/app/styles/vars';
.wrapper {
  display: flex;
  font-size: 13px;
  align-items: center;
  gap: 12px;
}


.deleteIcon {
  cursor: pointer;
  visibility: hidden;
  margin-left: auto;
}


.file {
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #2B4761;
  gap: 3px;
  transition: all .3s;
  .deleteIcon {
    display: block;
  }
  svg {
    transition: all .3s;
  }
  span {
    display: block;
    white-space: nowrap;
  }
  span {
    @media (max-width: 768px) {
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      max-width: 206px;
    }
  }
}
.wrapper:hover {
  .deleteIcon {
    visibility: visible;
  }
  .file {
    color: $c-red;
  }
}

.wrapper:hover svg {
  stroke: $c-red;
}


.withInfo {
  width: 300px;
  span {
    overflow: hidden;
    max-width: 206px;
    text-overflow: ellipsis;
  }
}

.fileInfo {
  color: $c-gray-30;
  display: flex;
  font-size: 12px;
  gap: 10px;
}

.date {
  color: $c-gray-40;
}

.dateIcon {
  cursor: pointer;
}

.popupFile {
  border-bottom: 1px solid $c-gray-30;
  .wrapper {
    width: 100%;

  }
  span {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      max-width: 206px;
    }
  }
  padding: 10px 0;
  .deleteIcon {
    cursor: pointer;
    visibility: visible;
    margin-left: auto;
    fill: #94A5B4;
  }
  
}