.home-news {
  padding: 0 0 125px;
  background-color: #e8eff6;

  &__container {
    padding: 0;
    max-width: 1440px;
  }

  &__inner {
    text-align: center;
  }

  &__title {
    padding-bottom: 35px;
  }

  &__slider {
    text-align: left;

    .slick-slide {
      padding: 0 20px;
    }

    .button-slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.right {
        right: 8px;
      }

      &.left {
        left: 8px;
      }
    }
  }

  &__article {
    padding: 25px 0;
    width: 100%;
    height: 610px;
    transition: all 0.3s ease-in-out;
  }

  &__item {
    padding: 32px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 4px;
    color: #ffffff;

    &::before {
      content: '';
      display: block;
      width: 102%;
      height: 610px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background-color: #e8eff6;
      clip-path: polygon(0 5%, 97% 0, 100% 97%, 1% 100%);
      transition: background-color 300ms ease-in-out;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    [aria-hidden='false'] &__item:hover {
      cursor: pointer;

      &::before {
        background-color: #db2947;
      }
    }
  }

  &__item-date {
    font-size: 16px;
    letter-spacing: 0.04em;
    opacity: 0.5;
    text-transform: uppercase;
  }

  &__item-title {
    @include cv900;
    padding-bottom: 12px;
    word-wrap: break-word;
    white-space: normal;
    text-align: unset;
    font-size: 47px;
    line-height: 54px;
    letter-spacing: 0.12em;
    font-stretch: 160%;
    color: #ffffff;
  }

  &__item-text {
    font-size: 18px;
    line-height: 26px;
  }

  &__item-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-cover {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 47.31%, rgba(0, 0, 0, 0.6) 100%);
    }
  }

  &__btn {
    margin: 40px auto 0;
    padding: 24px 0;
    width: calc(100% - 80px);
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid #d9e0e6;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #2b4761;
    position: relative;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

    & svg path {
      stroke: #2b4761;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #2b4761;
        border-color: #2b4761;
        color: #ffffff;

        & svg path {
          stroke: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 1123px) {
  .home-news {
    padding: 0 0 47px;
    background-color: #e8eff6;

    &__title {
      padding-bottom: 25px;
    }

    &__slider {
      .slick-slide {
        padding: 0 8px;
      }

      .button-slider {
        display: none;
      }
    }

    &__article {
      padding: 0;
      height: 440px;
    }

    &__item {
      padding: 15px;

      .img-cover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
      }
    }

    &__item-date {
      font-size: 14px;
      line-height: 20px;
    }

    &__item-title {
      font-size: 22px;
      line-height: 28px;
    }

    &__item-text {
      font-size: 16px;
      line-height: 24px;
    }

    &__btn {
      width: calc(100% - 30px);
      margin: 23px auto 15px;
      padding: 17px 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
