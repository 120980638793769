.login-page {
  &__background {
    position: relative;
    height: 100vh;
    overflow: hidden;
    &__image {
      position: absolute;
      left: 50%;
      z-index: -1;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      background: url(../../../assets/images/loginPage/Anabar_plateau_1.0.jpg) no-repeat;
      background-size: cover;
    }

    &__label {
      right: 3%;
      bottom: 3%;
      font-weight: 700;
      position: absolute;
      color: white;
      text-transform: uppercase;
    }

    &__logo {
      left: 8%;
      top: 3%;
      position: relative;
    }
  }
}
