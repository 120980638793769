.faq-list {
  padding-top: 24px;
}

@media (max-width: 1123px) {
  .faq-list {
    padding-top: 8px;
  }

  .faq-item__answer {
    font-size: 16px;
    line-height: 24px;
  }
}
