.mobile-botom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: -1.60321px -1.60321px 3.20641px rgba(0, 0, 0, 0.1), 1.60321px 1.60321px 3.20641px rgba(0, 0, 0, 0.1);
  z-index: 20;
  &.opacity {
    background: rgba(#fff, 0.85);
  }
  @media (min-width: 901px) {
    display: none;
  }
}
.mobile-bottom__list {
  padding: 20px 15px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.mobile-bottom__item {
  & + .mobile-bottom__item {
    margin-left: 20px;
  }
}
.mobile-botoom__icon {
  max-width: 23px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 3px;
}
.mobile-bottom_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $blue;
  &.active {
    color: $red;
  }
}

@media (max-width: 500px) {
  .mobile-bottom__list {
    padding: 10px 15px 15px;
  }
  .mobile-bottom_link {
    font-size: 12px;
  }
  .mobile-botoom__icon {
    max-width: 18px;
  }
}
