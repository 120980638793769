.tours-item {
  margin-top: 15px;
  cursor: pointer;

  &__content {
    transition: all 0.5s;
    padding: 15px;
    background-color: #eaecef;
    border-radius: 4px;
    position: relative;
    &:hover {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      transform: translateX(-5px) translateY(-5px);
    }
  }

  .tours-item__close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    background: url('../../assets/images/map/close-btn.svg') center / cover no-repeat;
    z-index: 1;
  }

  &__content.tours-item-bg {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .tours-item__name,
    .tours-item__desc,
    .tours-item__cost {
      color: #fff;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }
  &__city {
    padding-left: 20px;

    &_location {
      list-style-type: disc;
    }
  }
}
.tours-item__head {
  margin-bottom: 15px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 13px;
}
.tours-item__icon {
  &_red,
  &_blue {
    flex-shrink: 0;
  }
  &_red {
    path {
      &:not(.stroke-path) {
        fill: #db2947;
      }
      &.stroke-path {
        stroke: #db2947;
      }
    }
  }
  &_blue {
    path {
      &:not(.stroke-path) {
        fill: #338fd2;
      }
      &.stroke-path {
        stroke: #338fd2;
      }
    }
  }
}
.tours-item__name {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}
.tours-item__desc {
  font-size: 14px;
  color: $blue;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.tours-item__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}
.tours-item__cost {
  font-size: 14px;
  color: $blue;
  span {
    color: $red;
    font-weight: 500;
  }
}
.tours-item__btn {
  padding: 0.5em 0;
  width: 95px;
  font-size: 12px;
}



@media (max-width: 900px) {
  .tours-item__content {
    max-width: 596px;
    margin: 0 auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tours-item__name {
    font-size: 17px;
  }
  .tours-item__desc {
    font-size: 16px;
  }
  .tours-item__cost {
    font-size: 17px;
  }
  .tours-item__btn {
    width: 105px;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .tours-item__content {
    min-height: 140px;
  }
  .tours-item__name {
    font-size: 12px;
  }
  .tours-item__desc {
    font-size: 11px;
  }
  .tours-item__cost {
    font-size: 12px;
  }
  .tours-item__btn {
    width: 73px;
    font-size: 10px;
  }
}
