.textarea-group {
  position: relative;

  &__textarea {
    padding: 16px 16px 16px 20px;
    width: 100%;
    height: 100px;
    border: 2px solid #ebf1fa;
    font-size: 18px;
    line-height: 24px;
    color: #2b4761;
    resize: none;

    &::placeholder {
      color: #94a5b4;
    }

    scrollbar-width: thin;
    scrollbar-color: #db2947 #94a5b4;

    /* для Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      height: 12px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #94a5b4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #db2947;
      border-radius: 5px;
    }
  }

  &__helper {
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: #2b4761;
  }
}

@media (max-width: 900px) {
  .textarea-group {
    &__textarea {
      padding: 16px 12px 16px 16px;
      border: 1px solid #ebf1fa;
    }
  }
}
