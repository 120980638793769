.shareBlog {
  font-family: 'AkzidenzGroteskPro', sans-serif;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 4px solid #ebf1fa;
  border-bottom: none;
  position: relative;
  margin-top: 15px;
  box-sizing: border-box;

  .lineHidden {
    height: 20px;
    width: 4px;
    background: #fff;
    position: absolute;
    left: -4px;
    bottom: 0;
  }

  .lineBottom {
    height: 4px;
    background: #ebf1fa;
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% + 4px);
  }
  &__title {
    color: #db2947;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    margin-bottom: 16px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    color: #2b4761;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    span {
      color: #94a5b4;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media (max-width: 768px) {
  .shareBlog {
    margin: 24px;

    .lineHidden {
      height: 25px;
    }
  }
}
