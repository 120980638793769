.single-news {
  padding: 65px 0 70px;
  &::before {
    top: 1000px;
    left: 10%;
  }
  &::after {
    right: 10%;
    top: 1500px;
  }
}
.single-news__title {
  color: $blue;
  margin-left: 0;
  margin-bottom: 30px;
  white-space: normal;
  font-size: 74px;
}
.single-news__content {
  color: $blue;
  font-size: 24px;
  &::before {
    top: -90px;
  }
  &::after {
    left: 50%;
    top: 300px;
  }
  p {
    margin-top: 20px;
  }
  blockquote {
    position: relative;
    padding-left: 40px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $red;
    }
  }
  ul,
  ol {
    padding-left: 20px;
  }
  ul,
  li {
    list-style: unset;
  }
  hr {
    height: 2px;
    border: none;
    margin: 20px 0;
    background-color: #db2947;
  }
  img {
    display: block;
    padding: 10px;
    margin: 0 auto !important;
    object-fit: contain;
    max-width: 100%;
  }
}
.single-news__error {
  font-size: 40px;
  color: $blue;
  font-weight: 500;
}

@media (max-width: 1440px) {
  .single-news {
    padding: 50px 0 65px;
  }
  .single-news__title {
    font-size: 63px;
  }
  .single-news__content {
    font-size: 20px;
    blockquote {
      padding-left: 30px;
    }
  }
}
@media (max-width: 1280px) {
  .single-news__title {
    font-size: 50px;
  }
  .single-news__content {
    font-size: 20px;
    &::after {
    }
    &::before {
    }
    blockquote {
      padding-left: 20px;
    }
    p {
      margin-top: 15px;
    }
  }
}
@media (max-width: 1024px) {
  .single-news {
    padding: 30px 0 45px;
  }
  .single-news__title {
    margin-bottom: 23px;
    font-size: 47px;
  }
  .single-news__content {
    font-size: 15px;
    p {
      margin-top: 10px;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 400px;
      padding: 10px 0;
    }
  }
}
@media (max-width: 768px) {
  .single-news {
    padding: 35px 0 50px;
  }
  .single-news__title {
    margin-bottom: 18px;
    font-size: 33px;
  }
}
@media (max-width: 500px) {
  .single-news {
    padding: 10px 0 30px;
  }
  .single-news__title {
    margin-bottom: 13px;
    font-size: 23px;
  }
  .single-news__content {
    font-size: 11px;
    &::before {
      top: -20px;
    }
  }
}
