.tours-filters {
  &.sticky {
    margin: 0 auto;
    padding: 24px 40px;
    width: 100%;
    position: sticky;
    left: 0;
    top: 121px;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0px 12px 20px -9px rgba(4, 30, 56, 0.1);
    overflow: hidden;
    animation: fix-tours-filters 0.2s ease-in-out;
  }

  @keyframes fix-tours-filters {
    0% {
      height: 0;
      padding: 0px 40px;
    }

    100% {
      height: 108px;
      padding: 24px 40px;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
  }

  &__types-list {
    max-width: calc(100% - 145px);
    flex-grow: 1;
  }

  &_full &__types-list {
    max-width: 100%;
  }

  &__types-slider {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      flex-shrink: 0;

      &:last-child .tours-filters__wrapper {
        padding-right: 0;
      }
    }

    .button-slider {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &__back {
        height: 70px;
        width: calc(100% + 60px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &.right .button-slider__back {
        right: -5px;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #ffffff 40%, #ffffff 100%);
      }

      &.left .button-slider__back {
        background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0), #ffffff 40%, #ffffff 100%);
      }

      &.slick-disabled {
        display: none;
      }
    }
  }

  &__wrapper {
    padding-right: 30px;
  }

  &__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid transparent;
    transition: border-color 300ms ease-in-out;
    cursor: pointer;

     &:hover {
    background-color: rgba(41, 92, 125, 0.1);
    border-color: #cf2945;
    border-radius: 15%;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: #d9e0e6;
      }
    }

    &_active {
      border-color: #db2947;
      background-color: #fff5f5;
      border-radius: 15%;
      padding-top: 2%;
      width: 110%;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #db2947;
        }
      }
    }

    &__icon {
      width: 28px;
      height: 28px;
      fill: #db2947;
    }

    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #2b4761;
    }
  }

  &__params {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__group {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &__name {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #2b4761;
    }

    &__values-list {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    &__item {
      padding: 20px 25px;
      display: flex;
      flex-direction: column;
      gap: 35px;
      border: 2px solid #e8eaed;
      cursor: pointer;
      transition: border-color 300ms ease-in-out;
      color: #2b4761;

      &_full {
        padding: 15px 10px;
        flex-grow: 1;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #94a5b4;
        }
      }

      &.active {
        border-color: #db2947;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border-color: #db2947;
          }
        }
      }
    }
  }
}

@media (max-width: 1123px) {
  .tours-filters {
    &__container {
      &.fixed {
        padding: 15px;
        top: 76px;
      }
    }

    @keyframes fix-tours-filters {
      0% {
        height: 0;
        padding: 0px 15px;
      }

      100% {
        height: 90px;
        padding: 15px;
      }
    }

    &__content {
      gap: 12px;
    }

    &__types-list {
      max-width: calc(100% - 58px);
    }

    &__wrapper {
      padding-right: 20px;
    }
  }
}

@media (max-width: 900px) {
  .tours-filters {
    &__params {
      gap: 25px;

      &__group {
        gap: 12px;
      }

      &__name {
        font-size: 16px;
        line-height: 24px;
      }

      &__values-list {
        gap: 12px;
        flex-wrap: wrap;
      }

      &__item {
        padding: 12px 16px;
        gap: 35px;
        font-size: 16px;
        line-height: 24px;

        &_full {
          padding: 15px 10px;
          min-width: 157px;
          flex-grow: 0;
        }
      }
    }
  }
}
