// MAIN
.Amain {
  grid-area: main;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
  position: relative;
  background: url('../../assets/images/account/main-bg.png') center / cover no-repeat, #eaecef;

  & .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    @media (min-width: 901px) {
      width: calc(100% - 260px);
      left: auto;
    }
    @media (max-width: 900px) {
      height: calc(100% - 242px);
    }
    @media (max-width: 500px) {
      height: calc(100% - 167px);
    }
  }
}

// STATS
.Amain__stats-wrapper {
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 49%;
  column-gap: 20px;
  row-gap: 20px;
}
.Amain__stats {
  background: #fff;
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.06);
  height: 100%;
  &.c-2 {
    grid-column: span 2;
  }
}
.Amain__stats-header {
  display: grid;
  grid-template-columns: 1fr 145px 20px;
  column-gap: 5px;
  align-items: center;
  padding: 15px 20px 17px;
  border-bottom: 1px solid #dedede;
}
.Amain__stats-body {
  height: 85%;
}
.Amain__stats-name {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
}
.Amain__stats-period {
  font-size: 14px;
  color: $dark-blue;
  position: relative;
  width: fit-content;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 3px;
    width: 11px;
    height: 8px;
    transform: translateY(-50%) rotate(180deg);
    background: url('../../assets/images/select-arrow.svg') center / cover no-repeat;
  }
}
.Amain__stats-select {
  font-size: 14px;
  color: $dark-blue;
  border: none;
}
.Amain__stats-close {
  width: 17px;
  height: 17px;
  border: none;
  background: url('../../assets/images/map/close-btn.svg') center / cover no-repeat;
  justify-self: end;
}

@media (max-width: 1440px) {
  .Amain__stats-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1200px) {
  .Amain__stats-wrapper {
    grid-template-columns: 1fr;
  }
  .Amain__stats.c-2 {
    grid-column: span 1;
  }
}
@media (max-width: 900px) {
  .Amain {
    max-width: 90%;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
  }
  .Amain__stats-wrapper {
    padding: 0;
    row-gap: 10px;
  }
}
@media (max-width: 600px) {
  .Amain__stats-header {
    grid-template-columns: 1fr 18px;
    row-gap: 5px;
    padding: 10px 10px 11px;
  }
  .Amain__stats-period {
    order: 2;
  }
}
@media (max-width: 500px) {
  .Amain__stats-name,
  .Amain__stats-period,
  .Amain__stats-select {
    font-size: 11px;
  }
  .Amain {
    max-width: 96%;
  }
}

// WORKFLOW
.Amain__workflow {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;

  &_tours {
    grid-template-columns: repeat(5, 1fr);
  }
}
.Amain__workflow-column {
  min-width: 270px;
}
.Amain__workflow-column__title {
  position: relative;
  font-size: 14px;
  color: $dark-blue;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 15px;
  &.blue {
    border-color: #338fd2;
  }
  &.yellow {
    border-color: #ffb946;
  }
  &.green {
    border-color: #29db65;
  }
  &.red {
    border-color: #f7685b;
  }
  &.black {
    border-color: #2b4761;
  }
}
.Amain__workflow-item {
  & + .Amain__workflow-item {
    margin-top: 20px;
  }
}
.Amain__workflow-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 180px;
  padding: 15px;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.06);
}
.Amain__workflow-user {
  display: flex;
  align-items: center;
}
.Amain__workflow-user__img {
  margin-right: 15px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: $blue;
  flex-shrink: 0;
  text-transform: uppercase;
  color: #ffffff;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.disabled {
      display: none;
    }
  }
}
.Amain__workflow-username {
  font-weight: 500;
  color: $dark-blue;
  margin-bottom: 5px;
}
.Amain__workflow-usermail {
  color: $blue;
}
.Amain__workflow-item__title {
  font-weight: 500;
  color: $dark-blue;
}
.Amain__workflow-item__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .Amain__workflow-item__price {
    display: none;
  }
}
.Amain__workflow-item__price {
  display: flex;
  gap: 25px;

  span {
    color: #338fd1;
  }
}
.Amain__workflow-item__date {
  color: $blue;
}
.Amain__workflow-item__icon {
  max-width: 16px;
  object-fit: contain;
  &.settings {
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .Amain__workflow {
    grid-template-columns: repeat(4, 65%);
    column-gap: 10px;
    padding: 0;

    &_tours {
      grid-template-columns: repeat(5, 65%);
    }
  }
  .Amain__workflow-item {
    & + .Amain__workflow-item {
      margin-top: 10px;
    }
  }
  .Amain__workflow-link {
    min-height: 150px;
    position: relative;
  }
  .Amain__workflow-column__title {
    padding-bottom: 10px;
  }
  .Amain__workflow-item__price {
    display: none;
  }
  .Amain__workflow-item__bottom .Amain__workflow-item__price {
    display: block;
  }
  .Amain__workflow-item__icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
@media (max-width: 768px) {
  .Amain__workflow {
    grid-template-columns: repeat(4, 70%);

    &_tours {
      grid-template-columns: repeat(5, 70%);
    }
  }
  .Amain__workflow-column {
    min-width: 310px;
  }
}
@media (max-width: 500px) {
  .Amain__workflow {
    grid-template-columns: repeat(4, 95%);

    &_tours {
      grid-template-columns: repeat(5, 95%);
    }
  }
  .Amain__workflow-column {
    min-width: 290px;
  }
  .Amain__workflow-item {
    min-height: 105px;
    font-size: 11px;
  }
  .Amain__workflow-link {
    padding: 10px;
    min-height: 105px;
    font-size: 11px;
  }
  .Amain__workflow-column__title {
    font-size: 11px;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .Amain__workflow-user__img {
    margin-right: 10px;
  }
}

// PROCESSING
.Amain__processing-table {
  display: table;
  width: 100%;
  padding: 0 20px;
  border-spacing: 0 20px;
  margin-top: -30px;
}
.Amain__processing-head {
  display: table-header-group;
  position: relative;
  top: 20px;
}
.Amain__processing-list {
  display: table-row-group;
}
.Amain__processing-item {
  display: table-row;
  background-color: #fff;
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  &.table-head {
    background-color: transparent;
    box-shadow: none;
    & > div {
      font-size: 13px;
      font-weight: 400;
      color: $dark-blue;
      border-color: transparent;
    }
  }
  &.unread {
    box-shadow: 3px 2px 7px $blue;
  }
}
.Amain__processing-item__icon,
.Amain__processing-item__photo,
.Amain__processing-item__username,
.Amain__processing-item__usermail,
.Amain__processing-item__tourname,
.Amain__processing-item__price,
.Amain__processing-item__datestart,
.Amain__processing-item__time,
.Amain__processing-item__review {
  display: table-cell;
  padding: 10px 0;
  vertical-align: middle;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
}
.Amain__processing-item__icon {
  width: 16px;
  height: 100%;
  box-sizing: content-box;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.Amain__processing-item__photo {
  width: 36px;
  height: 36px;
  box-sizing: content-box;
  padding: 10px;
  text-transform: capitalize;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $blue;
    color: #ffffff;
  }
}
.Amain__processing-item__username,
.Amain__processing-item__tourname {
  color: $dark-blue;
  font-weight: 500;
}
.Amain__processing-item__tourname {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Amain__processing-item__usermail {
  color: $blue;
}
.Amain__processing-item__price,
.Amain__processing-item__datestart {
  color: $dark-blue;
  span {
    color: $light-blue;
  }
}
.Amain__processing-item__time {
  color: $blue;
  text-align: right;
  padding-right: 20px;
}
.Amain__processing-item__review {
  button {
    color: $red;
    background-color: transparent;
    border: none;
  }
}

@media (max-width: 1440px) {
  .Amain__processing-item__price,
  .Amain__processing-item__datestart {
    display: none !important;
  }
  .Amain__processing-item__tourname {
    max-width: 200px;
  }
}
@media (max-width: 1024px) {
  .Amain__processing-table {
    display: block;
    margin-top: 0;
  }
  .Amain__processing-head {
    display: block;
    top: 0;
  }
  .Amain__processing-list {
    display: grid;
    row-gap: 15px;
  }
  .Amain__processing-item {
    display: grid;
    grid-template-columns: 30px 70px 1fr 150px 150px;
    align-items: center;
    row-gap: 10px;
    padding: 11px 20px 20px;
    &.table-head {
      grid-template-columns: 20px 70px 1fr 1fr 1fr 70px;
      padding: 10px 20px 10px 27px;
      .Amain__processing-item__username {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-column-end: 4;
      }
      .Amain__processing-item__tourname {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-column-end: 5;
      }
      .Amain__processing-item__usermail {
        grid-row-start: 1;
        grid-column-start: 5;
        grid-column-end: 6;
      }
      .Amain__processing-item__time {
        grid-row-start: 1;
        grid-column-start: 6;
        grid-column-end: 7;
      }
      .Amain__processing-item__photo {
        display: none;
      }
      .Amain__processing-item__time,
      .Amain__processing-item__usermail {
        margin: 0;
        text-align: left;
      }
      .Amain__processing-item__icon {
        margin: 0;
      }
    }
  }
  .Amain__processing-item__icon,
  .Amain__processing-item__photo,
  .Amain__processing-item__username,
  .Amain__processing-item__usermail,
  .Amain__processing-item__tourname,
  .Amain__processing-item__price,
  .Amain__processing-item__datestart,
  .Amain__processing-item__time,
  .Amain__processing-item__review {
    display: block;
    padding: 0;
    vertical-align: unset;
    border: none;
    margin: 0 auto;
  }
  .Amain__processing-item__photo {
    padding: 0 10px;
  }
  .Amain__processing-item__username,
  .Amain__processing-item__tourname,
  .Amain__processing-item__usermail {
    margin: 0;
  }
  .Amain__processing-item__tourname {
    max-width: 100%;
    order: 4;
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .Amain__processing-item__time {
    padding-right: 0;
  }
}
@media (max-width: 900px) {
  .Amain__processing-table {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .Amain__processing-table {
    padding: 0;
  }
  .Amain__processing-item.table-head {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .Amain__processing-item__icon,
    .Amain__processing-item__usermail {
      display: none;
    }
    .Amain__processing-item__tourname {
      order: -1;
    }
  }
  .Amain__processing-list {
    gap: 8px;
    .Amain__processing-item {
      padding: 14px;
      grid-template-columns: 50px 1fr 150px;
      row-gap: 14px;
    }
    .Amain__processing-item__icon {
      order: 2;
      height: auto;
      align-self: start;
      margin: 0 0 0 auto !important;
    }
    .Amain__processing-item__photo {
      width: 46px;
      height: 46px;
      order: 0;
      padding: 0;
    }
    .Amain__processing-item__username {
      height: 100%;
    }
    .Amain__processing-item__usermail {
      padding-top: 15px;
    }
    .Amain__processing-item__usermail,
    .Amain__processing-item__username {
      padding-left: 10px;
      grid-column-start: 2;
      grid-row-start: 1;
    }
    .Amain__processing-item__tourname {
      order: 3;
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .Amain__processing-item__time {
      order: 4;
    }
    .Amain__processing-item__price {
      display: block !important;
      order: 3;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .Amain__processing-item__icon,
    .Amain__processing-item__photo,
    .Amain__processing-item__username,
    .Amain__processing-item__usermail,
    .Amain__processing-item__tourname,
    .Amain__processing-item__price,
    .Amain__processing-item__datestart,
    .Amain__processing-item__time,
    .Amain__processing-item__review {
      margin: 0;
    }
  }
}
@media (max-width: 500px) {
  .Amain__processing-item.table-head > div {
    font-size: 11px;
  }
  .Amain__processing-list {
    .Amain__processing-item {
      padding: 10px;
      row-gap: 10px;
      font-size: 11px;
    }
    .Amain__processing-item__photo {
      width: 36px;
      height: 36px;
    }
    .Amain__processing-item__username,
    .Amain__processing-item__tourname,
    .Amain__processing-item__time {
      font-size: 11px;
    }
    .Amain__processing-item__usermail,
    .Amain__processing-item__username {
      padding-left: 0;
    }
  }
}

//MAIN TOURS
.plus-button {
  width: 30px;
  height: 30px;
  font-size: 20px;
  position: absolute;
  border-radius: 50%;
  line-height: 25px;
  &__Amain-title {
    left: 10px;
    top: 0;
    border: 2px solid #338fd2;
    color: #338fd2;
  }
}
@media (max-width: 900px) {
  .plus-button {
    width: 25px;
    height: 25px;
    font-size: 18px;
    line-height: 20px;
  }
}

// NO REQUESTS
.norequests {
  max-width: 500px;
  width: 100%;
  padding: 15px 15px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.norequests-title {
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  padding-left: 40px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    background: url('../../assets/images/account/norequests.svg') center / cover no-repeat;
  }
}
.norequests-divider {
  width: 100%;
  height: 1px;
  background-color: #dedede;
}
.norequests-text {
  font-size: 13px;
  color: $blue;
  margin-top: 15px;
  margin-bottom: 25px;
}
.norequests-bottom {
  display: flex;
  align-items: center;
}
.norequests-btn {
  font-size: 11px;
  padding-left: 1em;
  padding-right: 1em;
  &.blue {
    background-color: $blue;
    margin-left: 25px;
    margin-right: 50px;
  }
}
.norequests-btn-simple {
  font-size: 13px;
  font-weight: 500;
  color: $light-blue;
}

// CHAT
.AMain-chat-wrapper {
  height: 100%;
  padding: 20px;
}
.chat {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background-color: #fff;
}
.chat-header {
  display: grid;
  grid-template-columns: 46px 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  padding: 20px;
  border-bottom: 1px solid #dedede;
  flex-grow: 0;
}
.chat-header__user-photo {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $blue;
  color: #ffffff;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}
.chat-header__text {
  padding-top: 8px;
  font-size: 14px;
  color: $blue;
  &.bold {
    font-weight: 500;
    color: $dark-blue;
    span {
      margin-left: 5px;
      color: $light-blue;
      font-weight: 400;
    }
  }
  &.group-size {
    grid-column-start: 2;
    grid-row-start: 2;
    white-space: nowrap;
  }
  &.duration {
    grid-column-start: 3;
    grid-row-start: 2;
    white-space: nowrap;
  }
  &.date-start {
    grid-column-start: 4;
    grid-row-start: 2;
    white-space: nowrap;
  }
  &.price {
    grid-column-start: 5;
    grid-row-start: 2;
    white-space: nowrap;
  }
}
.chat-body {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.chat-body__messages {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  background: url('../../assets/images/account/chat-bg.png') center / cover no-repeat;
}
.chat-bottom {
  flex-grow: 0;
  padding: 15px 30px;
  border-top: 1px solid #dedede;
  display: flex;
  align-items: center;
}
.chat-body__message {
  max-width: 50%;
  width: fit-content;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  &.chat-body__incoming {
    background-color: #ebf4ee;
  }
  &.chat-body__outgoing {
    background-color: #f4f3eb;
    margin-left: auto;
  }
}
.chat-body__text {
  width: 100%;
  font-size: 13px;
  line-height: 1.5;
  color: $blue;
}
.chat-body__notify {
  max-width: 50%;
  width: fit-content;
  padding: 5px 15px;
  font-size: 13px;
  color: $blue;
  text-align: center;
  margin: 35px auto;
  background: #ebecf4;
  border-radius: 4px;
}
.chat-textarea {
  min-height: 27px;
  width: 100%;
  resize: none;
  background-color: #eaecef;
  border: none;
  padding: 5px 10px;
  font-size: 13px;
  color: $blue;
  border-radius: 2px;
  &::placeholder {
    color: $blue;
    opacity: 0.6;
  }
}
.chat-bottom__extra {
  display: flex;
  align-items: center;
  position: relative;
}
.chat-bottom__extra-actions {
  width: max-content;
  position: absolute;
  bottom: 180%;
  right: 0;
  padding: 11px 10px;
  background-color: #fff;
  border: 1px solid #dedede;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 20;

  display: none;
  &.open {
    display: block;
  }
}
.extra-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & + .extra-action {
    margin-top: 13px;
  }
}
.extra-action__button {
  margin-left: 15px;
  text-align: left;
  width: 100%;
  font-size: 13px;
  color: $blue;
  border: none;
  background: none;
}
.chat-bottom__extra-btn {
  background: url('../../assets/images/account/chat-extra.svg') center / cover no-repeat;
}
.chat-bottom__send-btn {
  background: url('../../assets/images/account/chat-send.svg') center / cover no-repeat;
}
.chat-bottom__extra-btn,
.chat-bottom__send-btn {
  width: 29px;
  height: 28px;
  border: none;
  margin-left: 15px;
  flex-shrink: 0;
}

@media (max-width: 1280px) {
  .chat-body__message,
  .chat-body__notify {
    max-width: 80%;
  }
}
@media (max-width: 1024px) {
  .chat-header {
    grid-template-columns: 46px 1fr 1fr 150px;
  }
  .chat-header__text {
    &.email {
      grid-column-start: 2;
      grid-row-start: 2;
    }
    &.name {
      grid-column-start: 3;
      grid-row-start: 1;
    }
    &.last-message {
      grid-column-start: 4;
      grid-row-start: 1;
    }
    &.group-size {
      padding-top: 15px;
      grid-column-start: 2;
      grid-row-start: 3;
    }
    &.date-start {
      grid-column-start: 3;
      grid-row-start: 2;
    }
    &.duration {
      padding-top: 15px;
      grid-column-start: 3;
      grid-row-start: 3;
    }
    &.price {
      grid-column-start: 4;
      grid-row-start: 2;
    }
  }
}
@media (max-width: 900px) {
  .AMain-chat-wrapper {
    padding: 0;
  }
  .chat-bottom__send-btn {
    margin-left: 15px;
  }
}
@media (max-width: 768px) {
  .chat-header {
    grid-template-columns: 63px 1fr 170px;
  }
  .chat-header__user-photo {
    width: 52px;
    height: 52px;
  }
  .chat-header__text {
    &.login {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
    }
    &.email {
      padding-top: 30px;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
    }
    &.name {
      padding-top: 14px;
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
    }
    &.last-message {
      display: none;
    }
    &.group-size {
      padding-top: 15px;
      grid-column-start: 1;
      grid-row-start: 3;
    }
    &.date-start {
      padding-top: 15px;
      grid-column-start: 3;
      grid-row-start: 3;
    }
    &.duration {
      padding-top: 5px;
      grid-column-start: 1;
      grid-row-start: 4;
    }
    &.price {
      padding-top: 5px;
      grid-column-start: 3;
      grid-row-start: 4;
    }
  }
  .chat-body__message {
    max-width: 95%;
  }
  .chat-body__notify {
    max-width: 90%;
  }
}
@media (max-width: 500px) {
  .chat-header {
    padding: 10px;
    grid-template-columns: 40px 1fr 135px;
  }
  .chat-header__text {
    &.login {
      padding-top: 5px;
    }
    &.email {
      padding-top: 20px;
    }
    &.name {
      padding-top: 14px;
    }
    &.group-size {
      padding-top: 10px;
    }
    &.date-start {
      padding-top: 10px;
    }
    &.duration {
      padding-top: 5px;
    }
    &.price {
      padding-top: 5px;
    }
  }
  .chat-header__user-photo {
    width: 36px;
    height: 36px;
  }
  .chat-header__text {
    font-size: 11px;
  }
  .chat-body {
    padding: 10px 5px;
  }
  .chat-body__message {
    padding: 10px;
    margin-bottom: 8px;
  }
  .chat-body__text {
    font-size: 11px;
  }
  .chat-body__notify {
    font-size: 11px;
    padding: 5px 10px;
    margin: 20px auto;
  }
  .chat-bottom {
    padding: 10px;
  }
  .chat-bottom__extra-btn,
  .chat-bottom__send-btn {
    width: 27px;
    height: 27px;
  }
}
